





































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Diagnostic } from '@/models/diagnostic';
import { Section } from '@/models/section';
import { BModal } from 'bootstrap-vue';
import RapportLivrable from '../livrable/RapportLivrable.vue';
import { DiagnosticConstat } from '@/models/diagnostic_constat';
import { DiagnosticAnnex } from '@/models/diagnostic_annex';

@Component({
  components: {
    RapportLivrable
  }
})
export default class ModalDiagnosticCQPreview extends Vue {
  @Prop({ required: true }) accountId!: number;
  @Prop({ required: true }) diagnostic!: Diagnostic;
  @Prop({ required: true }) contents!: Section[];
  @Prop({ required: true }) constats!: DiagnosticConstat[];
  @Prop({ required: true }) signatures!: string[];
  @Prop({ required: true }) diagnosticAnnexes!: DiagnosticAnnex[];

  pageOverflowStages = [];

  show() {
    (this.$refs.modalCQ as BModal).show();
    this.pageOverflowStages = [];
  }

  get pageOverflowsLinks() {
    return this.pageOverflowStages.map((stageName) => `<a href="#livrable-${stageName}">${stageName}</a>`).join(', ');
  }
}
