import { render, staticRenderFns } from "./SettingsCategorieMaster.vue?vue&type=template&id=297cd438&scoped=true&"
import script from "./SettingsCategorieMaster.vue?vue&type=script&lang=ts&"
export * from "./SettingsCategorieMaster.vue?vue&type=script&lang=ts&"
import style0 from "./SettingsCategorieMaster.vue?vue&type=style&index=0&id=297cd438&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "297cd438",
  null
  
)

export default component.exports