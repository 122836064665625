



















import { Component, Vue, Prop } from 'vue-property-decorator';
import { DiagnosticStageName } from '@/models/diagnostic_stage';
import { DiagnosticConstat } from '@/models/diagnostic_constat';
import { Section } from '@/models/section';
import { TableOfContents } from '@/models/tableOfContents';

@Component({
  components: {}
})
export default class Sommaire extends Vue {
  @Prop({ required: true }) sommaire!: TableOfContents;
  @Prop({ required: true }) titles!: Section[];
  @Prop({ required: true }) constats!: DiagnosticConstat[];

  firstCountablePage = 5; // TODO : Increment if sommaire gets page break inserted

  get stages() {
    let stagePage = this.firstCountablePage;
    const stages: {
      name: string;
      page: number;
      lines: { title: string; page: number }[];
      constats?: boolean;
    }[] = [];

    [
      { name: DiagnosticStageName.ANALYSE, constats: false },
      { name: DiagnosticStageName.CIVIL, constats: true },
      { name: DiagnosticStageName.PATRIMONIAL, constats: true },
      { name: DiagnosticStageName.SOCIAL, constats: true },
      { name: DiagnosticStageName.ECONOMIQUE, constats: true },
      { name: DiagnosticStageName.FISCAL, constats: true },
      { name: DiagnosticStageName.OBJECTIFS, constats: false },
      { name: DiagnosticStageName.CONSEILLERS, constats: false }
    ].forEach((stage) => {
      if (stage.constats) {
        if (this.stageConstats(stage.name).length > 0) {
          stages.push({
            name: stage.name,
            constats: true,
            page: stagePage,
            lines: this.stageConstats(stage.name).map((c, constatIndex) => {
              const title = this.constatTitle(c) || `Constat ${constatIndex + 1}`;

              const titleRelativePage =
                this.sommaire[stage.name] && this.sommaire[stage.name][title] ? this.sommaire[stage.name][title] : 0;
              return { title, page: stagePage + titleRelativePage };
            })
          });
          const stagePagesTotal =
            this.sommaire[stage.name] && this.sommaire[stage.name].total ? this.sommaire[stage.name].total : 1;
          stagePage = stagePage + stagePagesTotal;
        }
      } else {
        stages.push({
          name: stage.name,
          page: stagePage,
          lines: this.stageTitles(stage.name).map((title) => {
            const titleRelativePage =
              this.sommaire[stage.name] && this.sommaire[stage.name][title] ? this.sommaire[stage.name][title] : 0;
            return { title, page: stagePage + titleRelativePage };
          })
        });
        const stagePagesTotal =
          this.sommaire[stage.name] && this.sommaire[stage.name].total ? this.sommaire[stage.name].total : 1;
        stagePage = stagePage + stagePagesTotal;
      }
    });

    return stages;
  }

  private stageConstats(stageName: DiagnosticStageName) {
    return this.constats.filter((c) => c.stageName === stageName);
  }

  private stageTitles(stageName: DiagnosticStageName): string[] {
    return this.titles
      .filter((title) => title.stageName === stageName)
      .map((title) => {
        if (!title.content) return 'Erreur';
        return title.content.replace(/(<([^>]+)>)/gi, '');
      });
  }

  private constatTitle(constat: DiagnosticConstat): string | undefined {
    const constatTitle = this.titles.find((title) => title.constat && title.constat.id === constat.id);
    if (!constatTitle || !constatTitle.content) return undefined;

    return constatTitle.content.replace(/(<([^>]+)>)/gi, '');
  }
}
