









































































































































































































































































































































































































































































































































import { Vue, Prop, Component } from 'vue-property-decorator';
import { Diagnostic } from '@/models/diagnostic';
import { diagnosticService } from '../../services/diagnostic';
import AccountService from '../../services/account';
import ConseillerService from '../../services/conseiller';
import TemplateService from '../../services/template';
import { DiagnosticStage, DiagnosticStageName } from '@/models/diagnostic_stage';
import { DiagnosticPipeline } from '@/models/diagnostic_pipeline';
import { DiagnosticConstat } from '@/models/diagnostic_constat';
import { DiagnosticWorkingState } from '@/models/diagnostic_working_state';
import { DiagnosticAnnex } from '@/models/diagnostic_annex';
import { Section } from '@/models/section';
import { Placeholder } from '../../models/placeholder';
import DiagnosticPipelineNavigator from '@/components/diagnostic/DiagnosticPipelineNavigator.vue';
import ContentForm from '@/components/diagnostic/ContentForm.vue';
import TemplateSelect from '@/components/template/TemplateSelect.vue';
import { Account } from '../../models/account';
import { Template, TemplateCategory } from '@/models/template';
import { Conseiller } from '@/models/conseiller';
import { TranslateResult } from 'vue-i18n';
import ModalDiagnosticStagePreview from '@/components/diagnostic/ModalDiagnosticStagePreview.vue';
import ModalDiagnosticCQPreview from '@/components/diagnostic/ModalDiagnosticCQPreview.vue';
import ModalDiagnosticCQPrint from './ModalDiagnosticCQPrint.vue';
import DiagnosticSidebar from '@/components/sidebar/DiagnosticSidebar.vue';
import { BModal } from 'bootstrap-vue';

@Component({
  components: {
    DiagnosticPipelineNavigator,
    ContentForm,
    TemplateSelect,
    ModalDiagnosticStagePreview,
    ModalDiagnosticCQPreview,
    ModalDiagnosticCQPrint,
    DiagnosticSidebar
  }
})
export default class DiagnosticCreate extends Vue {
  @Prop({ required: true }) accountId!: number;
  account: Account | null = null;
  pipeline: DiagnosticStage[] = DiagnosticPipeline;
  stageNames = DiagnosticStageName;
  workingStates = DiagnosticWorkingState;
  currentStageIndex = 0;
  currentSubstageIndex = 0;
  currentConstatId: number | null = null;

  diagnostic: Diagnostic | null = null;
  diagnosticContents: Section[] = [];
  diagnosticConstats: DiagnosticConstat[] = [];
  diagnosticAnnexes: DiagnosticAnnex[] = [];

  formSubmission = false;
  errors: string[] = [];
  loadingContents = true;

  selectingTemplate = false;
  selectingAnnexTemplate = false;
  templateToInsert: Template | null = null;

  modalPreview = false;
  modalCQ = false;

  sidebarMode: null | 'dictionary' | 'comments' = null;

  conseillersTotal: Conseiller[] = [];

  autoSubmitMethod = this.submit;
  autoSubmitToggle = false;

  scrollPosition = 0;
  focusedSection: Section | null = null;

  timeoutMilliseconds = 5000;

  workingStatesOptions: {
    value: DiagnosticWorkingState;
    text: TranslateResult;
  }[] = [];

  clientStatesOptions: {
    value: boolean | null;
    text: TranslateResult | string;
  }[] = [];

  mounted() {
    this.initOptions();

    AccountService.accountById(this.accountId).then((response) => {
      this.account = response.data;
    });

    diagnosticService
      .diagnosticByAccountId(this.accountId)
      .then((response) => {
        this.diagnostic = response.data;

        this.loadingContents = true;

        // this.registerAutoSubmitTimer(new Date());

        diagnosticService.diagnosticContentsByAccountId(this.accountId).then((response) => {
          this.diagnosticContents = response.data;

          if (this.$route.query.stage !== undefined) {
            this.currentStageIndex = this.pipeline.findIndex((s) => s.name === this.$route.query.stage);
            if (this.currentStageIndex === -1) this.currentStageIndex = 0;
            if (this.$route.query.substage !== undefined && this.pipelinePosition.stage.substages) {
              this.currentSubstageIndex = this.pipelinePosition.stage.substages.findIndex(
                (s) => s.name === this.$route.query.substage
              );
              if (this.currentSubstageIndex === -1) this.currentSubstageIndex = 0;
              if (this.$route.query.constat) this.currentConstatId = parseInt(this.$route.query.constat as string, 10);
            }
          }

          diagnosticService.diagnosticConstatsByAccountId(this.accountId).then((response) => {
            this.diagnosticConstats = response.data;

            this.updatePipelineContent();
          });
        });
      })
      .catch((error) => {
        console.warn({ error });
      });

    ConseillerService.conseillerList().then((response) => {
      this.conseillersTotal = response.filter((c) => c.signature !== undefined);
    });

    window.addEventListener('scroll', this.updateScroll);
  }

  updateScroll() {
    this.scrollPosition = window.scrollY;
  }

  /*
  @Watch('autoSubmitToggle')
  toggleAutoSubmit(toggle: boolean) {
    // if (toggle) this.registerAutoSubmitTimer(new Date());
    // else this.clearAutoSubmitTimer();
  }
  */

  beforeDestroy() {
    // this.clearAutoSubmitTimer();
    window.removeEventListener('scroll', this.updateScroll);
  }

  private initOptions() {
    this.workingStatesOptions = [
      {
        value: DiagnosticWorkingState.BROUILLON,
        text: this.$t('diagnostic.BROUILLON')
      },
      {
        value: DiagnosticWorkingState.PRET_CQ,
        text: this.$t('diagnostic.PRET_CQ')
      },
      {
        value: DiagnosticWorkingState.VALIDE,
        text: this.$t('diagnostic.VALIDE')
      },
      {
        value: DiagnosticWorkingState.A_MODIFIER,
        text: this.$t('diagnostic.A_MODIFIER')
      },
      {
        value: DiagnosticWorkingState.PRESENTE,
        text: this.$t('diagnostic.PRESENTE')
      }
    ];

    this.clientStatesOptions = [
      { value: null, text: this.$t('diagnostic.CLIENT_ATTENTE') },
      { value: true, text: this.$t('diagnostic.CLIENT_ACCEPTE') },
      { value: false, text: this.$t('diagnostic.CLIENT_REFUSE') }
    ];
  }

  get pipelinePosition(): {
    stage: DiagnosticStage;
    substage: DiagnosticStage | undefined;
    constat: DiagnosticConstat | undefined;
  } {
    const stage = this.pipeline[this.currentStageIndex];
    return {
      stage,
      substage: stage.substages ? stage.substages[this.currentSubstageIndex] : undefined,
      constat: this.currentConstat
    };
  }

  get currentStage(): DiagnosticStage {
    if (this.pipelinePosition.substage) return this.pipelinePosition.substage;
    else return this.pipelinePosition.stage;
  }

  get currentConstat(): DiagnosticConstat | undefined {
    if (this.currentConstatId === null) return undefined;
    else return this.diagnosticConstats.find((c) => c.id === this.currentConstatId);
  }

  get currentConstats(): DiagnosticConstat[] {
    return this.constatsInStage(this.currentStage.name);
  }

  get currentContents(): Section[] {
    let contents = this.diagnosticContents.filter((content) => content.stageName === this.currentStage.name);
    if (this.currentStage.constats)
      contents = contents.filter(
        (content) => content.constat && this.currentConstat && content.constat.id === this.currentConstat.id
      );
    return contents.sort((a, b) => a.displayOrder - b.displayOrder);
  }

  get previewAvailable() {
    return this.currentContents.length && (!this.currentStage.constats || this.currentConstat);
  }

  constatsInStage(stageName: DiagnosticStageName): DiagnosticConstat[] {
    return this.diagnosticConstats.filter((c) => c.stageName === stageName);
  }

  async nextStage() {
    await this.submit();
    if (!this.pipelinePosition.substage && !this.currentConstatId) {
      this.goToStage(this.currentStageIndex + 1);
    } else if (this.pipelinePosition.substage) {
      const constatId = this.currentConstatId;
      const stageConstatsIds = this.constatsInStage(this.pipelinePosition.substage.name).map((c) => c.id);
      const nextConstatId = stageConstatsIds.filter((id) => id && constatId && id > constatId).shift();

      if (nextConstatId) {
        this.goToStage(this.currentStageIndex, this.currentSubstageIndex, nextConstatId);
      } else if (
        this.pipelinePosition.stage.substages &&
        this.currentSubstageIndex < this.pipelinePosition.stage.substages.length - 1
      ) {
        this.goToStage(this.currentStageIndex, this.currentSubstageIndex + 1);
      } else {
        this.goToStage(this.currentStageIndex + 1);
      }
    }
  }

  goToStage(stageIndex: number, substageIndex?: number | null, constatId?: number) {
    this.currentStageIndex = stageIndex;
    this.currentSubstageIndex = substageIndex || 0;
    this.currentConstatId = constatId || null;

    if (!this.pipelinePosition.substage) {
      if (this.$route.query.stage !== this.pipelinePosition.stage.name)
        this.$router.push({
          name: 'DiagnosticCreate',
          query: { stage: this.pipelinePosition.stage.name }
        });
    } else {
      if (!constatId) {
        const firstConstat = this.constatsInStage(this.pipelinePosition.substage.name).shift();
        if (firstConstat && firstConstat.id) this.currentConstatId = firstConstat.id;
      }

      if (
        this.$route.query.substage !== this.pipelinePosition.substage.name ||
        (this.currentConstatId && this.$route.query.constat !== this.currentConstatId.toString())
      )
        this.$router.push({
          name: 'DiagnosticCreate',
          query: {
            stage: this.pipelinePosition.stage.name,
            substage: this.pipelinePosition.substage.name,
            constat: this.currentConstatId ? this.currentConstatId.toString() : undefined
          }
        });
    }
  }

  finishDiagnostic() {
    console.warn('Not yet implemented');
  }

  async previousStage() {
    await this.submit();
    if (!this.pipelinePosition.substage && !this.currentConstatId) {
      this.goToStage(this.currentStageIndex - 1);
    } else if (this.pipelinePosition.substage) {
      const constatId = this.currentConstatId;
      const stageConstatsIds = this.constatsInStage(this.pipelinePosition.substage.name).map((c) => c.id);
      const previousConstatId = stageConstatsIds.filter((id) => id && constatId && id < constatId).pop();

      if (previousConstatId) {
        this.goToStage(this.currentStageIndex, this.currentSubstageIndex, previousConstatId);
      } else if (this.pipelinePosition.stage.substages && this.currentSubstageIndex > 0) {
        this.goToStage(this.currentStageIndex, this.currentSubstageIndex - 1);
      } else {
        this.goToStage(this.currentStageIndex - 1);
      }
    }
  }

  async addConstat(stage: DiagnosticStage) {
    if (!this.account) return;
    await this.submit();
    console.log('addConstat', { stage });
    this.loadingContents = true;

    diagnosticService.diagnosticConstatCreate(this.account.id, stage.name).then((constat: DiagnosticConstat) => {
      this.goToStage(this.currentStageIndex, this.currentSubstageIndex, constat.id as number);

      this.diagnosticConstats.push(constat);

      const bilans = this.pipeline.find((s) => s.name === DiagnosticStageName.BILANS);
      const constatStage =
        bilans && bilans.substages ? bilans.substages.find((s) => s.name === constat.stageName) : null;
      if (constatStage) {
        this.updateStageContent(constatStage, constat);
      }
      this.loadingContents = false;
    });
  }

  deleteConstat(stage: DiagnosticStage, constat: DiagnosticConstat, index: number) {
    if (!this.account || !constat.id) return;
    const deletedCurrentConstat = constat === this.currentConstat;

    diagnosticService.diagnosticContentDelete(this.account.id, stage.name, constat.id).then((result) => {
      if (result) {
        this.diagnosticConstats = this.diagnosticConstats.filter((c) => c.id !== constat.id);

        if (!deletedCurrentConstat) return;
        if (this.constatsInStage(constat.stageName)[index - 1]) {
          this.goToStage(
            this.currentStageIndex,
            this.currentSubstageIndex,
            this.constatsInStage(constat.stageName)[index - 1].id as number
          );
        } else if (this.constatsInStage(constat.stageName)[index + 1]) {
          this.goToStage(
            this.currentStageIndex,
            this.currentSubstageIndex,
            this.constatsInStage(constat.stageName)[index + 1].id as number
          );
        } else {
          this.goToStage(this.currentStageIndex, this.currentSubstageIndex);
        }
        diagnosticService.diagnosticByAccountId(this.accountId).then((response) => {
          this.diagnostic = response.data;
        });
      }
    });
  }

  addContent(newContent: Section) {
    this.diagnosticContents.push(newContent);

    this.$nextTick().then(() => {
      const newEditor = this.$el.querySelector(`[data-content-display-order='${newContent.displayOrder}']`);
      if (newEditor) {
        newEditor.scrollIntoView({ behavior: 'smooth' });
        newEditor.animate([{ opacity: '0' }, { opacity: '1' }], {
          duration: 500
        });
      }
    });
  }

  submit(): Promise<void> {
    if (!this.account || !this.diagnostic) return Promise.reject(new Error('Invalid state'));
    this.errors = [];
    this.formSubmission = true;

    const updatePromise = Promise.all([
      diagnosticService.diagnosticUpdate(this.account.id, this.diagnostic).then((diagnostic: Diagnostic) => {
        this.diagnostic = diagnostic;
      }),
      diagnosticService
        .diagnosticContentsUpdate(this.account.id, this.diagnosticContents)
        .then((content: Section[]) => {
          this.diagnosticContents = content;
        }) // Do not replace contents while user is editing
    ]);

    let timeoutHandle: number;
    const timeoutPromise = new Promise((resolve, reject) => {
      timeoutHandle = window.setTimeout(() => reject(Error('Timeout')), this.timeoutMilliseconds);
    });

    return Promise.race([updatePromise, timeoutPromise])
      .then(() => {
        if (timeoutHandle !== undefined) clearTimeout(timeoutHandle);
        this.formSubmission = false;
        // this.lastSubmit = new Date();
      })
      .catch((thrown: { message: string }) => {
        this.formSubmission = false;
        console.warn(thrown.message);
      });
  }

  deleteDiagnostic() {
    if (!this.account || !this.diagnostic) return;
    this.formSubmission = true;
    diagnosticService.diagnosticDelete(this.account.id).then((account: Account) => {
      this.formSubmission = false;
      this.$router.push({
        name: 'AccountOne',
        params: { accountId: account.id.toString() }
      });
    });
  }

  removeDiagnosticContent(contentToRemove: Section) {
    let displayOrderToRemove = contentToRemove.displayOrder;
    this.diagnosticContents = this.diagnosticContents
      .filter((c) => !(c.stageName === contentToRemove.stageName && c.displayOrder === contentToRemove.displayOrder))
      .map((c) => {
        // Shift down contents with higher order
        if (c.stageName === contentToRemove.stageName && c.displayOrder === displayOrderToRemove + 1) {
          c.displayOrder = displayOrderToRemove;
          displayOrderToRemove = displayOrderToRemove + 1;
        }
        return c;
      });
  }

  reorderContents(draggedContents: Section[]) {
    if (!this.diagnostic) return;
    const untouchedContents = this.diagnosticContents.filter(
      (content) => content.stageName !== this.currentStage.name || content.displayOrder === undefined
    );

    this.diagnosticContents = untouchedContents.concat(draggedContents);

    draggedContents.forEach((content, index) => (content.displayOrder = index));
    // Save vertical scroll to restore it when the contents display order is updated
    const scrollY = window.scrollY;
    setTimeout(() => window.scrollTo({ top: scrollY }), 1);
  }

  openSelectTemplate(annexTemplate?: boolean) {
    this.selectingTemplate = true;
    this.selectingAnnexTemplate = !!annexTemplate;
  }

  insertTemplate(template: Template | null, stageName: DiagnosticStageName, constat?: DiagnosticConstat) {
    this.selectingTemplate = false;

    if (template === null) return;

    if (template.category !== TemplateCategory.ANNEXES) {
      this.insertTemplateContents(template, stageName, constat);
    } else if (template.category === TemplateCategory.ANNEXES) {
      this.addAnnexTemplate(template, stageName, constat);
    }
  }

  insertTemplateContents(template: Template, stageName: DiagnosticStageName, constat?: DiagnosticConstat) {
    // Clear current contents
    console.log(this.diagnosticContents);
    this.diagnosticContents = this.diagnosticContents.filter((content) => {
      const contentInOtherConstat = content?.constat?.id !== constat?.id;
      const contentInOtherStage = content.stageName !== this.currentStage.name;
      const isLieuOrDate = content.placeholder === Placeholder.LIEU || content.placeholder === Placeholder.DATE;

      return contentInOtherConstat || contentInOtherStage || isLieuOrDate;
    });
    console.log(this.diagnosticContents);
    this.loadingContents = true;

    TemplateService.templateContentsByTemplateId(template.id).then((response) => {
      const templateContents = response.data as Section[];
      let baseDisplayOrder: number;
      const stageContents = this.diagnosticContents.filter((c) => c.stageName === stageName);
      if (stageContents.length === 0) {
        baseDisplayOrder = 0;
      } else {
        baseDisplayOrder = Math.max(...stageContents.map((c: Section) => c.displayOrder || 0));
      }
      templateContents.forEach((c) => {
        c.stageName = stageName;
        c.displayOrder = (c.displayOrder || 0) + baseDisplayOrder;
        c.constat = constat;
        c.id = undefined;
      });

      this.diagnosticContents.push(...templateContents);
      this.loadingContents = false;

      diagnosticService.diagnosticCopyAnnexes(this.accountId, template.id, stageName, constat || null).then(() => {
        this.updateStageAnnexes(stageName, constat);
      });
    });
  }

  get stageAnnexes() {
    return this.diagnosticAnnexes.filter((annex) => annex.stageName === this.currentStage.name);
  }

  get currentAnnexes() {
    if (this.currentConstat)
      return this.stageAnnexes.filter(
        (annex) => annex.constat && this.currentConstat && annex.constat.id === this.currentConstat.id
      );
    else return this.stageAnnexes;
  }

  addAnnexTemplate(template: Template, stageName: DiagnosticStageName, constat?: DiagnosticConstat) {
    diagnosticService.diagnosticAddAnnexTemplate(this.accountId, template.id, stageName, constat).then(() => {
      this.updateStageAnnexes(stageName, constat);
    });
  }

  updateStageAnnexes(stageName: DiagnosticStageName, constat?: DiagnosticConstat) {
    diagnosticService.diagnosticAnnexes(this.accountId, stageName, constat || null).then((stageAnnexes) => {
      this.clearAnnexes(stageName);
      this.diagnosticAnnexes = this.diagnosticAnnexes.concat(stageAnnexes);

      this.loadingContents = false;
    });
  }

  pushAnnex(name: string, url: string, stageName: DiagnosticStageName) {
    this.diagnosticAnnexes.push({ name, url, stageName: stageName });
  }

  clearAnnexes(stageName: DiagnosticStageName) {
    this.diagnosticAnnexes = this.diagnosticAnnexes.filter((a) => a.stageName !== stageName);
  }

  updatePipelineContent() {
    this.pipeline.forEach((stage) => {
      if (stage.substages) {
        stage.substages.forEach((substage) => {
          this.constatsInStage(substage.name).forEach((constat) => this.updateStageContent(substage, constat));
        });
      } else {
        this.updateStageContent(stage);
      }
    });
  }

  async updateStageContent(stage: DiagnosticStage, constat?: DiagnosticConstat) {
    if (!this.diagnostic) return;

    let numberOfContentsInStage;
    if (constat)
      numberOfContentsInStage = this.diagnosticContents.filter(
        (c) => c.stageName === stage.name && c.constat && c.constat.id === constat.id
      ).length;
    else numberOfContentsInStage = this.diagnosticContents.filter((c) => c.stageName === stage.name).length;

    const emptyStage =
      (stage.name === DiagnosticStageName.AVANTPROPOS && numberOfContentsInStage <= 2) || numberOfContentsInStage === 0;

    if (emptyStage) {
      const defaultTemplateInserted = await this.insertDefaultTemplate(stage.name, constat);
      if (!defaultTemplateInserted) this.updateStageLayout(stage, constat);
    }

    if (constat) this.updateStageAnnexes(stage.name, constat);
    else this.updateStageAnnexes(stage.name);
  }

  insertDefaultTemplate(stageName: DiagnosticStageName, constat?: DiagnosticConstat): Promise<boolean> {
    return TemplateService.templateDefault(TemplateCategory.DIAGNOSTICPATRIMONIAL, stageName).then((response) => {
      if (response.status === 200) {
        const defaultTemplate: Template = response.data;
        this.insertTemplate(defaultTemplate, stageName, constat);
        return true;
      }
      return false;
    });
  }

  updateStageLayout(stage: DiagnosticStage, constat?: DiagnosticConstat) {
    const layout: Placeholder[] = stage.layout;
    if (!this.diagnostic || layout.length === 0) {
      this.loadingContents = false;
      return;
    }
    const diagnosticId = this.diagnostic.id;
    this.diagnosticContents.push(
      ...layout.map((placeholder, index) => {
        const content: Section = {
          displayOrder: index,
          stageName: stage.name,
          constat: constat,
          placeholder,
          diagnosticId
        };
        return content;
      })
    );
    this.loadingContents = false;
  }

  get conseillerSignataire() {
    if (!this.diagnostic || !this.diagnostic.signature1) return null;
    return this.conseillersTotal.find(
      // @ts-ignore
      (c) => c.signature === this.diagnostic.signature1
    );
  }

  get conseillerCoSignataire() {
    if (!this.diagnostic || !this.diagnostic.signature2) return null;
    return this.conseillersTotal.find(
      // @ts-ignore
      (c) => c.signature === this.diagnostic.signature2
    );
  }

  get conseillerCocoSignataire() {
    if (!this.diagnostic || !this.diagnostic.signature3) return null;
    return this.conseillersTotal.find(
      // @ts-ignore
      (c) => c.signature === this.diagnostic.signature3
    );
  }

  get conseillers() {
    const nonNullConseillers: Array<Conseiller> = [
      this.account ? this.account.conseiller : null,
      this.conseillerSignataire,
      this.conseillerCoSignataire,
      this.conseillerCocoSignataire
    ].filter((presence) => !!presence) as Array<Conseiller>;

    const uniqConseillers: Array<Conseiller> = nonNullConseillers.filter((conseiller, index) => {
      // eslint-disable-next-line prettier/prettier
        return nonNullConseillers
          .map((c) => c && c.id)
          .indexOf(conseiller.id) === index;
    });

    return uniqConseillers.map((conseiller) => {
      return {
        conseiller: {
          picture: conseiller.picture,
          firstname: conseiller.firstname,
          lastname: conseiller.lastname
        },
        expertise: conseiller.jobTitle,
        courriel: conseiller.email
      };
    });
  }

  get signatures() {
    if (!this.diagnostic) return [];
    return [
      {
        conseiller: this.conseillerSignataire,
        signature: this.diagnostic.signature1,
        url: this.diagnostic.signature1Url
      },
      {
        conseiller: this.conseillerCoSignataire,
        signature: this.diagnostic.signature2,
        url: this.diagnostic.signature2Url
      },
      {
        conseiller: this.conseillerCocoSignataire,
        signature: this.diagnostic.signature3,
        url: this.diagnostic.signature3Url
      }
    ].filter((s) => s.signature && s.signature.length);
  }

  toggleConseillerSignature(c: Conseiller) {
    if (!this.diagnostic) return;
    if (this.diagnostic.signature1 === c.signature) {
      Vue.set(this.diagnostic, 'signature1', '');
      Vue.set(this.diagnostic, 'signature1Url', '');
    } else {
      Vue.set(this.diagnostic, 'signature1', c.signature);
      Vue.set(this.diagnostic, 'signature1Url', c.signatureUrl);
    }
  }

  toggleCoConseillerSignature(c: Conseiller) {
    if (!this.diagnostic) return;
    if (this.diagnostic.signature2 === c.signature) {
      Vue.set(this.diagnostic, 'signature2', null);
      Vue.set(this.diagnostic, 'signature2Url', null);
    } else {
      Vue.set(this.diagnostic, 'signature2', c.signature);
      Vue.set(this.diagnostic, 'signature2Url', c.signatureUrl);
    }
  }

  toggleCocoConseillerSignature(c: Conseiller) {
    if (!this.diagnostic) return;
    if (this.diagnostic.signature3 === c.signature) {
      Vue.set(this.diagnostic, 'signature3', null);
      Vue.set(this.diagnostic, 'signature3Url', null);
    } else {
      Vue.set(this.diagnostic, 'signature3', c.signature);
      Vue.set(this.diagnostic, 'signature3Url', c.signatureUrl);
    }
  }

  openCQ() {
    this.modalCQ = true;
  }

  feedbackCQ(state: DiagnosticWorkingState) {
    if (!this.diagnostic) return;
    Vue.set(this.diagnostic, 'workingState', state);
    // this.diagnostic.workingState = state;

    diagnosticService.diagnosticUpdate(this.accountId, this.diagnostic).then(() => {
      this.$router.push({
        name: 'AccountOne',
        params: { accountId: this.accountId.toString() }
      });
    });
  }

  updateAsReadyCQ() {
    if (this.diagnostic === null) return;
    Vue.set(this.diagnostic, 'workingState', DiagnosticWorkingState.PRET_CQ);
    // this.diagnostic.workingState = DiagnosticWorkingState.PRET_CQ;
    this.submit();
  }

  openModal(modal: Vue | Element | (Vue | Element)[]) {
    (modal as BModal).show();
  }

  focusContent(content: Section | null) {
    this.focusedSection = content;
  }
}
