import axios from './axios';
import { Preconisation } from '@/models/preconisation';
import { PreconisationType } from '@/models/preconisationtype';

class PreconisationService {
  async preconisation(): Promise<Preconisation[]> {
    return axios.get('/preconisation').then((response) => {
      return response.data as Preconisation[];
    });
  }

  async preconisationOne(id: number): Promise<Preconisation> {
    return axios.get('/preconisation/' + id).then((response) => {
      return response.data as Preconisation;
    });
  }

  preconisationSave(id: number | undefined, titre: string, preconisationTypeId: number, categorieSelected: number[]) {
    if (id === undefined) {
      return axios.post('/settings/preconisation', {
        titre: titre,
        preconisationTypeId: preconisationTypeId,
        categorieSelected: categorieSelected
      });
    } else {
      return axios.post('/settings/preconisation/' + id, {
        titre: titre,
        preconisationTypeId: preconisationTypeId,
        categorieSelected: categorieSelected
      });
    }
  }

  async preconisationTypeAll(): Promise<PreconisationType[]> {
    return axios.get('/preconisation/type').then((response) => {
      return response.data as PreconisationType[];
    });
  }

  async preconisationTypeById(typeId: number): Promise<PreconisationType> {
    return axios.get('/preconisation/type/' + typeId).then((response) => {
      return response.data as PreconisationType;
    });
  }

  preconisationSaveContent(id: number, preconisation: Preconisation) {
    return axios.post('/settings/preconisation/' + id + '/content', preconisation);
  }

  deletePreconisation(id: number) {
    return axios.post('/settings/preconisation/' + id + '/delete');
  }
}

export default new PreconisationService();
