
















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Diagnostic } from '@/models/diagnostic';
import { Section } from '@/models/section';
import AvantPropos from '../livrable/AvantPropos.vue';
import Sommaire from '../livrable/Sommaire.vue';
import Bilan from '../livrable/Bilan.vue';
import Objectifs from '../livrable/Objectifs.vue';
import Body from '../livrable/Body.vue';
import { DiagnosticStageName, DiagnosticConstatsStageNames } from '@/models/diagnostic_stage';
import { DiagnosticConstat } from '@/models/diagnostic_constat';
import { Definition } from '@/models/dictionary';
import { Placeholder } from '@/models/placeholder';
import DictionaryService from '@/services/dictionary';
import { DiagnosticAnnex } from '@/models/diagnostic_annex';
import { Contact } from '@/models/contact';
import ClientService from '@/services/clients';
// @ts-ignore
import CSSUnitConverter from 'css-unit-converter';
import { TableOfContents, StageTable } from '@/models/tableOfContents';

@Component({
  components: {
    AvantPropos,
    Sommaire,
    Bilan,
    Objectifs,
    Body
  }
})
export default class RapportLivrable extends Vue {
  @Prop({ required: true }) accountId!: number;
  @Prop({ required: true }) diagnostic!: Diagnostic;
  @Prop({ required: true }) contents!: Section[];
  @Prop({ required: true }) constats!: DiagnosticConstat[];
  @Prop({ required: true }) signatures!: string[];
  @Prop({ required: true }) diagnosticAnnexes!: DiagnosticAnnex[];
  @Prop({ required: false, default: false, type: Boolean }) printing!: boolean;
  definitions: Definition[] = [];
  mainContacts: Contact[] = [];
  stageNames = DiagnosticStageName;

  firstCountablePage = 5; // TODO : Increment if sommaire gets page break inserted
  analyseSommaire: StageTable = {
    start: this.firstCountablePage,
    total: 1
  };

  objectifsSommaire: StageTable = {
    start: this.firstCountablePage + 1,
    total: 1
  };

  civilSommaire: StageTable = {
    start: this.firstCountablePage + 2,
    total: 0
  };

  patrimonialSommaire: StageTable = {
    start: this.firstCountablePage + 3,
    total: 0
  };

  socialSommaire: StageTable = {
    start: this.firstCountablePage + 4,
    total: 0
  };

  economiqueSommaire: StageTable = {
    start: this.firstCountablePage + 5,
    total: 0
  };

  fiscalSommaire: StageTable = {
    start: this.firstCountablePage + 6,
    total: 0
  };

  standardPageHeightInCm = 29.7;
  standardPageHeightInPx = CSSUnitConverter(this.standardPageHeightInCm, 'cm', 'px', 0);

  async mounted() {
    const definitionsPromise = DictionaryService.definitions(this.accountId).then((response) => {
      this.definitions = response.data;
    });
    const contactsPromise = ClientService.loadContactOnAccount(this.accountId).then((response) => {
      this.mainContacts = response.sort((c1, c2) => c1.contactIndex - c2.contactIndex).slice(0, 2);
    });

    Promise.all([definitionsPromise, contactsPromise]).then(() => {
      this.$nextTick()
        .then(() => this.handlePageBreaks())
        .then(() => this.scanOverflows());
    });
  }

  scanOverflows() {
    const pagesLimitedHeight = document.querySelectorAll('.livrable.page.limited-height');
    pagesLimitedHeight.forEach((pageNode) => {
      let childrenHeight = 0;
      for (let child = pageNode.firstChild as Element; child !== null; child = child.nextSibling as Element) {
        childrenHeight += child.clientHeight;
        if (childrenHeight > pageNode.clientHeight && !pageNode.classList.contains('overflowed')) {
          pageNode.classList.add('overflowed');
          this.$emit('pushStageOverflow', pageNode.getAttribute('data-diagnostic-stage'));
          return;
        }
      }
    });
  }

  handlePageBreaks() {
    const pageBreaks = document.querySelectorAll<HTMLElement>('.page-break');

    pageBreaks.forEach((pageBreak, index) => {
      const page = pageBreak.closest<HTMLElement>('.page');
      if (!page) return;

      pageBreak.setAttribute('data-page-break-index', index.toString());

      this.incrementPageHeight(page);

      this.adjustPageBreaksSpacers(page, pageBreak);

      this.adjustPageBottomSpacer(page);
    });
  }

  private incrementPageHeight(page: HTMLElement) {
    const pageStyle = window.getComputedStyle(page);
    const currentPagesQuantity = parseInt(pageStyle.getPropertyValue('--pages'), 10);

    page.style.setProperty('--pages', (currentPagesQuantity + 1).toString());
  }

  private adjustPageBreaksSpacers(page: HTMLElement, pageBreak: HTMLElement) {
    // Find previous page break, and increase margin-top so bottom-top difference = standardPageHeightInPx
    if (!pageBreak.dataset.pageBreakIndex || pageBreak.dataset.pageBreakIndex === '0') return;

    const index = parseInt(pageBreak.dataset.pageBreakIndex, 10);
    const pageClassSelector = '.' + page.classList.toString().replaceAll(' ', '.');

    const previousPageBottom = pageBreak.getBoundingClientRect().top;
    let previousPageTop: number;
    const previousPageBreak = document.querySelector(
      `${pageClassSelector} .page-break[data-page-break-index="${index - 1}"]`
    );
    if (previousPageBreak) {
      previousPageTop = previousPageBreak.getBoundingClientRect().bottom;
    } else {
      previousPageTop = page.getBoundingClientRect().top;
    }

    const previousPageHeight = previousPageBottom - previousPageTop;
    const diff = previousPageHeight - this.standardPageHeightInPx;

    if (diff < 0) {
      // Previous page too small
      const pageBreakStyle = window.getComputedStyle(pageBreak);
      const pageBreakMarginTop = parseInt(pageBreakStyle.getPropertyValue('margin-top'), 10);
      const newMarginTop = pageBreakMarginTop + Math.abs(diff);
      pageBreak.style.setProperty('margin-top', newMarginTop + 'px');
    } else if (diff > 0) {
      // Previous page too large
      page.classList.add('overflowed');
      this.$emit('pushStageOverflow', page.getAttribute('data-diagnostic-stage'));
    }
  }

  private adjustPageBottomSpacer(page: HTMLElement) {
    const pageClassSelector = '.' + page.classList.toString().replaceAll(' ', '.');

    const lastPageBottom = page.getBoundingClientRect().bottom;
    let lastPageTop: number;
    const pageBreaks = document.querySelectorAll(`${pageClassSelector} .page-break`);
    if (pageBreaks) {
      const lastPageBreak = pageBreaks[pageBreaks.length - 1];
      lastPageTop = lastPageBreak.getBoundingClientRect().bottom;
    } else {
      lastPageTop = page.getBoundingClientRect().top;
    }

    const lastPageHeight = lastPageBottom - lastPageTop;
    const diff = lastPageHeight - this.standardPageHeightInPx;

    if (diff < 0) {
      // Last page too small
      const pageStyle = window.getComputedStyle(page);
      const pagePaddingBottom = parseInt(pageStyle.getPropertyValue('padding-bottom'), 10);
      page.style.setProperty('padding-bottom', pagePaddingBottom + Math.abs(diff) + 'px');
    } else if (diff > 0) {
      // Last page too large
      page.classList.add('overflowed');
      this.$emit('pushStageOverflow', page.getAttribute('data-diagnostic-stage'));
    }
  }

  get mainContactsNames() {
    return this.mainContacts
      .sort((c1, c2) => (c1.titre === 'Madame' ? 1 : c2.titre === 'Madame' ? -1 : 0))
      .map((contact) => [contact.titre, contact.prenom, contact.nom].join(' '));
  }

  hashContents(stageName: DiagnosticStageName) {
    return this.contents.filter((c) => c.stageName === stageName);
  }

  hashConstats(stageName: DiagnosticStageName) {
    return this.constats.filter((c) => c.stageName === stageName);
  }

  get bilansStageNames() {
    if (!this.diagnostic.constats || this.diagnostic.constats.length === 0) return [];
    const diagnosticBilansPresent: DiagnosticStageName[] = this.diagnostic.constats.map((constat) => constat.stageName);

    return DiagnosticConstatsStageNames.filter((constatStageName: DiagnosticStageName) => {
      return diagnosticBilansPresent.indexOf(constatStageName) !== -1;
    });
  }

  bilanAnnexes(stageName: DiagnosticStageName) {
    return this.diagnosticAnnexes.filter((a) => a.stageName === stageName);
  }

  updateSommaire(stageName: DiagnosticStageName, title: string, pages: number) {
    const sommaireSection = this.sommaireForStage(stageName);
    if (sommaireSection) Vue.set(sommaireSection, title, pages);
  }

  private sommaireForStage(stageName: DiagnosticStageName): StageTable | null {
    switch (stageName) {
      case DiagnosticStageName.ANALYSE:
        return this.analyseSommaire;
      case DiagnosticStageName.OBJECTIFS:
        return this.objectifsSommaire;
      case DiagnosticStageName.CIVIL:
        return this.civilSommaire;
      case DiagnosticStageName.PATRIMONIAL:
        return this.patrimonialSommaire;
      case DiagnosticStageName.SOCIAL:
        return this.socialSommaire;
      case DiagnosticStageName.ECONOMIQUE:
        return this.economiqueSommaire;
      case DiagnosticStageName.FISCAL:
        return this.fiscalSommaire;
      default:
        return null;
    }
  }

  get sommaire() {
    const sommaire: TableOfContents = {};
    [
      DiagnosticStageName.ANALYSE,
      DiagnosticStageName.OBJECTIFS,
      DiagnosticStageName.CIVIL,
      DiagnosticStageName.PATRIMONIAL,
      DiagnosticStageName.SOCIAL,
      DiagnosticStageName.ECONOMIQUE,
      DiagnosticStageName.FISCAL
    ].forEach((stageName) => (sommaire[stageName] = this.sommaireForStage(stageName) as StageTable));
    return sommaire;
  }

  get titles() {
    return this.diagnostic.contents?.filter((c) => c.placeholder === Placeholder.TITRE) ?? [];
  }
}
