




































































































import { Component, Vue } from 'vue-property-decorator';
import ProduitService from '@/services/produit';
import { Caracteristique } from '../../models/caracteristique';

@Component
export default class Search extends Vue {
  isHidden = true;
  searchInputArray: {
    caracteristique: null | Caracteristique;
    operator: string | null;
    value: string | null;
  }[] = [{ caracteristique: null, operator: null, value: null }];

  caracteristiqueList: Caracteristique[] = [];
  produitTypeSelected = null;
  produitTypeList: { value: null | number; text: string }[] = [{ value: null, text: '-- Choisir une catégorie --' }];

  caracteristiqueForProduitTypeSelected: {
    value: null | Caracteristique;
    text: string;
  }[] = [{ value: null, text: '-- Choisir une caractéristique --' }];

  binaryOptions = [
    { value: null, text: '-- Choisir une valeur --' },
    { value: false, text: 'Faux' },
    { value: true, text: 'Vrai' }
  ];

  created() {
    this.load();
  }

  load() {
    this.loadProduitType();
    this.loadCaracteristique();
  }

  loadProduitType() {
    ProduitService.loadCategorieType().then((response) => {
      response.forEach((type) => {
        this.produitTypeList.push({ value: type.id, text: type.nom });
      });
    });
  }

  loadCaracteristique() {
    ProduitService.loadCaracteristique().then((response) => {
      this.caracteristiqueList = response;
    });
  }

  operatorForCaracteristique(caracteristique: Caracteristique) {
    switch (caracteristique.inputType.id) {
      case 1: // texte
        return [
          { value: null, text: '-- Choisir un opérateur -- ' },
          { value: '~', text: 'contient' }
        ];
      case 2: // binaire
        return [
          { value: null, text: '-- Choisir un opérateur -- ' },
          { value: '~', text: 'est égal à' }
        ];
      case 3: // nombre
        return [
          { value: null, text: '-- Choisir un opérateur -- ' },
          { value: ':', text: 'est égal à' },
          { value: '<', text: 'est inférieur à' },
          { value: '$', text: 'est inférieur ou égal à' },
          { value: '>', text: 'est supérieur à ' },
          { value: '_', text: 'est supérieur ou égal à ' }
        ];
      case 4: // borne min & max
        return [
          { value: null, text: '-- Choisir un opérateur -- ' },
          { value: '!', text: 'valeur minimum égale à' },
          { value: '@', text: 'valeur maximum égale à ' }
        ];
      case 5: // note
        return [
          { value: null, text: '-- Choisir un opérateur -- ' },
          { value: ':', text: 'note égale à' },
          { value: '<', text: 'note inférieure à' },
          { value: '$', text: 'note inférieure ou égale  à' },
          { value: '>', text: 'note supérieure à' },
          { value: '_', text: 'note supérieure ou égale à' }
        ];
      case 6: // pourcentage
        return [
          { value: null, text: '-- Choisir un opérateur -- ' },
          { value: ':', text: 'pourcentage égal à' },
          { value: '<', text: 'pourcentage inférieur à' },
          { value: '$', text: 'pourcentage inférieur ou égal à' },
          { value: '>', text: 'pourcentage supérieur à' },
          { value: '_', text: 'pourcentage supérieur ou égal à' }
        ];
      case 7: // monétaire
        return [
          { value: null, text: '-- Choisir un opérateur -- ' },
          { value: ':', text: 'est égal à' },
          { value: '<', text: 'est inférieur à' },
          { value: '$', text: 'est inférieur ou égal à' },
          { value: '>', text: 'est supérieur à ' },
          { value: '_', text: 'est supérieur ou égal à ' }
        ];
      case 8: // pourcentage min & max
        return [
          { value: null, text: '-- Choisir un opérateur -- ' },
          { value: '>=', text: 'pourentage minimum à ' },
          { value: 2, text: 'pourcentage maximum à ' }
        ];
      default:
        return [{ value: null, text: '-- Choisir input --' }];
    }
  }

  onProduitTypeSelected(produitTypeId: number) {
    this.caracteristiqueForProduitTypeSelected = this.caracteristiqueList
      .filter((caracteristique) => {
        const categorieTypesId = caracteristique.categorieTypes.map((x) => x.id);
        return categorieTypesId.indexOf(produitTypeId) !== -1;
      })
      .map((caracteristique) => {
        return { value: caracteristique, text: caracteristique.nom };
      });
    this.caracteristiqueForProduitTypeSelected.splice(0, 0, {
      value: null,
      text: '-- Choisir une caractéristique --'
    });
    this.searchInputArray = [{ caracteristique: null, operator: null, value: null }];
  }

  onAddSearch() {
    this.searchInputArray.push({
      caracteristique: null,
      operator: null,
      value: null
    });
  }

  onDeleteSearch(index: number) {
    this.searchInputArray.splice(index, 1);
  }

  onSearch() {
    // Préparation du paramètre search (exemple: /api/produits/?search=66:jerome@nowly.co)
    const search = this.searchInputArray
      .filter((element) => element.caracteristique !== null)
      .map((element) => {
        return '?search=' + `${element.caracteristique?.id}${element.operator}${element.value}`;
      })
      .join(',');

    this.$router.push({ path: 'produit', query: { search } });
  }
}
