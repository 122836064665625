<template>
  <div class="vue-xeditable">
    <slot v-if="isRemoteUpdating" name="before"></slot>

    <span
      v-show="!isEditing && !isRemoteUpdating"
      class="vue-xeditable-value"
      :class="[{ 'vue-xeditable-empty': vueXeditableIsValueEmpty }, spanClass]"
      @click="XmaybeStartEditing(1, $event)"
      @dblclick="XmaybeStartEditing(2, $event)"
    >
      <template v-if="vueXeditableIsValueEmpty">{{ empty }}</template>
      <template v-else-if="type === 'select' && Array.isArray(rawValue)">{{ rawValue[rawValue.length - 1] }}</template>
      <template v-else-if="rawValue === undefined || rawValue === null">?</template>
      <template v-else-if="type === 'binaire'"
        ><i class="fas" :class="[rawValue === 'true' ? 'fa-check text-success' : 'fa-times text-danger']"></i
      ></template>
      <template v-else-if="type === 'borne min/max'"
        >de {{ rawValue.split('|')[0] }} à {{ rawValue.split('|')[1] }}</template
      >
      <template v-else-if="type === 'pourcentage min/max'"
        >de {{ rawValue.split('|')[0] }}% à {{ rawValue.split('|')[1] }}%</template
      >
      <template v-else-if="type === 'note'">{{ rawValue }} / 10 <i class="fas fa-star text-primary"></i></template>
      <template v-else-if="type === 'pourcentage'">{{ rawValue }} %</template>
      <template v-else-if="type === 'monetaire'">{{ rawValue }} €</template>
      <template v-else-if="type === 'text' || type === 'textarea' || type === 'nombre'">{{ rawValue }}</template>
      <template v-else>{{ rawValue }}</template>
    </span>

    <div v-show="isEditing && !isRemoteUpdating" class="vue-xeditable-control">
      <textarea
        v-if="type === 'textarea' || type === 'text'"
        class="vue-xeditable-form-control form-control"
        :class="inputClass"
        :value="rawValue"
        @keydown="XonKeydown"
      >
      </textarea>

      <input
        v-else-if="type === 'nombre' || type === 'pourcentage' || type === 'monetaire'"
        class="vue-xeditable-form-control form-control"
        :class="inputClass"
        type="number"
        :value="rawValue"
        @keydown="XonKeydown"
      />

      <template v-else-if="type === 'binaire'">
        <b-form-radio-group :class="inputClass" :value="rawValue">
          <b-form-radio value="true">Oui</b-form-radio>
          <b-form-radio value="false">Non</b-form-radio>
        </b-form-radio-group>
      </template>

      <template v-else-if="type === 'borne min/max' || type === 'pourcentage min/max'">
        <b-form-group label-cols="6" :label-class="'p-0'">
          <template #label>
            <input
              class="form-control bg-transparent rounded-0 text-secondary border-white-light"
              :class="inputClass"
              type="number"
              name="minMax[]"
              :value="rawValue.split('|')[0]"
              @keydown="XonKeydown"
            />
          </template>
          <input
            class="form-control bg-transparent rounded-0 text-secondary border-white-light"
            :class="inputClass"
            type="number"
            name="minMax[]"
            :value="rawValue.split('|')[1]"
            @keydown="XonKeydown"
          />
        </b-form-group>
      </template>

      <template v-else-if="type === 'note'">
        <star-rating
          :max-rating="10"
          :value="rawValue"
          :show-rating="false"
          active-color="white"
          inactive-color="#436275"
          :star-size="20"
          :inline="true"
          @rating-selected="XvalueDidChange"
        ></star-rating>
      </template>

      <x-custom-select
        v-else-if="type === 'select'"
        class="vue-xeditable-form-control bg-transparent"
        :value="rawValue"
        :options="options"
        @input="XvalueDidChange"
        @keydown="XonKeydown"
      >
      </x-custom-select>
    </div>
    <template v-if="isEditing && !isRemoteUpdating && type !== 'note'">
      <b-btn size="sm" variant="outline-info" class="fas fa-check" @click="XvalueDidChange"></b-btn>
      <b-btn size="sm" variant="outline-danger" class="fas fa-times" @click="XstopEditing"></b-btn>
    </template>
    <slot v-if="isRemoteUpdating" name="after"></slot>
  </div>
</template>

<script>
import axios from '@/services/axios';
import StarRating from 'vue-star-rating';
import XCustomSelect from './XCustomSelect.vue';

export default {
  name: 'VueXeditable',
  components: {
    StarRating,
    'x-custom-select': XCustomSelect
  },
  props: {
    value: {
      type: [String, Number, Array],
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    name: {
      type: String,
      required: false,
      default: 'VueXeditableDefaultName'
    },
    empty: {
      type: String,
      required: false,
      default: 'Aucune information'
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    options: {
      type: Array,
      default: function() {
        return [];
      }
    },
    editOnDoubleClick: {
      type: Boolean,
      required: false,
      default: false
    },
    remote: {
      type: Object,
      required: false,
      default: function() {
        return {
          url: null,
          method: 'PUT',
          key: null,
          resource: null,
          headers: null
        };
      }
    },
    inputClass: {
      type: String,
      default: ''
    },
    spanClass: {
      type: String,
      default: ''
    }
  },
  emits: [
    'value-did-change',
    'value-remote-error',
    'value-remote-update-error',
    'value-will-change',
    'stop-editing',
    'start-editing'
  ],
  data() {
    return {
      isEditing: false,
      isRemoteUpdating: false,
      rawValue: null
    };
  },
  computed: {
    vueXeditableIsValueEmpty() {
      return this.rawValue === null || this.rawValue === undefined || this.rawValue === '';
    },
    vueXeditableHasRemoteUpdate() {
      return this.remote && this.remote.url && this.remote.url.length && this.remote.key && this.remote.key.length;
    },
    vueXeditableHasValidRemote() {
      return this.vueXeditableHasRemoteUpdate && ['PUT', 'POST'].includes(this.remote.method.toUpperCase());
    }
  },
  watch: {
    value(newValue) {
      if (this.type === 'note') {
        this.rawValue = parseInt(newValue);
      } else {
        this.rawValue = newValue;
      }
    },
    options(newOptions) {
      this.rawValue = newOptions.find((o) => {
        const v = Array.isArray(o) ? o[0] : o;
        return v === this.rawValue;
      });
    }
  },
  created() {
    if (this.type === 'note') {
      this.rawValue = parseInt(this.value);
    } else {
      this.rawValue = this.value;
    }
  },
  methods: {
    XonKeydown(event) {
      if (!this.isEditing) {
        return;
      }
      if (event.keyCode === 27) {
        this.XstopEditing(event);
      }
    },
    XmaybeStartEditing(value, event) {
      if ((value === 1 && !this.editOnDoubleClick) || (value === 2 && this.editOnDoubleClick)) {
        this.XstartEditing(event);
      }
    },
    XstartEditing(event) {
      this.isEditing = true;
      this.$emit('start-editing', this.rawValue, this.name);
      setTimeout(function() {
        if (event.target.nextElementSibling !== null) {
          const inputs = Array.from(event.target.nextElementSibling.children);
          inputs.forEach((i) => i.focus());
        }
      }, 100);
    },
    XstopEditing(event) {
      this.isEditing = false;
      this.$emit('stop-editing', this.rawValue, this.name, event);
    },
    XvalueDidChange(event) {
      let newValue;
      if (this.type === 'borne min/max' || this.type === 'pourcentage min/max') {
        const first = event.target.parentElement.querySelector('legend input').value;
        const second = event.target.parentElement.querySelector('div[role=group] input').value;
        newValue = first + '|' + second;
      } else if (this.type === 'binaire') {
        newValue = event.target.parentElement.querySelector('input:checked').value;
      } else if (this.type === 'note' || this.type === 'select') {
        newValue = event;
      } else {
        newValue = event.target.parentElement.querySelector('.vue-xeditable-form-control').value;
      }
      console.log('XvalueDidChange', { newValue, event });
      if (this.type === 'select' || this.type === 'binaire' || this.type === 'note') {
        this.XstopEditing(); // Needed because no events can be associated with select / option?...
      }
      this.XstopEditing(event);
      if (this.XhasValueChanged(newValue) || this.type === 'select' || this.type === 'binaire') {
        this.$emit('value-will-change', this.rawValue, this.name);
        if (this.vueXeditableHasRemoteUpdate) {
          if (this.vueXeditableHasValidRemote) {
            this.XsendRemoteUpdate(newValue)
              .then(() => {
                this.$emit('value-did-change', newValue, this.name);
              })
              .catch((error) => {
                this.$emit('value-remote-update-error', newValue, error, this.name);
              });
          } else {
            this.$emit('value-remote-error', 'Invalid Remote Update configuration.');
          }
        } else {
          this.XmakeLocalUpdate(newValue);
          this.$emit('value-did-change', newValue, this.name);
        }
      }
    },
    XhasValueChanged(newValue) {
      return newValue !== this.rawValue;
    },
    XmakeLocalUpdate(newValue) {
      this.rawValue = newValue;
    },
    XsendRemoteUpdate(newValue) {
      let updateValue = null;
      if (this.type === 'number') {
        updateValue = parseFloat(newValue);
      } else if (this.type === 'select') {
        updateValue = Array.isArray(newValue) ? newValue[0] : newValue;
      } else {
        updateValue = newValue.toString();
      }
      const payload = {};
      if (this.remote.resource && this.remote.resource.length) {
        const subpayload = {};
        subpayload[this.remote.key] = updateValue;
        payload[this.remote.resource] = subpayload;
      } else {
        payload[this.remote.key] = updateValue;
      }
      return new Promise((resolve, reject) => {
        this.isRemoteUpdating = true;
        axios({
          method: this.remote.method,
          url: this.remote.url,
          headers: this.remote.headers || {},
          data: payload
        })
          .then((response) => {
            this.isRemoteUpdating = false;
            this.XmakeLocalUpdate(newValue);
            resolve(newValue, response);
          })
          .catch((error) => {
            this.isRemoteUpdating = false;
            reject(error);
          });
      });
    }
  }
};
</script>

<style>
.vue-xeditable {
  color: #222;
  cursor: pointer;
  width: inherit;
}
.vue-xeditable:hover {
  color: #666;
}
.vue-xeditable-value {
  white-space: pre-wrap;
  user-select: none;
  word-break: break-word;
}
.vue-xeditable-empty {
  color: #ea0002;
  font-style: italic;
}
.vue-xeditable-control {
  width: inherit;
}
.vue-xeditable-form-control {
  padding: 5px;
  box-sizing: content-box;
  color: #555;
  background-color: #fff;
  background-image: none;
  outline: none;
}
</style>
