


















































































import { Component, Vue, Prop } from 'vue-property-decorator';
// @ts-ignore
import VueScrollingTable from 'vue-scrolling-table';
import ArrayUtils from '@/utils/ArrayUtils';
import { Produit } from '@/models';
import AcmService from '@/services/acm';

@Component({
  components: {
    VueScrollingTable
  }
})
export default class ModalComparatorACM extends Vue {
  @Prop({ required: true }) acmPrecoProduit!: Produit[];
  @Prop({ type: Number, required: true }) acmId!: number;
  @Prop({ type: Number, required: true }) acmPrecoId!: number;

  checked: number[] = [];
  caracteristiqueToRemove: number[] = [];
  caracteristiqueToAdd: number[] = [];

  mounted() {
    this.showCheckedCaracteristique();
  }

  get groupedCaracteristique() {
    const flat: {
      nom: string;
      id: number;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      produit?: any;
    }[] = ArrayUtils.flatMap(this.acmPrecoProduit, (produit) => {
      return produit.produitHasCaracteristiques.map((produitHasCaracteristique) => {
        return {
          nom: produitHasCaracteristique.caracteristique.nom,
          id: produitHasCaracteristique.caracteristique.id
        };
      });
    });
    return ArrayUtils.unique(flat).map((carateristique) => {
      carateristique.produit = this.acmPrecoProduit.map((produit) => {
        const data = produit.produitHasCaracteristiques.find((produitHasCaracteristique) => {
          return produitHasCaracteristique.caracteristique.id === carateristique.id;
        });
        return {
          produitId: produit.id,
          value: data !== undefined ? data.value : undefined
        };
      });
      return carateristique;
    });
  }

  open() {
    // @ts-ignore
    this.$refs.modalInfo.show();
  }

  close() {
    if (this.caracteristiqueToAdd.length > 0) {
      this.caracteristiqueToAdd = [];
    }
    if (this.caracteristiqueToRemove.length > 0) {
      this.caracteristiqueToRemove = [];
    }
    // @ts-ignore
    this.$refs.modalInfo.hide();
  }

  showCheckedCaracteristique() {
    AcmService.acmHasCaracteristiqueGet(this.acmId, this.acmPrecoId).then((response) => {
      this.checked = response;
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateCaracteristiqueList(event: any) {
    if (this.checked.indexOf(parseInt(event.target.value)) === -1) {
      this.caracteristiqueToAdd.push(event.target.value);
      this.caracteristiqueToRemove = this.caracteristiqueToRemove.filter((id) => id !== event.target.value);
    } else {
      this.caracteristiqueToRemove.push(event.target.value);
      this.caracteristiqueToAdd = this.caracteristiqueToAdd.filter((id) => id !== event.target?.value);
    }
  }

  updateAcmHasCaracteristique() {
    if (this.caracteristiqueToRemove.length > 0) {
      AcmService.acmHasCaracteristiqueDelete(this.acmId, this.acmPrecoId, this.caracteristiqueToRemove).then(
        (response) => {
          if (response) {
            this.caracteristiqueToRemove = [];
            this.showCheckedCaracteristique();
          }
        }
      );
    }
    if (this.caracteristiqueToAdd.length > 0) {
      AcmService.acmHasCaracteristiqueAdd(this.acmId, this.acmPrecoId, this.caracteristiqueToAdd).then((response) => {
        if (response) {
          this.caracteristiqueToAdd = [];
          this.showCheckedCaracteristique();
        }
      });
    }
    this.close();
  }
}
