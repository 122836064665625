import 'mutationobserver-shim';
import Vue from 'vue';

import { BootstrapVue } from 'bootstrap-vue';
// import './assets/sass/style.scss';
Vue.use(BootstrapVue);

import './plugins/ckeditor';
import './plugins/moment';
import './plugins/sentry';
import './plugins/vuelidate';
import './plugins/vuejs-logger';
import './plugins/vuescroll';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

Vue.config.productionTip = false;

export default new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
