import axios from './axios';
import { Contact } from '@/models/contact';

class ClientService {
  async loadContact(): Promise<Contact[]> {
    return axios.get<Contact[]>('/clients/').then((response) => {
      return response.data;
    });
  }

  async loadContactOnAccount(accountId: number): Promise<Contact[]> {
    return axios.get<Contact[]>(`/clients/account/${accountId}`).then((response) => {
      return response.data;
    });
  }

  async contact(id: number): Promise<Contact> {
    return axios.get<Contact>('/clients/' + id).then((response) => {
      return response.data;
    });
  }

  async loadContactBYPage(pageNumber: number, size: number): Promise<Contact[]> {
    return axios.get<Contact[]>('/clients/pagination?page=' + pageNumber + '&size=' + size).then((response) => {
      return response.data;
    });
  }

  async reorder(contacts: Contact[]): Promise<Contact[]> {
    return axios.post<Contact[]>('/clients/reorder', contacts).then((response) => {
      return response.data;
    });
  }
}

export default new ClientService();
