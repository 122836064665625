













import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
// @ts-ignore
import StarRating from 'vue-star-rating';
@Component({
  components: {
    StarRating
  }
})
export default class NoteInput extends Vue {
  @Prop({ required: true }) value!: number;
  @Prop({ type: Boolean, default: false, required: false }) noClose!: boolean;

  model = this.value;
  confirm = false;

  @Watch('value')
  onValue(n: number) {
    this.model = n;
  }

  @Watch('model')
  onModel() {
    this.$emit('input', this.model);
  }
}
