



















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import AutoCompletion from './AutoCompletion.vue';
@Component({
  components: { AutoCompletion }
})
export default class TextInput extends Vue {
  @Prop({ required: true }) value!: string;
  @Prop({ required: true }) placeholder!: string;
  @Prop({ type: Number, default: undefined, required: false }) caractId?: number;
  @Prop({ type: Boolean, default: false, required: false }) noClose!: boolean;

  model: string = this.value || '';
  confirm = false;

  updateValue(value: string) {
    this.model = value;
  }

  @Watch('value')
  onValue(n: string) {
    this.model = n;
  }

  @Watch('model')
  onModel() {
    this.$emit('input', this.model);
  }
}
