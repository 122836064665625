import axios from './axios';
import { Selection } from '@/models/selection';

class SelectionService {
  async selectionAll(): Promise<Selection[]> {
    return axios.get('/selection/').then((response) => {
      return response.data as Selection[];
    });
  }

  async selectionById(selectionId: number): Promise<Selection> {
    return axios.get('/selection/' + selectionId).then((response) => {
      return response.data as Selection;
    });
  }

  async selectionSave(selection: { nom: string; produits: number[] }): Promise<Selection> {
    return axios.post('/selection/new/save', selection).then((response) => {
      return response.data as Selection;
    });
  }

  async selectionEdit(selectionId: number, selection: { nom: string; produits: number[] }): Promise<Selection> {
    return axios.post('/selection/' + selectionId + '/edit', selection).then((response) => {
      return response.data as Selection;
    });
  }

  selectionDeleteById(selectionId: number) {
    return axios.post('/selection/' + selectionId + '/delete');
  }
}

export default new SelectionService();
