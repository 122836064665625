



































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ProduitService from '@/services/produit';
// @ts-ignore
import draggable from 'vuedraggable';
import Breadcrumb from './Breadcrumb.vue';
import BinaryInput from '@/components/elements/produit/BinaryInput.vue';
import BoundaryInput from '@/components/elements/produit/BoundaryInput.vue';
import NoteInput from '@/components/elements/produit/NoteInput.vue';
import NumberInput from '@/components/elements/produit/NumberInput.vue';
import PourcentageInput from '@/components/elements/produit/PourcentageInput.vue';
import TextInput from '@/components/elements/produit/TextInput.vue';
import Scrollable from '@/components/elements/Scrollable.vue';
import BoundaryPourcentageInput from '@/components/elements/produit/BoundaryPourcentageInput.vue';
import CustomListSelect from '@/components/elements/produit/CustomListSelect.vue';
import { Caracteristique, CaracteristiqueWithValue } from '../../models/caracteristique';
import { Produit } from '../../models/produit';

@Component({
  components: {
    draggable,
    Breadcrumb,
    BinaryInput,
    BoundaryInput,
    NoteInput,
    NumberInput,
    PourcentageInput,
    TextInput,
    Scrollable,
    BoundaryPourcentageInput,
    CustomListSelect
  }
})
export default class ProduitEditContent extends Vue {
  @Prop({ required: true }) produitId!: number;

  produit: Produit | null = null;
  produitName = '';
  produitNameError = false;
  produitPointFort = '';
  produitPointFaible = '';
  caracteristiques: CaracteristiqueWithValue[] = [];
  allCaracteristiques: Caracteristique[] = [];
  content: CaracteristiqueWithValue[] = [];
  originalContent: CaracteristiqueWithValue[] = [];
  hasError = false;
  isEdited = false;
  search = '';

  created() {
    this.loadProduit();
  }

  onFilterCaracteristique(value: string) {
    if (value === '') {
      this.caracteristiques = this.allCaracteristiques.slice(0);
    } else {
      this.caracteristiques = this.allCaracteristiques.filter(
        (c) => c.nom.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    }
  }

  nameChange() {
    this.hasError = false;
    this.produitNameError = false;
  }

  checkIfEdited() {
    if (this.produit && this.produitName !== this.produit.nom) {
      this.isEdited = true;
    } else if (this.originalContent.length !== this.content.length) {
      this.isEdited = true;
    } else {
      let i = 0;
      for (; i < this.originalContent.length; i++) {
        if (this.content[i].id !== this.originalContent[i].id) {
          this.isEdited = true;
          break;
        } else if (this.content[i].value !== this.originalContent[i].value) {
          this.isEdited = true;
          break;
        }
      }
      if (i === this.originalContent.length) {
        this.isEdited = false;
      }
    }
  }

  loadProduit() {
    ProduitService.produitOne(this.produitId).then((data) => {
      this.produit = data;
      this.produitName = this.produit.nom;
      this.produitPointFaible = this.produit.pointFaible;
      this.produitPointFort = this.produit.pointFort;
      this.loadCaracteristique();
    });
  }

  loadMaster() {
    ProduitService.produitCaracteristique(this.produitId).then((response) => {
      response.forEach((x) => {
        const item = this.caracteristiques.find((y) => x.caracteristique.id === y.id);
        this.caracteristiques = this.caracteristiques.filter((x) => x.id !== item?.id);
        this.allCaracteristiques = this.caracteristiques.slice(0);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        item!.value = x.value;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        item!.index = x.index;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.content.push(item!);
      });
      this.content = this.content.sort((a, b) => a.index - b.index);
      this.originalContent = JSON.parse(JSON.stringify(this.content));
    });
  }

  loadCaracteristique() {
    ProduitService.loadCaracteristique().then((response) => {
      this.caracteristiques = response;
      this.allCaracteristiques = this.caracteristiques.slice(0);
      this.loadMaster();
    });
  }

  invalidElement(element: CaracteristiqueWithValue, b: boolean) {
    element.error = b;
    this.hasError = this.content.map((e) => e.error).filter((v) => v === true).length > 0;
  }

  saveProduit() {
    this.content.forEach((value, index) => {
      value.index = index;
    });
    ProduitService.produitEdit(
      this.produitId,
      this.content,
      this.produitName,
      this.produitPointFort,
      this.produitPointFaible
    )
      .then(() => {
        this.content = [];
        this.originalContent = [];
        this.caracteristiques = [];
        this.loadProduit();
      })
      .catch((e) => {
        if (e.response.data !== undefined && e.response.data.code === 1) {
          this.hasError = true;
          this.produitNameError = true;
        } else {
          throw e;
        }
      });
  }

  beforeOrNotify() {
    if (this.isEdited) {
      // @ts-ignore
      this.$refs.modalConfirm.show();
    } else {
      this.$router.push({
        name: 'ProduitOne',
        params: { produitId: `${this.produitId}` }
      });
    }
  }

  modalValidate() {
    this.$router.push({ name: 'Produit' });
  }

  modalCancel() {
    // @ts-ignore
    this.$refs.modalConfirm.hide();
  }

  filter(evt: { item: { id: string } }) {
    const item = this.content.find((x) => x.id === parseInt(evt.item.id));
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.content = this.content.filter((x) => x.id !== item!.id);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.caracteristiques.push(item!);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.allCaracteristiques.push(item!);
  }

  @Watch('content')
  onContentChange() {
    this.checkIfEdited();
    this.allCaracteristiques = this.allCaracteristiques.filter((x) => {
      return this.content.find((y) => y.id === x.id) === undefined;
    });
  }
}
