















import { Component, Vue } from 'vue-property-decorator';
import Header from './components/Header.vue';
import axios from '@/services/axios';
import { UserModule } from '@/store/modules/user';

@Component({
  components: {
    Header
  }
})
export default class App extends Vue {
  private uiVersion: string = process.env.VUE_APP_VERSION || '';
  private backendStatus = {};

  private get user() {
    return UserModule.user;
  }

  public get isLogin() {
    return this.$route.path === '/login';
  }

  public created() {
    axios
      .get('/status')
      .then((response) => {
        this.backendStatus = response.data;
      })
      .catch((response) => {
        console.warn('Unexpected response from BackEnd', response);
      });
  }
}
