/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import Vuex, { Store, Module, ModuleOptions } from 'vuex';
import VuexPersistence from 'vuex-persist';
import { UserState } from './modules/user';
import { PanierState } from './modules/panier';
import { IdsHistoryState } from './modules/ids_history';

Vue.use(Vuex);

export interface RootState {
  user: UserState;
  panier: PanierState;
  templatesIdsHistory: IdsHistoryState;
}

const dynamicRegisterPlugin = (store: Store<RootState>) => {
  // Override dynamic module handling in vuex
  let origRegisterModule: any;
  if (store.registerModule) {
    origRegisterModule = store.registerModule.bind(store);
    // your module interfaces
    type T = UserState | PanierState | IdsHistoryState;
    // @ts-ignore
    store.registerModule = (path: string, module: Module<T, RootState>, options?: ModuleOptions) => {
      // eslint-disable-next-line no-prototype-builtins
      if ((store.state as Record<string, any>).hasOwnProperty(path)) {
        origRegisterModule(path, module, { preserveState: true });
      } else {
        origRegisterModule(path, module, options);
      }
      // origRegisterModule(path, module, options)
    };
  }
};

const vuexLocal = new VuexPersistence<RootState>({
  storage: window.localStorage
});

export default new Vuex.Store<RootState>({
  plugins: [dynamicRegisterPlugin, vuexLocal.plugin]
});
