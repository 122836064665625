



































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import AcmService from '@/services/acm';
import ConseillerService from '@/services/conseiller';
import ClientService from '@/services/clients';
import SalesforceService from '@/services/salesforce';
import Breadcrumb from './Breadcrumb.vue';
import FormInputAppend from '@/components/elements/form-input-append.vue';
import Scrollable from '@/components/elements/Scrollable.vue';
import ModalContactInfo from '@/components/contact/ModalContactInfo.vue';
import { Contact } from '../../models/contact';

@Component({
  components: {
    Breadcrumb,
    FormInputAppend,
    Scrollable,
    ModalContactInfo
  }
})
export default class AcmCreateSelectClient extends Vue {
  clients: Contact[] = [];
  itemsToRender: Contact[] = [];
  showSpinner = false;
  filter: string | null = null;
  conseiller = null;
  conseillerList: { value: number; text: string }[] = [];
  active: boolean | null = null;
  // totalRows: 0,
  // currentPage: 1,
  // perPage: 10

  created() {
    this.loadData();
  }

  loadData() {
    this.showSpinner = true;
    ClientService.loadContact().then((response) => {
      this.showSpinner = false;
      this.clients = response;
      this.itemsToRender = this.clients.slice(0);
    });
    // Rest.loadContactBYPage(this.currentPage - 1, this.perPage).then(response => {
    //   this.totalRows = response.data.totalElements
    //   this.clients = response.data.content
    //   this.itemsToRender = this.clients
    // })
    ConseillerService.conseillerList().then((response) => {
      this.conseillerList = [];
      this.conseillerList.push(
        ...response.map((item) => {
          return { value: item.id, text: item.firstname + ' ' + item.lastname };
        })
      );
    });
  }

  saveAcm(clientId: number) {
    AcmService.acmCreateDraft(clientId).then((response) => {
      this.$router.push({
        name: 'AcmCreateEdit',
        params: { acmId: `${response.id}` }
      });
    });
  }

  filterClient() {
    if (this.conseiller === null) {
      this.itemsToRender = this.clients;
    } else {
      this.itemsToRender = this.clients.filter(
        (client) => client.conseiller.id !== null && client.conseiller.id === this.conseiller
      );
    }
    if (this.filter !== null && this.filter.trim().length > 0) {
      const fil = this.filter.trim().toLowerCase();
      this.itemsToRender = this.clients.filter((client) => {
        if (client.email === undefined) {
          return client.prenom.toLowerCase().includes(fil) || client.nom.toLowerCase().includes(fil);
        }
        return (
          client.prenom.toLowerCase().includes(fil) ||
          client.nom.toLowerCase().includes(fil) ||
          client.email.toLowerCase().includes(fil)
        );
      });
    }
  }

  updateClientList() {
    this.active = true;
    this.showSpinner = true;
    SalesforceService.contactsSync()
      .then((response) => {
        this.showSpinner = false;
        if (response.status === 200) {
          this.modalShow();
          this.active = null;
        }
      })
      .catch((error) => {
        this.showSpinner = false;
        if (error.response.status === 400 || error.response.status === 409) {
          this.modalError();
          this.active = null;
          this.loadData();
        }
      });
  }

  modalValidate() {
    // @ts-ignore
    this.$refs.modalInfo.hide();
    this.loadData();
  }

  modalShow() {
    // @ts-ignore
    this.$refs.modalInfo.show();
  }

  modalError() {
    // @ts-ignore
    this.$refs.modalWarning.show();
  }

  @Watch('conseiller')
  onconseiller() {
    this.filterClient();
  }

  @Watch('filter')
  onfilter() {
    this.filterClient();
  }
}
