



























































































































































































































































































import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import {
  Template,
  TemplateCategory,
  TemplateSubCategory,
  TemplateRiskLevel,
  TemplateConstats
} from '../../models/template';
import { Section } from '../../models/section';
import { templateCategoryTree } from '../elements/TemplateCategoryTree';
import ContentForm from '@/components/diagnostic/ContentForm.vue';
import TemplateSidebar from '../sidebar/TemplateSidebar.vue';
import ModalTemplatePreview from '@/components/template/ModalTemplatePreview.vue';
import TemplateService from '@/services/template';
import { DiagnosticStageName } from '@/models/diagnostic_stage';

@Component({
  components: {
    ContentForm,
    TemplateSidebar,
    ModalTemplatePreview
  },
  filters: {
    capitalize(value: string) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }
})
export default class TemplateForm extends Vue {
  @Prop({ required: true }) template!: Template;
  @Prop({ required: true }) templateContents!: Section[];
  @Prop({ required: true }) formSubmission!: boolean;
  @Prop({ required: true }) errors!: string[];
  @Prop({ required: true }) loading!: boolean;
  @Prop({ required: false }) autoSubmitCountdown?: number;
  @Prop({ required: false }) autoSubmitToggle?: boolean;

  scrollPosition = 0;

  nameEditable = false;
  templateAnnexes: { name: string; url: string }[] = [];

  modalPreview = false;

  get creation(): boolean {
    return this.$route.name === 'TemplateCreate';
  }

  get isConstat(): boolean {
    return (
      this.template.category === TemplateCategory.DIAGNOSTICPATRIMONIAL &&
      TemplateConstats.includes(this.template.subcategory as DiagnosticStageName)
    );
  }

  get isAnnexTemplate(): boolean {
    return this.template.category === TemplateCategory.ANNEXES;
  }

  uppercase = (value: string) => {
    if (!value) return '';
    return value.toString().toUpperCase();
  };

  findCategory = (cat: string): TemplateCategory => {
    return (TemplateCategory as { [key: string]: TemplateCategory })[cat];
  };

  findSubCategory = (cat: string): TemplateSubCategory | DiagnosticStageName => {
    return (
      (TemplateSubCategory as { [key: string]: TemplateSubCategory })[cat] ||
      (DiagnosticStageName as { [key: string]: DiagnosticStageName })[cat]
    );
  };

  getAnnexFiletype = (name: string): string => {
    if (name.match(/\.(jpg|jpeg|gif|png|webp)$/)) {
      return 'image';
    }
    if (name.match(/\.(doc|docx|pdf)$/)) {
      return 'document';
    }
    return 'unknown';
  };

  categories: { value: string; text: string }[] = Object.keys(TemplateCategory).map((categoryKey: string) => {
    if (categoryKey === undefined) return { value: categoryKey, text: '' };
    const translation = this.$root.$t(`templates.${categoryKey}.category`);
    return {
      value: categoryKey,
      text: this.uppercase(translation.toString())
    };
  });

  subcategories: { value: string; text: string }[] = [];

  riskLevels = TemplateRiskLevel;
  riskLevelsOptions: { value: string; text: string }[] = Object.keys(this.riskLevels).map((key: string) => {
    if (key === undefined) return { value: key, text: '' };
    const translation = this.$root.$t(`templates.risk.${key}`);
    return {
      value: key,
      text: this.uppercase(translation.toString())
    };
  });

  mounted() {
    console.log('TemplateForm - mounted');
    if (this.template) {
      this.updateSubCategories(this.template.category.toString());
    }

    window.addEventListener('scroll', this.updateScroll);
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScroll);
  }

  updateScroll() {
    this.scrollPosition = window.scrollY;
  }

  updateName(e: Event) {
    if (e.type === 'keydown' && (e as KeyboardEvent).key === 'Enter') e.preventDefault();
    const title = document.getElementById('templateName') as HTMLElement;

    this.template.name = title.innerText.trim();
    title.innerText.trim();
    this.nameEditable = false;
    if (e.type !== 'blur') title.blur();
  }

  @Watch('template')
  onTemplate() {
    this.updateSubCategories(this.template.category.toString());
  }

  changeCategory(cat: string) {
    this.template.category = this.findCategory(cat);
    this.updateSubCategories(this.template.category);
    this.$emit('changeCategory');
  }

  changeSubCategory(subcat: string) {
    this.template.subcategory = this.findSubCategory(subcat);
    this.$emit('changeCategory');
  }

  @Watch('template.category')
  updateSubCategories(category: string) {
    const categoryNode = templateCategoryTree.find((node) => node.code === category);
    if (categoryNode && categoryNode.subcategories) {
      const subcategoryCodes = categoryNode.subcategories.map((s) => s.code);
      this.subcategories = subcategoryCodes.map((subcatKey: string) => {
        if (subcatKey === undefined) return { value: subcatKey, text: '' };
        const translation = this.$t(`templates.${categoryNode.code}.${subcatKey}`);
        return {
          value: subcatKey,
          text: this.uppercase(translation.toString())
        };
      });

      if (!subcategoryCodes.includes(this.template.subcategory.toString())) {
        this.template.subcategory = categoryNode.subcategories[0].value as TemplateSubCategory;
      }
    } else {
      this.subcategories = [];
    }
  }

  async submit() {
    if (!this.template.name || this.template.name.trim() === '') {
      this.errors.push('nameRequired');
      return;
    }
    // @ts-ignore
    await this.$refs.contentForm.uploadAllAnnexes();

    this.$emit('submit');
  }

  get previewAvailable() {
    return this.templateContents.length;
  }

  updateAnnexes() {
    TemplateService.templateAnnexes(this.template.id).then((response) => {
      this.templateAnnexes = [];
      (response.data as { key: string; value: string }[]).forEach((nameAndURL: { [key: string]: string }) => {
        const name = Object.getOwnPropertyNames(nameAndURL)[0];
        const url = nameAndURL[name];
        this.templateAnnexes.push({ name, url });
      });
    });
  }
}
