import axios from './axios';
import { Conseiller } from '@/models/conseiller';

class ConseillerService {
  async me(): Promise<Conseiller> {
    return axios.get('/conseiller/me').then((response) => {
      return response.data;
    });
  }

  async conseillerList(): Promise<Conseiller[]> {
    return axios.get('/conseiller').then((response) => {
      return response.data;
    });
  }

  uploadProfilePicture(conseillerId = null): string {
    if (conseillerId === null) {
      return axios.defaults.baseURL + '/conseiller/uploadProfilePicture';
    } else {
      return axios.defaults.baseURL + '/conseiller/uploadProfilePicture?conseiller=' + conseillerId;
    }
  }

  uploadSignatureUrl(conseillerId: number | null = null): string {
    if (conseillerId === null) {
      return axios.defaults.baseURL + '/conseiller/uploadSignature';
    } else {
      return axios.defaults.baseURL + '/conseiller/uploadSignature?conseiller=' + conseillerId;
    }
  }
}

export default new ConseillerService();
