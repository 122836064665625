export enum Placeholder {
  TITRE = 'TITRE',
  SOUS_TITRE = 'SOUS_TITRE',
  SOUS_TITRE_INTERNE = 'SOUS_TITRE_INTERNE',
  BLOC_TEXTE = 'BLOC_TEXTE',
  AVANTAGES_RISQUES = 'AVANTAGES_RISQUES',
  EQUILIBRE_FINANCIER_PRECONISATION = 'EQUILIBRE_FINANCIER_PRECONISATION',
  LIEU = 'LIEU',
  DATE = 'DATE',
  OBJECTIF = 'OBJECTIF',
  ENJEU = 'ENJEU'
}
