























































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import ProduitService from '@/services/produit';
// @ts-ignore
import draggable from 'vuedraggable';
import Breadcrumb from './Breadcrumb.vue';
import BinaryInput from '@/components/elements/produit/BinaryInput.vue';
import BoundaryInput from '@/components/elements/produit/BoundaryInput.vue';
import NoteInput from '@/components/elements/produit/NoteInput.vue';
import NumberInput from '@/components/elements/produit/NumberInput.vue';
import PourcentageInput from '@/components/elements/produit/PourcentageInput.vue';
import TextInput from '@/components/elements/produit/TextInput.vue';
import moment from 'moment';
import Scrollable from '@/components/elements/Scrollable.vue';
import BoundaryPourcentageInput from '@/components/elements/produit/BoundaryPourcentageInput.vue';
import CustomListSelect from '@/components/elements/produit/CustomListSelect.vue';
import { CategorieProduit } from '../../models/categorieproduit';
import { Caracteristique, CaracteristiqueWithValue } from '../../models/caracteristique';

@Component({
  components: {
    CustomListSelect,
    draggable,
    Breadcrumb,
    BinaryInput,
    BoundaryInput,
    NoteInput,
    NumberInput,
    PourcentageInput,
    TextInput,
    Scrollable,
    BoundaryPourcentageInput
  }
})
export default class ProduitCreateContent extends Vue {
  @Prop({ required: true }) id!: number;
  @Prop({ required: true }) orga!: number;

  produitName = '';
  produitNameError = false;
  produitPointFort = '';
  produitPointFaible = '';
  caracteristiques: Caracteristique[] = [];
  filterCaracteristique = '';
  allCaracteristiques: Caracteristique[] = [];
  categorie: CategorieProduit | null = null;
  content: CaracteristiqueWithValue[] = [];
  hasError = false;

  created() {
    const date = new Date();
    const formattedDate = moment(date).format('YYYY-MM-DD');
    this.produitName = 'Nouveau produit du ' + formattedDate;
    ProduitService.loadOneCategorie(this.id).then((response) => {
      this.categorie = response;
      this.loadCaracteristique();
    });
  }

  onFilterCaracteristique(value: string) {
    if (value === '') {
      this.caracteristiques = this.allCaracteristiques.slice(0);
    } else {
      this.caracteristiques = this.allCaracteristiques.filter(
        (c) => c.nom.toLowerCase().indexOf(this.filterCaracteristique.toLowerCase()) !== -1
      );
    }
  }

  nameChange() {
    this.hasError = false;
    this.produitNameError = false;
  }

  loadMaster() {
    ProduitService.loadCategorieMaster(this.id).then((response) => {
      response.forEach((x) => {
        const item = this.caracteristiques.find((y) => x.id === y.id);
        if (item !== undefined) {
          item.index = x.index;
          this.caracteristiques = this.caracteristiques.filter((x) => x.id !== item.id);
          this.allCaracteristiques = this.caracteristiques.slice(0);
          this.content.push(item);
        }
      });
      this.content = this.content.sort((a, b) => a.index - b.index);
    });
  }

  loadCaracteristique() {
    ProduitService.loadCaracteristique().then((response) => {
      this.caracteristiques = response.filter((carac) => {
        return (
          this.categorie !== null &&
          carac.categorieTypes.map((x) => x.id).indexOf(this.categorie.categorieType.id) !== -1
        );
      });
      this.allCaracteristiques = this.caracteristiques.slice(0);
      this.loadMaster();
    });
  }

  invalidElement(element: CaracteristiqueWithValue, boolean: boolean) {
    element.error = boolean;
    this.hasError = this.content.map((e) => e.error).filter((v) => v === true).length > 0;
  }

  saveProduit() {
    this.content.forEach((value, index) => {
      value.index = index;
    });
    ProduitService.produitCreate(
      this.orga,
      this.id,
      this.content,
      this.produitName,
      this.produitPointFort,
      this.produitPointFaible
    )
      .then((data) => {
        this.$router.push({
          name: 'ProduitOne',
          params: { produitId: `${data.id}` }
        });
      })
      .catch((e) => {
        if (e.response.data !== undefined && e.response.data.code === 1) {
          this.hasError = true;
          this.produitNameError = true;
        } else {
          throw e;
        }
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRemoveFromContent(evt: any) {
    const item = this.content.find((x) => x.id === parseInt(evt.item.id));
    this.content = this.content.filter((x) => x.id !== item?.id);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.caracteristiques.push(item!);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDraggedFromListToContent(evt: any) {
    this.allCaracteristiques = this.allCaracteristiques.filter((x) => x.id !== parseInt(evt.removed.element.id));
  }
}
