




















































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import ClientsService from '@/services/clients';
@Component
export default class ModalContactInfo extends Vue {
  @Prop({ type: Number }) contactId!: number;
  modalInfo = {};

  resetModal() {
    this.modalInfo = {};
  }

  open() {
    if (this.contactId !== undefined) {
      ClientsService.contact(this.contactId).then((response) => {
        this.modalInfo = response;
        // @ts-ignore
        this.$refs.myModalRef.show();
      });
    }
  }
}
