import { Conseiller } from '@/models/conseiller';

export default class User {
  public id: number;
  public email: string;
  public firstname: string;
  public lastname: string;
  public picture: string;
  public pictureUrl: string;
  public signature: string;
  public signatureUrl: string;

  // @ts-ignore
  constructor(conseiller: Conseiller) {
    this.id = conseiller.id;
    this.email = conseiller.email;
    this.firstname = conseiller.firstname;
    this.lastname = conseiller.lastname;
    this.picture = conseiller.picture;
    this.pictureUrl = conseiller.pictureUrl;
    this.signature = conseiller.signature;
    this.signatureUrl = conseiller.signatureUrl;
  }
}
