


















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import ModalAddCaracteristique from './SettingsCaracteristiqueModalAddEdit.vue';
import SettingsCaracteristiqueModalError from './SettingsCaracteristiqueModalError.vue';
import { Caracteristique } from '../../../models/caracteristique';
import { CategorieType } from '../../../models/categorietype';
import ProduitService from '@/services/produit';
@Component({
  components: {
    ModalAddCaracteristique,
    SettingsCaracteristiqueModalError
  }
})
export default class SettingsCaracteristique extends Vue {
  caracteristique: Caracteristique[] = [];
  caracteristiqueData: Caracteristique[] = [];
  categorieType: CategorieType[] = [];
  selected: Caracteristique | null = null;
  categorieSelected: number | null = null;
  errorProduit = [];

  mounted() {
    this.load();
  }

  load() {
    // @ts-ignore
    this.$log.debug('Load caracteristique for settings');
    ProduitService.loadCaracteristique().then((response) => {
      this.caracteristique = response;
      this.caracteristiqueData = Object.assign([], response);
      this.filter(this.categorieSelected);
    });
    // @ts-ignore
    this.$log.debug('Load categorieType for settings');
    ProduitService.loadCategorieType().then((response) => {
      this.categorieType = response;
    });
  }

  openModalCreateMode() {
    // @ts-ignore
    this.$log.debug('Open modal for create caracteristique');
    this.selected = null;
    // @ts-ignore
    this.$refs.modalAddCaracteristique.openModal();
  }

  openModalEditMode(item: Caracteristique) {
    // @ts-ignore
    this.$log.debug('Open modal for edit caracteristique', item);
    this.selected = item;
    // @ts-ignore
    this.$refs.modalAddCaracteristique.openModal();
  }

  deleteProduitCaracteristique(item: Caracteristique) {
    // @ts-ignore
    this.$log.debug('request delete caracteristique', item);
    ProduitService.caracteristiqueDelete(item.id)
      .then((response) => {
        if (response.status === 202) {
          this.load();
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // @ts-ignore
          this.$log.warn(error.response.data);
          this.errorProduit = error.response.data;
          // @ts-ignore
          this.$refs.modalError.openModal();
        } else {
          // @ts-ignore
          this.$log.error(error);
          throw error;
        }
      });
  }

  toggle(item: Caracteristique, ctype: CategorieType) {
    ProduitService.toggleProduitCaracteristique(item.id, ctype.id).then(() => {
      const hasType = item.categorieTypes.find((c) => c.id === ctype.id);
      if (hasType === undefined) {
        item.categorieTypes.push(ctype);
      } else {
        item.categorieTypes = item.categorieTypes.filter((c) => c.id !== ctype.id);
      }
    });
  }

  filter(categorieId: number | null) {
    if (categorieId === null) {
      this.caracteristique = this.caracteristiqueData;
    } else {
      this.caracteristique = this.caracteristiqueData.filter(
        (x) => x.categorieTypes.filter((y) => y.id === categorieId).length > 0
      );
    }
  }

  @Watch('categorieSelected')
  onCategorieSelectedChange(n: number | null) {
    this.filter(n);
  }
}
