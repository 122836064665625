




































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { DiagnosticComment } from '@/models/diagnostic_comment';
import { Section } from '@/models/section';
import CommentsService from '@/services/comments';
import { Conseiller } from '@/models/conseiller';
import ConseillerService from '@/services/conseiller';

@Component({
  components: {}
})
export default class SidebarComments extends Vue {
  @Prop({ required: true }) diagnosticId!: number;
  @Prop({ required: true }) sections!: Section[];
  @Prop({ required: false }) focusedSection?: Section;

  me: Conseiller | null = null;
  currentSection: Section | null = null;
  diagnosticComments: DiagnosticComment[] = [];
  newCommentContent = '';
  newCommentSectionId: number | null = null;
  newCommentParentId: number | null = null;

  editingCommentId: number | null = null;

  mounted() {
    ConseillerService.me().then((myself) => (this.me = myself));
    this.currentSection = this.focusedSection || this.sections[0] || null;
    this.loadComments();
  }

  loadComments() {
    CommentsService.comments(this.diagnosticId).then((response) => (this.diagnosticComments = response.data));
  }

  @Watch('focusedSection')
  updateSection() {
    if (this.focusedSection) this.currentSection = this.focusedSection;
  }

  @Watch('currentSection')
  scrollToComment() {
    if (!this.currentSection || !this.$el.querySelector) return;
    const commentNode = this.$el.querySelector(`[data-section-id="${this.currentSection.id}"]`);
    if (commentNode !== null) commentNode.scrollIntoView(true);
  }

  commentsOfSection(section: Section) {
    return this.diagnosticComments
      .filter((comment) => comment.section.id === section.id)
      .sort((c1, c2) => {
        if (c2.parent !== undefined && (c2.parent.id as number) >= (c1.id as number)) return -1;
        else if (c1.parent !== undefined && (c1.parent.id as number) <= (c2.id as number)) return 1;
        else return (c1.id as number) - (c2.id as number);
      });
  }

  get currentComment() {
    if (this.focusedSection) {
      return this.diagnosticComments.find((comm) => this.focusedSection && comm.section.id === this.focusedSection.id);
    } else {
      return undefined;
    }
  }

  addComment() {
    if (this.newCommentSectionId === null) {
      console.warn('No section selected !');
      return;
    }
    const sectionId: number = this.newCommentSectionId;

    CommentsService.addComment(
      this.newCommentContent,
      this.diagnosticId,
      sectionId,
      this.newCommentParentId || undefined
    ).then(() => {
      this.newCommentSectionId = null;
      this.newCommentParentId = null;
      this.loadComments();
    });
  }

  editComment(comment: DiagnosticComment) {
    CommentsService.editComment(
      comment.content as string,
      this.diagnosticId,
      comment.section.id as number,
      comment.id as number
    ).then(() => {
      this.newCommentSectionId = null;
      this.newCommentParentId = null;
      this.editingCommentId = null;
      this.loadComments();
    });
  }

  commentAuthorName(commentId: number) {
    const author = this.diagnosticComments.find((c) => c.id === commentId)?.author;
    if (author) return `${author.firstname} ${author.lastname}`;
    else return null;
  }

  toggleNewCommentState(section: Section) {
    if (this.newCommentSectionId !== section.id) {
      this.newCommentSectionId = section.id as number;
      this.newCommentParentId = null;
    } else {
      this.newCommentSectionId = null;
    }
  }

  toggleReplyState(comment: DiagnosticComment) {
    if (this.newCommentParentId !== comment.id) {
      this.newCommentParentId = comment.id as number;
      this.newCommentSectionId = comment.section.id as number;
    } else {
      this.newCommentParentId = null;
      this.newCommentSectionId = null;
    }
  }

  toggleEditState(comment: DiagnosticComment) {
    if (this.editingCommentId !== comment.id) this.editingCommentId = comment.id as number;
    else {
      this.editingCommentId = null;
      this.loadComments();
    }
  }

  archiveComment(comment: DiagnosticComment) {
    CommentsService.archiveComment(this.diagnosticId, comment.section.id as number, comment.id as number).then(() => {
      this.loadComments();
    });
  }
}
