





































export default {
  name: 'Settings'
};
