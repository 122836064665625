































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Diagnostic } from '@/models/diagnostic';
import { Section } from '@/models/section';
import { BModal } from 'bootstrap-vue';
import RapportLivrable from '../livrable/RapportLivrable.vue';
import { DiagnosticConstat } from '@/models/diagnostic_constat';
import { DiagnosticAnnex } from '@/models/diagnostic_annex';

@Component({
  components: {
    RapportLivrable
  }
})
export default class ModalDiagnosticCQPrint extends Vue {
  @Prop({ required: true }) accountId!: number;
  @Prop({ required: true }) diagnostic!: Diagnostic;
  @Prop({ required: true }) contents!: Section[];
  @Prop({ required: true }) constats!: DiagnosticConstat[];
  @Prop({ required: true }) signatures!: string[];
  @Prop({ required: true }) diagnosticAnnexes!: DiagnosticAnnex[];

  pageOverflowStages = [];
  printing = false;

  show() {
    (this.$refs.modalCQ as BModal).show();
    this.pageOverflowStages = [];
  }

  get pageOverflowsLinks() {
    return this.pageOverflowStages.map((stageName) => `<a href="#livrable-${stageName}">${stageName}</a>`).join(', ');
  }

  makePDF() {
    console.log('makePDF');
    this.printing = true;
    this.$nextTick(() => this.renderPDF());
  }

  renderPDF() {
    const contentElement = document.querySelector('#diagnosticCQPrint .livrable.printing');
    if (contentElement === null) {
      this.$nextTick(() => this.renderPDF());
    } else {
      /*
      const opt = {
        jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' }
      };
      html2pdf()
        .set(opt)
        .from(contentElement)
        .toContainer()
        .toCanvas()
        .toPdf()
        .save()
        .then(() => {
          this.printing = false;
        });
      */
      const domClone = contentElement.cloneNode(true);
      let $printSection = document.getElementById('printSection');
      if ($printSection === null) {
        $printSection = document.createElement('div');
        $printSection.id = 'printSection';
        document.body.appendChild($printSection);
      }
      $printSection.innerHTML = '';
      $printSection.appendChild(domClone);
      window.print();
      this.printing = false;
    }
  }
}
