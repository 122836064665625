import axios from './axios';
import { Compagnie } from '@/models/compagnie';

class OrganisationService {
  async organisationList(): Promise<Compagnie[]> {
    return axios.get('/organisation/').then((response) => {
      return response.data as Compagnie[];
    });
  }

  organisationCreate(nom: string, address: string, email: string, telephone: string, site: string) {
    return axios.post('/organisation/add', {
      nom: nom,
      adresse: address,
      email: email,
      telephone: telephone,
      site: site
    });
  }
}

export default new OrganisationService();
