



















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
@Component
export default class NumberInput extends Vue {
  @Prop({ required: true }) value!: number;
  @Prop({ required: true }) placeholder!: string;
  @Prop({ type: Boolean, default: false, required: false }) noClose!: boolean;

  model = this.value;
  confirm = false;

  @Watch('value')
  onValue(n: number) {
    this.model = n;
  }

  @Watch('model')
  onModel() {
    this.$emit('input', this.model);
  }
}
