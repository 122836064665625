



























import { Component, Vue, Prop } from 'vue-property-decorator';
import { Produit } from '@/models/produit';
@Component
export default class SettingsCategorieModalError extends Vue {
  @Prop({ required: true }) produit!: Produit;

  showDismissibleAlert = false;

  openModal() {
    // @ts-ignore
    this.$refs.modal.show();
  }
}
