




















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
import Cropper from 'cropperjs';
import FileUpload from 'vue-upload-component';
@Component({
  components: {
    FileUpload
  }
})
export default class UploaderWithCropper extends Vue {
  @Prop({ type: String, default: '' }) value!: string;
  @Prop({ type: String }) uploadUrl!: string;
  @Prop({ type: Number, default: NaN }) aspectRatio!: number;

  previousSignatureUrl: string = this.value;
  signatureUrl: string = this.value;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  files: any[] = [];
  edit = false;
  cropper: Cropper | null = null;

  get progress() {
    return this.files.length === 0 ? 100 : parseInt(this.files[0].progress);
  }

  get token() {
    return `Bearer ${UserModule.jwtToken}`;
  }

  get signatureChanged() {
    return this.signatureUrl !== this.previousSignatureUrl;
  }

  mounted() {
    // @ts-ignore
    this.$watch(
      // @ts-ignore
      () => this.$refs.upload.uploaded,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (_) => {
        // @ts-ignore
        if (this.$refs.upload && this.$refs.upload.uploaded) {
          this.$emit('uploaded');
        }
      }
    );
    // @ts-ignore
    this.$watch(
      // @ts-ignore
      () => this.$refs.upload.uploading,
      (val) => {
        if (val !== 0) {
          this.$emit('uploading');
        }
      }
    );
  }

  cancel() {
    if (this.files.length > 0) {
      // @ts-ignore
      this.$refs.upload.files[0].active = false;
    }
  }

  get signatureSrc() {
    return this.files.length ? this.files[0].url : this.signatureUrl;
  }

  editSave() {
    this.edit = false;
    const oldFile = this.files[0];
    const binStr = atob(
      this.cropper
        ?.getCroppedCanvas()
        .toDataURL(oldFile.type)
        .split(',')[1] || ''
    );
    const arr = new Uint8Array(binStr.length);
    for (let i = 0; i < binStr.length; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    let file = null;
    try {
      file = new File([arr], oldFile.name, { type: oldFile.type });
    } catch (err) {
      file = new Blob([arr], { type: oldFile.type });
    }
    // @ts-ignore
    this.$refs.upload.update(oldFile.id, {
      file,
      type: file.type,
      size: file.size,
      active: true
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputFile(newFile: any, oldFile: any) {
    if (newFile && !oldFile) {
      this.$nextTick(function() {
        this.edit = true;
      });
    }
    if (!newFile && oldFile) {
      this.edit = false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputFilter(newFile: any, oldFile: any, prevent: any) {
    if (newFile && !oldFile) {
      if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
        alert('Your choice is not a picture');
        return prevent();
      }
    }
    if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
      newFile.url = '';
      const URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        newFile.url = URL.createObjectURL(newFile.file);
      }
    }
  }

  @Watch('value')
  onValueChange(n: string) {
    this.signatureUrl = n;
  }

  @Watch('signatureUrl')
  onSignatureUrlChange(n: number) {
    this.$emit('input', n);
  }

  @Watch('edit')
  onEditChange(value: boolean) {
    if (value) {
      this.$nextTick(function() {
        if (!this.$refs.editImage) {
          return;
        }
        // @ts-ignore
        this.cropper = new Cropper(this.$refs.editImage, {
          aspectRatio: this.aspectRatio,
          viewMode: 1
        });
      });
    } else {
      if (this.cropper) {
        this.cropper.destroy();
        this.cropper = null;
      }
    }
  }
}
