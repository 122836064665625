

























import { Prop, Vue } from 'vue-property-decorator';
import TemplateService from '../../services/template';
import { Template } from '../../models/template';
import { Section } from '../../models/section';
import { Placeholder } from '../../models/placeholder';
import TemplateForm from '@/components/template/TemplateForm.vue';
import { templateCategoryTree } from '../elements/TemplateCategoryTree';
import { Component } from 'vue-mixin-decorator';

@Component({
  components: { TemplateForm }
})
export default class TemplateOne extends Vue {
  @Prop({ required: true }) templateId!: number;

  template: Template | null = null;
  templateContents: Section[] = [];
  formSubmission = false;
  errors: string[] = [];
  loading = true;

  autoSubmitMethod = this.handleUpdate;
  timeoutMilliseconds = 10000;

  mounted() {
    TemplateService.templateById(this.templateId)
      .then((response) => {
        this.template = response.data;
      })
      .then(() => {
        TemplateService.templateContentsByTemplateId(this.templateId).then((response) => {
          this.templateContents = response.data;
          this.loading = false;

          this.templateContents.forEach((c, index) => {
            if (index < this.templateLayout.length) {
              c.placeholder = this.templateLayout[index];
            } else if (c.placeholder === null) {
              c.placeholder = Placeholder.BLOC_TEXTE;
            }
            if (c.displayOrder === undefined || c.displayOrder === null) c.displayOrder = index;
          });
          this.templateContents = this.templateContents.sort((c1, c2) => c1.displayOrder - c2.displayOrder);

          // this.registerAutoSubmitTimer(new Date());
        });
      });
  }

  beforeDestroy() {
    // this.clearAutoSubmitTimer();
  }

  get templateLayout(): Placeholder[] {
    if (!this.template) return [];

    const categoryNode = templateCategoryTree.find((node) => this.template && node.code === this.template.category);
    if (!categoryNode || !categoryNode.subcategories) return [];

    const subCategoryNode = categoryNode.subcategories.find(
      (node) => this.template && node.code === this.template.subcategory
    );
    if (!subCategoryNode) return [];

    return subCategoryNode.layout || [];
  }

  handleUpdate(): Promise<void> {
    if (!this.template) return Promise.reject(new Error('Invalid state'));
    this.errors = [];
    this.formSubmission = true;

    this.formSubmission = true;
    const updatePromise = TemplateService.templateUpdate(this.templateId, this.template as Template);

    let timeoutHandle: number;
    const timeoutPromise = new Promise((resolve, reject) => {
      timeoutHandle = window.setTimeout(() => reject(Error('Timeout')), this.timeoutMilliseconds);
    });

    return Promise.race([updatePromise, timeoutPromise])
      .then((template) => {
        if (timeoutHandle !== undefined) clearTimeout(timeoutHandle);
        this.template = template as Template;

        TemplateService.templateContentsUpdate(this.templateId, this.templateContents)
          .then((contents: Section[]) => {
            this.templateContents = contents;
            this.formSubmission = false;
            // this.lastSubmit = new Date();
          })
          .catch((rejection) => {
            console.warn({ rejection });
            this.formSubmission = false;
          });
      })
      .catch((thrown: { message: string }) => {
        this.formSubmission = false;
        console.warn(thrown.message);
      });
  }

  handleCancel() {
    this.$router.push({ name: 'TemplateList' });
  }

  handleDelete() {
    this.formSubmission = true;
    TemplateService.templateDelete(this.templateId).then(() => {
      this.formSubmission = false;
      this.$router.push('/template');
    });
  }

  handleDuplicate() {
    this.formSubmission = true;
    const templateDup: Template = { ...this.template } as Template;
    templateDup.id = 0;
    templateDup.name = `Copie de ${templateDup.name}`;
    const contentsDup: Section[] = this.templateContents.map((c) => {
      return { ...c };
    });
    contentsDup.forEach((c) => {
      c.id = 0;
    });

    TemplateService.templateCreate(templateDup)
      .then((newTemplate: Template) => {
        this.template = newTemplate;
        TemplateService.templateContentsUpdate(newTemplate.id, contentsDup).then((contents: Section[]) => {
          this.templateContents = contents;

          this.formSubmission = false;
          this.$router.push({
            name: 'TemplateOne',
            params: { templateId: `${newTemplate.id}` }
          });
        });
      })
      .catch((rejection) => {
        this.formSubmission = false;
        if (rejection.response.status === 409) {
          this.errors.push('alreadyDuplicated');
        }
      });
  }

  removeTemplateContent(contentToRemove: Section) {
    let displayOrderToRemove = contentToRemove.displayOrder;
    this.templateContents = this.templateContents
      .filter((c) => c.displayOrder !== contentToRemove.displayOrder)
      .map((c) => {
        // Shift down contents with higher order
        if (c.displayOrder === displayOrderToRemove + 1) {
          c.displayOrder = displayOrderToRemove;
          displayOrderToRemove = displayOrderToRemove + 1;
        }
        return c;
      });
  }

  reorderTemplateContents(contents: Section[]) {
    this.templateContents = contents;
    this.templateContents.forEach((c, index) => (c.displayOrder = index));
  }
}
