import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

const isProduction = process.env.NODE_ENV === 'production';
if (isProduction) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DNS,
    integrations: [new Integrations.BrowserTracing()],
    release: 'gconsult@' + process.env.VUE_APP_VERSION,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.0
  });
}
