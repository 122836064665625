var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dictionary-sidebar"},[_c('div',{staticClass:"definitions-search my-2 px-2"},[_c('b-input-group',[_c('b-form-input',{staticClass:"search bg-transparent rounded-0 font-weight-light text-white border-white-light",attrs:{"type":"text","placeholder":"Rechercher une définition..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('b-input-group-append',[(_vm.search === '')?_c('i',{staticClass:"icon-search px-3 py-1"}):_vm._e(),(_vm.search !== '')?_c('b-button',{staticClass:"px-3 py-1",attrs:{"variant":"light"},on:{"click":function($event){_vm.search = ''}}},[_c('i',{staticClass:"fa fa-backspace"})]):_vm._e()],1)],1)],1),(_vm.showSpinner)?_c('div',{staticClass:"custom_position"},[_c('b-spinner',{staticStyle:{"width":"10vh","height":"10vh"},attrs:{"variant":"light","label":"Chargement du dictionnaire"}})],1):_c('ul',{staticClass:"dictionary px-2"},[_vm._l((_vm.sortedTraits),function(trait){return [(_vm.traitMatchesSearch(trait))?[(trait.onContact)?_vm._l(([1, 2]),function(contactIndex){return _c('li',{key:("dictionary-" + (trait.id) + "-" + contactIndex)},[_c('div',{staticClass:"dictionary-entry text-left pl-0"},[_c('span',{class:{
                  trait: true,
                  usable: _vm.activeDefinition(trait, contactIndex) || !_vm.bindingState
                },attrs:{"draggable":_vm.activeDefinition(trait, contactIndex) || !_vm.bindingState}},[_c('span',{staticClass:"at"},[_vm._v("@")]),_vm._v(_vm._s(trait.name)+_vm._s(contactIndex)+" ")]),(_vm.bindingState && _vm.activeDefinition(trait, contactIndex))?[_c('br'),_vm._v(" ↳ "),_c('span',{staticClass:"definition-preview mx-1",domProps:{"textContent":_vm._s(_vm.previewDefinitionValue(_vm.activeDefinition(trait, contactIndex)))}})]:_vm._e()],2)])}):_c('li',{key:'dictionary-' + trait.id},[_c('div',{staticClass:"dictionary-entry text-left pl-0"},[_c('span',{class:{
                trait: true,
                usable: _vm.activeDefinition(trait) || !_vm.bindingState
              },attrs:{"draggable":_vm.activeDefinition(trait) || !_vm.bindingState}},[_c('span',{staticClass:"at"},[_vm._v("@")]),_vm._v(_vm._s(trait.name)+" ")]),(
                _vm.bindingState &&
                  !_vm.expandedDefinitions[trait.id] &&
                  _vm.dictionary[trait.id] &&
                  _vm.dictionary[trait.id].length > 0
              )?[_c('br'),_vm._v(" ↳ "),(_vm.activeDefinition(trait))?_c('span',{staticClass:"definition-preview mx-1",domProps:{"textContent":_vm._s(_vm.previewDefinitionValue(_vm.activeDefinition(trait)))}}):_c('span',{staticClass:"definition-preview text-muted mx-1"},[_vm._v("?")])]:_vm._e()],2)])]:_vm._e()]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }