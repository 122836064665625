

























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { CategorieProduit } from '@/models/categorieproduit';
import { CategorieType } from '@/models/categorietype';
import ProduitService from '@/services/produit';

@Component
export default class SettingsCategorieProduitModalAddEdit extends Vue {
  @Prop({
    type: Object as () => CategorieProduit | undefined,
    default: undefined
  })
  readonly item: CategorieProduit | undefined;

  nom = '';
  categorie: number | null = null;
  category: CategorieType[] = [];
  showDismissibleAlert = false;
  dismissSecs = 5;
  dismissCountDown = 0;

  get title() {
    return this.item === undefined ? 'Ajouter une sous categorie de produit' : 'Modifier une sous categorie de produit';
  }

  get options() {
    const data: { value: number | null; text: string }[] = Array.of({
      value: null,
      text: '-- Choisissez un type --'
    });
    return data.concat(
      this.category.map((item) => {
        return { value: item.id, text: item.nom };
      })
    );
  }

  openModal() {
    // @ts-ignore
    this.$refs.modal.show();
    this.loadCategory();
  }

  close() {
    this.dismissCountDown = 0;
    // @ts-ignore
    this.$refs.modal.hide();
  }

  fillForm() {
    if (this.item !== undefined) {
      this.nom = this.item.nom;
      this.categorie = this.item.categorieType.id;
    }
  }

  clearForm() {
    this.nom = '';
    this.showDismissibleAlert = false;
  }

  handleOk(evt: Event) {
    // Prevent modal from closing
    evt.preventDefault();
    this.handleSubmit();
  }

  handleSubmit() {
    if (this.nom === '' || this.categorie === null) {
      this.showDismissibleAlert = true;
    } else {
      this.showDismissibleAlert = false;
      if (this.item) {
        // @ts-ignore
        this.$log.debug('Edit category with id:', this.item.id);
      } else {
        // @ts-ignore
        this.$log.debug('Create new category');
      }
      // @ts-ignore
      this.$log.debug('Category data', this.nom, this.categorie);
      ProduitService.createCategorie(this.item ? this.item.id : undefined, this.nom, this.categorie).then(
        (response) => {
          if (response.status === 202) {
            this.dismissCountDown = this.dismissSecs;
            this.$emit('sous-categorie-created');
          }
        }
      );
    }
  }

  countDownChanged(dismissCountDown: number) {
    this.dismissCountDown = dismissCountDown;
  }

  loadCategory() {
    ProduitService.loadCategorieType().then((response) => {
      this.category = response;
    });
  }

  @Watch('item')
  onItemChange(n?: CategorieProduit) {
    if (n === undefined) {
      this.clearForm();
    } else {
      this.fillForm();
    }
  }
}
