































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import AcmService from '@/services/acm';
import ProduitService from '@/services/produit';
import ArrayUtils from '@/utils/ArrayUtils';
// @ts-ignore
import draggable from 'vuedraggable';
import Breadcrumb from './Breadcrumb.vue';
import ModalViewProduct from '@/components/produit/ModalViewProduct.vue';
import { Acm } from '../../models/acm';
import { Caracteristique } from '../../models/caracteristique';
import { Produit } from '../../models';
import { ProduitHasCaracteristique } from '../../models/produithascaracteristique';

@Component({
  components: {
    draggable,
    Breadcrumb,
    ModalViewProduct
  }
})
export default class AcmCreateCompareProduct extends Vue {
  @Prop({ required: true }) acmId!: number;

  produits: Produit[] = [];
  caracteristique: Caracteristique[] = [];
  caracteristiqueDeleted: Caracteristique[] = [];
  produitCaracteristique: {
    produitId: number;
    content: ProduitHasCaracteristique[];
  }[] = [];

  selectedProduct: Produit | null = null;
  selection: number[] = [];
  showAlert = false;
  acm: Acm | null = null;

  created() {
    AcmService.acmById(this.acmId)
      .then((response) => {
        this.acm = response;
        // @ts-ignore
        const produit = this.$localStorage.get('acm.product');
        if (produit === null || JSON.parse(produit).length === 0) {
          this.$router.push({
            name: 'AcmCreateSelectProduct',
            params: { acmId: `${this.acmId}` }
          });
        } else {
          this.produits = JSON.parse(produit);
          this.caracteristique = [];
          const promises = this.produits.map((p) => {
            return ProduitService.produitCaracteristique(p.id).then((response) => {
              const obj: {
                produitId: number;
                content: ProduitHasCaracteristique[];
              } = {
                produitId: p.id,
                content: response
              };
              this.produitCaracteristique.push(obj);
              return obj;
            });
          });
          Promise.all(promises).then((values) => {
            this.caracteristique = ArrayUtils.unique(
              ArrayUtils.flatMap(values, (item) => {
                return item.content.map((c) => {
                  return c.caracteristique;
                });
              })
            );
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.$router.push({ name: 'AcmList' });
        }
      });
  }

  mounted() {
    // @ts-ignore
    this.selection.push(...this.$refs.panier.panier.map((p) => p.produit.id));
  }

  caracteristiqueForProduit(produit: Produit, carac: Caracteristique) {
    const productCarac = this.produitCaracteristique.find((pc) => pc.produitId === produit.id);
    if (productCarac === undefined) {
      return { value: '' };
    } else {
      const found = productCarac.content.find((c) => {
        return c.caracteristique.id === carac.id;
      });
      if (found === undefined) {
        return { value: '' };
      } else {
        return found;
      }
    }
  }

  addCaracteristique(carac: Caracteristique) {
    this.caracteristique.push(carac);
    this.caracteristiqueDeleted = this.caracteristiqueDeleted.filter((c) => c.id !== carac.id);
  }

  deleteCarac(carac: Caracteristique) {
    this.caracteristiqueDeleted.push(carac);
    this.caracteristique = this.caracteristique.filter((c) => c.id !== carac.id);
  }

  reduceCarac(index: number) {
    this.caracteristique = this.caracteristique.map((v, i) => {
      if (i === index) {
        // @ts-ignore
        v.hidden = !v.hidden;
      }
      return v;
    });
  }

  ajouterAuPanier() {
    const produitCaracteristique = this.produitCaracteristique
      .filter((p) => this.selection.includes(p.produitId))
      .map((pc) => {
        return {
          ...pc,
          produit: this.produits.find((p) => p.id === pc.produitId)
        };
      });
    const caracId = this.caracteristique.map((c) => c.id);
    produitCaracteristique.map((pc) => {
      pc.content = pc.content.filter((c) => {
        return caracId.includes(c.caracteristique.id);
      });
      return pc;
    });
    // @ts-ignore
    const panier = this.$refs.panier.panier.slice(0);
    panier.push(...produitCaracteristique);
    // @ts-ignore
    this.$refs.panier.panier = ArrayUtils.unique(panier);
    this.showAlert = true;
    this.selection = [];
  }

  voirProduit(item: Produit) {
    this.selectedProduct = item;
    this.$nextTick(() => {
      // @ts-ignore
      this.$refs.modalViewProduct.open();
    });
  }
}
