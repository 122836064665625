






















import { Component, Vue, Prop } from 'vue-property-decorator';
import AcmService from '@/services/acm';
import { AcmPreconisation } from '../../models/acmPreconisation';
@Component
export default class AcmPrecoModalError extends Vue {
  @Prop({ required: true }) acmId!: number;
  @Prop({ required: true }) acmPreco!: AcmPreconisation;

  showDismissibleAlert = false;
  openModal() {
    // @ts-ignore
    this.$refs.modal.show();
  }

  confirmDeleteAcmPreco() {
    AcmService.acmPrecoDelete(this.acmId, this.acmPreco.id).then(() => {
      // @ts-ignore
      this.$refs.modal.hide();
    });
  }

  noDeleteAcmPreco(acmPreco: AcmPreconisation) {
    this.$emit('no-delete-acmpreco', acmPreco);
  }
}
