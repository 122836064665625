import { render, staticRenderFns } from "./ProduitCreateContent.vue?vue&type=template&id=930db5c8&scoped=true&"
import script from "./ProduitCreateContent.vue?vue&type=script&lang=ts&"
export * from "./ProduitCreateContent.vue?vue&type=script&lang=ts&"
import style0 from "./ProduitCreateContent.vue?vue&type=style&index=0&id=930db5c8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "930db5c8",
  null
  
)

export default component.exports