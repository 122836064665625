







































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { User } from '../models';
@Component
export default class Header extends Vue {
  @Prop({ type: Object as () => User }) user!: User;

  scrollPosition = 0;
  accountSearch = '';

  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScroll);
  }

  updateScroll() {
    this.scrollPosition = window.scrollY;
  }

  searchAccounts(event: Event) {
    event.preventDefault();

    if (this.accountSearch.length > 0)
      this.$router.push({
        name: 'AccountList',
        query: { search: this.accountSearch }
      });
  }

  get salesForceUrl() {
    return `${process.env.VUE_APP_SALESFORCE_DOMAIN}/lightning/page/home`;
  }
}
