import { PanierModule } from '@/store/modules/panier';
import { Produit } from '@/models';

export default {
  saveAll(selections: Produit[]) {
    PanierModule.saveAll(selections);
  },
  isEmpty() {
    return PanierModule.isEmpty;
  },
  clear() {
    PanierModule.clear();
  },
  all() {
    return PanierModule.produit;
  },
  add(produit: Produit) {
    PanierModule.add(produit);
  },
  removeById(produitId: number) {
    PanierModule.removeById(produitId);
  },
  remove(produit: Produit) {
    PanierModule.remove(produit);
  }
};
