import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import store from '@/store';

export interface IdsHistoryState {
  storedIds: number[];
}

@Module({ dynamic: true, store, name: 'ids_history', namespaced: true })
class IdsHistory extends VuexModule implements IdsHistoryState {
  public storedIds: number[] = [];

  @Mutation
  private _reset(ids?: number[]) {
    this.storedIds = ids || [];
  }

  @Mutation
  private _add(id: number) {
    this.storedIds = this.storedIds.filter((storedId) => storedId !== id);
    this.storedIds.unshift(id);
  }

  @Mutation
  private _limit(size: number) {
    if (this.storedIds.length > size) this.storedIds.splice(size);
  }

  @Mutation
  private _remove(id: number) {
    this.storedIds = this.storedIds.filter((storedId) => storedId !== id);
  }

  @Action
  public clear() {
    this._reset();
  }

  @Action
  public saveAll(ids: number[]) {
    this._reset(ids);
  }

  public get isEmpty() {
    return this.storedIds.length === 0;
  }

  @Action
  public add(id: number) {
    this._add(id);
    this._limit(5);
  }

  @Action
  public removeById(id: number) {
    this._remove(id);
  }

  @Action
  public remove(id: number) {
    this._remove(id);
  }
}

export const IdsHistoryModule = getModule(IdsHistory);
