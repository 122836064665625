


























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import AcmService from '@/services/acm';
import StateList from './AcmStateList';
import VueXeditable from '@/components/elements/xeditable/XEditable.vue';

@Component({
  components: {
    VueXeditable
  }
})
export default class AcmState extends Vue {
  @Prop({ required: true }) value!: string;
  @Prop({ required: true }) acmId!: number;
  @Prop({ type: Boolean, default: false, required: true }) editable!: boolean;

  model = this.value;
  isEdit = false;
  allState = StateList.allState;

  get color() {
    return this.stateForCode(this.model)?.color;
  }

  styleClass() {
    return ['text-' + this.color, this.editable ? 'editable' : ''];
  }

  stateForCode(code: string) {
    return StateList.allState.find((x) => x.code === code);
  }

  edit() {
    if (this.editable) {
      this.isEdit = true;
      this.$nextTick().then(() => {
        // @ts-ignore
        this.$refs.editable.$el.getElementsByClassName('vue-xeditable-value')[0].click();
      });
    }
  }

  endEdit(value: string) {
    const code = StateList.allState.find((x) => x.text === value)?.code;
    if (code !== undefined) {
      this.$emit('input', code);
      this.isEdit = false;
      AcmService.acmEditState(this.acmId, code);
    }
  }

  @Watch('value')
  onvalue() {
    this.model = this.value;
  }

  @Watch('model')
  onmodel() {
    this.$emit('input', this.model);
  }
}
