





































import { Component, Vue, Prop } from 'vue-property-decorator';
import { DiagnosticStage, DiagnosticStageName } from '@/models/diagnostic_stage';
import { DiagnosticConstat } from '@/models/diagnostic_constat';
import { Section } from '@/models/section';
import { BModal } from 'bootstrap-vue';
import AvantPropos from '../livrable/AvantPropos.vue';
import Bilan from '../livrable/Bilan.vue';
import Objectifs from '../livrable/Objectifs.vue';
import Body from '../livrable/Body.vue';
import { Definition } from '@/models/dictionary';
import DictionaryService from '@/services/dictionary';
import { DiagnosticAnnex } from '@/models/diagnostic_annex';

@Component({
  components: {
    AvantPropos,
    Bilan,
    Objectifs,
    Body
  }
})
export default class ModalDiagnosticStagePreview extends Vue {
  @Prop({ required: true }) accountId!: number;
  @Prop({ required: true }) contents!: Section[];
  @Prop({ required: true }) stage!: DiagnosticStage;
  @Prop({ required: true }) constat?: DiagnosticConstat;
  @Prop({ required: true }) constatIndex?: number;
  @Prop({ required: true }) signatures!: string[];
  @Prop({ required: true }) bilanAnnexes!: DiagnosticAnnex[];
  definitions: Definition[] = [];
  stageNames = DiagnosticStageName;

  livrablePages = 1;

  mounted() {
    DictionaryService.definitions(this.accountId).then((response) => {
      this.definitions = response.data;
    });

    (this.$refs.modalPreview as BModal).show();
  }
}
