
































import { Component, Vue } from 'vue-property-decorator';
@Component
export default class ModalConfirmDelete extends Vue {
  deleteSelection() {
    // @ts-ignore
    this.$emit('eventDeleteSelection');
    this.hideModal();
  }

  showModal() {
    // @ts-ignore
    this.$refs.modalDelete.show();
  }

  hideModal() {
    // @ts-ignore
    this.$refs.modalDelete.hide();
  }
}
