import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import { resetRouter } from '@/router';
import store from '@/store';
import { User as UserModel } from '@/models/index';
import conseiller from '@/services/conseiller';

export interface UserState {
  user: UserModel | null;
  jwtToken: string;
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements UserState {
  public user: UserModel | null = null;
  public jwtToken = '';

  @Mutation
  public setToken(token: string) {
    this.jwtToken = token;
  }

  @Mutation
  public setUser(user: UserModel) {
    this.user = user;
  }

  @Mutation
  public resetToken() {
    this.jwtToken = '';
    this.user = null;
  }

  @Action
  public async login(token: string) {
    this.setToken(token);
    const me = await conseiller.me();
    this.setUser(new UserModel(me));
  }

  @Action
  public logout() {
    resetRouter();
    this.resetToken();
  }
}
export const UserModule = getModule(User);
