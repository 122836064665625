import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import store from '@/store';
import { Produit } from '@/models/index';

export interface PanierState {
  produit: Produit[];
}

@Module({ dynamic: true, store, name: 'panier', namespaced: true })
class Panier extends VuexModule implements PanierState {
  public produit: Produit[] = [];

  @Mutation
  private _reset(produits?: Produit[]) {
    this.produit = produits || [];
  }

  @Mutation
  private _add(produit: Produit) {
    this.produit.push(produit);
  }

  @Mutation
  private _remove(produitId: number) {
    this.produit = this.produit.filter((produit) => produit.id !== produitId);
  }

  @Action
  public clear() {
    this._reset();
  }

  @Action
  public saveAll(produits: Produit[]) {
    this._reset(produits);
  }

  public get isEmpty() {
    return this.produit.length === 0;
  }

  @Action
  public add(produit: Produit) {
    this._add(produit);
  }

  @Action
  public removeById(produitId: number) {
    this._remove(produitId);
  }

  @Action
  public remove(produit: Produit) {
    this._remove(produit.id);
  }
}

export const PanierModule = getModule(Panier);
