

















































import { Component, Vue, Prop } from 'vue-property-decorator';
import ArrayUtils from '@/utils/ArrayUtils';
// @ts-ignore
import VueScrollingTable from 'vue-scrolling-table';
import { Produit } from '@/models/produit';

interface GroupedCaracteristique {
  id: number;
  nom: string;
  produit?: {
    produitId: number;
    value?: string;
  }[];
}

@Component({
  components: {
    VueScrollingTable
  }
})
export default class ModalComparator extends Vue {
  @Prop({ type: Array as () => Produit[] }) selection!: Produit[];

  mounted() {
    this.open();
  }

  get groupedCaracteristique(): GroupedCaracteristique[] {
    const flatten: GroupedCaracteristique[] = ArrayUtils.flatMap(this.selection, (produit: Produit) => {
      return produit.produitHasCaracteristiques.map((produitHasCaracteristique) => {
        return {
          nom: produitHasCaracteristique.caracteristique.nom,
          id: produitHasCaracteristique.caracteristique.id
        } as GroupedCaracteristique;
      });
    });
    const unique: GroupedCaracteristique[] = ArrayUtils.unique(flatten);
    return unique.map((carateristique: GroupedCaracteristique) => {
      carateristique.produit = this.selection.map((produit) => {
        const data = produit.produitHasCaracteristiques.find((produitHasCaracteristique) => {
          return produitHasCaracteristique.caracteristique.id === carateristique.id;
        });
        return {
          produitId: produit.id,
          value: data !== undefined ? data.value : undefined
        };
      });
      return carateristique as GroupedCaracteristique;
    });
  }

  open() {
    // @ts-ignore
    this.$refs.modalInfo.show();
  }

  close() {
    // @ts-ignore
    this.$refs.modalInfo.hide();
  }
}
