



















import { Component, Vue, Prop } from 'vue-property-decorator';
import { Section } from '@/models/section';
import { Placeholder } from '@/models/placeholder';
import Body from './Body.vue';
import { Definition } from '@/models/dictionary';

@Component({
  components: {
    Body
  }
})
export default class Objectifs extends Vue {
  @Prop({ required: true }) contents!: Section[];
  @Prop({ required: false }) definitions?: Definition[];

  get enjeux() {
    return this.contents.filter((content) => content.placeholder === Placeholder.ENJEU);
  }

  get surroundingContents() {
    return this.contents.filter((content) => ![Placeholder.ENJEU, Placeholder.OBJECTIF].includes(content.placeholder));
  }

  get objectifs() {
    return this.contents.filter((content) => content.placeholder === Placeholder.OBJECTIF);
  }
}
