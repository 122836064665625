






import MediumEditor from 'medium-editor';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import 'medium-editor/dist/css/medium-editor.css';
import 'medium-editor/dist/css/themes/default.css';

@Component
export default class MardownEditor extends Vue {
  @Prop({ required: true }) readonly value!: string;

  mediumEditor: MediumEditor.MediumEditor | null = null;
  mounted() {
    // @ts-ignore
    this.mediumEditor = new MediumEditor(this.$refs.editorRef, {
      toolbar: {
        buttons: ['bold', 'italic', 'underline', 'anchor']
      }
    });
    if (this.mediumEditor) {
      this.mediumEditor.subscribe('editableInput', () => {
        this.$emit('input', this.mediumEditor?.getContent(0));
      });
    }
  }

  @Watch('value')
  onValueChange(newVal: string) {
    if (this.mediumEditor && newVal !== this.mediumEditor.getContent(0)) {
      this.mediumEditor.setContent(newVal, 0);
      this.$emit('input', newVal);
    }
  }
}
