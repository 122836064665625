import axiosInstance from './axios';

class DictionaryService {
  traits() {
    return axiosInstance.get(`/dictionary/traits`);
  }

  definitions(accountId: number) {
    return axiosInstance.get(`/dictionary/definitions/${accountId}`);
  }
}

export default new DictionaryService();
