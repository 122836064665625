

































































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import BanqueCard from '@/components/produit/card/BanqueCard.vue';
import ConseilCard from '@/components/produit/card/ConseilCard.vue';
import DefaultCard from '@/components/produit/card/DefaultCard.vue';
import EpargneCard from '@/components/produit/card/EpargneCard.vue';
import ImmobilierCard from '@/components/produit/card/ImmobilierCard.vue';
import PlacementCard from '@/components/produit/card/PlacementCard.vue';
import PrevoyanceCard from '@/components/produit/card/PrevoyanceCard.vue';
import Scrollable from '@/components/elements/Scrollable.vue';
import Panier from './panier';
import ModalComparator from './ModalComparator.vue';
import { Produit } from '../../models';
import ProduitService from '@/services/produit';
import SelectionService from '@/services/selection';

@Component({
  components: {
    BanqueCard,
    ConseilCard,
    DefaultCard,
    EpargneCard,
    ImmobilierCard,
    PlacementCard,
    PrevoyanceCard,
    Scrollable,
    ModalComparator
  }
})
export default class Selection extends Vue {
  @Prop({ type: Number, default: -1 }) readonly selectionId!: number;

  currentSelection: Produit[] = [];
  titleSelection = '';
  message = true;

  mounted() {
    this.load();
  }

  load() {
    if (this.$route.query.p !== null && this.$route.query.p instanceof String) {
      const produitsId = this.$route.query.p.trim();
      if (produitsId.length > 0) {
        Promise.all(
          produitsId.split(',').map((produitId: string) => {
            return ProduitService.produitOne(parseInt(produitId, 10)).catch((error) => {
              if (error.response.status === 404) {
                Panier.removeById(parseInt(produitId, 10));
                return undefined;
              } else {
                return Promise.reject(error.response);
              }
            });
          })
        ).then((responses) => {
          this.currentSelection.push(...(responses.filter((x) => x !== undefined) as Produit[]));
          if (this.currentSelection.length === 0) {
            this.$router.push({ name: 'Produit' });
          } else if (this.currentSelection.length !== produitsId.length) {
            this.$router.push({
              name: 'Selection',
              query: { p: this.currentSelection.map((x) => x.id).join(',') }
            });
          }
        });
      }
    } else if (this.selectionId !== -1) {
      SelectionService.selectionById(this.selectionId).then((selection) => {
        this.titleSelection = selection.nom;
        this.currentSelection = selection.selectionHasProduit.map((x) => x.produit);
      });
    } else {
      this.currentSelection = [];
    }
  }

  saveCurrentSelection() {
    if (this.titleSelection === '') {
      this.message = false;
    } else {
      this.message = true;
      const currentSelection: { nom: string; produits: number[] } = {
        nom: this.titleSelection,
        produits: this.currentSelection.map((produit) => produit.id)
      };

      if (this.selectionId === -1) {
        SelectionService.selectionSave(currentSelection).then((response) => {
          this.$router.push({
            name: 'SelectionOne',
            params: { selectionId: `${response.id}` }
          });
        });
      } else {
        SelectionService.selectionEdit(this.selectionId, currentSelection);
      }
    }
  }

  onClose(produit: Produit) {
    this.currentSelection = this.currentSelection.filter((s) => s.id !== produit.id);
    Panier.remove(produit);
  }

  ajouterLesProduits() {
    Panier.saveAll(this.currentSelection);
    this.$router.push({ name: 'Produit' });
  }

  @Watch('titleSelection')
  onTitleSelectionChange(n: string) {
    this.message = n !== '';
  }
}
