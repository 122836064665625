












































































import { Vue, Prop, Component } from 'vue-property-decorator';
import { DiagnosticStage, DiagnosticStageName } from '@/models/diagnostic_stage';
import { DiagnosticPipeline } from '@/models/diagnostic_pipeline';
import { DiagnosticConstat } from '@/models/diagnostic_constat';

@Component({
  components: {}
})
export default class DiagnosticPipelineNavigator extends Vue {
  @Prop({ required: true }) pipelinePosition!: {
    stage: DiagnosticStage;
    substage: DiagnosticStage | undefined;
    constat: DiagnosticConstat | undefined;
  };

  @Prop({ required: true }) constats!: DiagnosticConstat[];

  pipeline: DiagnosticStage[] = DiagnosticPipeline;

  get currentStage(): DiagnosticStage {
    if (this.pipelinePosition.substage) return this.pipelinePosition.substage;
    else return this.pipelinePosition.stage;
  }

  constatsInStage(stageName: DiagnosticStageName): DiagnosticConstat[] {
    return this.constats.filter((c) => c.stageName === stageName);
  }
}
