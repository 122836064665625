




































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Selection } from '@/models/selection';

@Component
export default class SelectionCard extends Vue {
  @Prop({ type: Object }) selection!: Selection;
  @Prop({ type: Boolean, default: false }) withClose!: boolean;

  onClose() {
    this.$emit('onClose');
  }

  open() {
    this.$router.push({
      name: 'SelectionOne',
      params: { selectionId: `${this.selection.id}` }
    });
  }
}
