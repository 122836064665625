import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import Login from '../components/Login.vue';
import Logout from '../components/Logout.vue';
import AccountList from '../components/accounts/AccountList.vue';
import AccountOne from '../components/accounts/AccountOne.vue';
import Settings from '../components/settings/Settings.vue';
import SettingsUser from '../components/settings/conseiller/SettingsConseiller.vue';
import SettingsCategorieProduit from '../components/settings/categorie/SettingsCategorieProduit.vue';
import SettingsCaracteristique from '../components/settings/caracteristique/SettingsCaracteristique.vue';
import SettingsEvaluation from '../components/settings/evaluation/SettingsEvaluation.vue';
import SettingsAcmPreconisation from '../components/settings/preconisation/SettingsAcmPreconisation.vue';
import SettingsAcmSommairePreconisationMaster from '../components/settings/preconisation/SettingsAcmPreconisationMaster.vue';
import SettingsCategorieMaster from '../components/settings/categorie/SettingsCategorieMaster.vue';
import ContactList from '../components/contact/ContactList.vue';
import ProduitList from '../components/produit/ProduitList.vue';
import ProduitOne from '../components/produit/ProduitOne.vue';
import ProduitCreateSelectCategorie from '../components/produit/ProduitCreateSelectCategorie.vue';
import ProduitCreateAssociateOrganisation from '../components/produit/ProduitCreateAssociateOrganisation.vue';
import ProduitCreateContent from '../components/produit/ProduitCreateContent.vue';
import ProduitEditContent from '../components/produit/ProduitEditContent.vue';
import AcmList from '../components/acm/AcmList.vue';
import AcmCreateSelectClient from '../components/acm/AcmCreateSelectClient.vue';
import AcmCreateCompareProduct from '../components/acm/AcmCreateCompareProduct.vue';
import AcmCreateEdit from '../components/acm/AcmCreateEdit.vue';
import AcmCreateEditSommaire from '../components/acm/AcmCreateEditSommaire.vue';
import AcmCreatePreconisation from '../components/acm/AcmCreatePreconisation.vue';
import AcmCreatePreconisationSelection from '../components/acm/AcmCreatePreconisationSelection.vue';
import AcmCreateEditLastPage from '../components/acm/AcmCreateEditLastPage.vue';
import AcmCreated from '../components/acm/AcmCreated.vue';
import AcmOne from '../components/acm/AcmOne.vue';
import AcmDuplicate from '../components/acm/AcmDuplicate.vue';
import Selection from '../components/selection/Selection.vue';
import AllSelection from '../components/selection/AllSelection.vue';
import SalesForceCallbackOauth from '../components/SalesForceCallbackOauth.vue';
import SettingsAvantProposMaster from '../components/settings/master/SettingsAvantProposMaster.vue';
import SettingsBordereauMaster from '../components/settings/master/SettingsBordereauMaster.vue';
import TemplateList from '../components/template/TemplateList.vue';
import TemplateCreate from '../components/template/TemplateCreate.vue';
import TemplateOne from '../components/template/TemplateOne.vue';
import DiagnosticCreate from '../components/diagnostic/DiagnosticCreate.vue';
import NotFound from '../components/NotFound.vue';
import { UserModule } from '@/store/modules/user';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { noAuth: true }
  },
  {
    path: '/oauth/callback',
    name: 'SalesForceCallbackOauth',
    component: SalesForceCallbackOauth,
    meta: { noAuth: true }
  },
  { path: '/', name: 'AccountList', component: AccountList },
  {
    path: '/accounts/:accountId',
    name: 'AccountOne',
    component: AccountOne,
    props: true
  },
  { path: '/acm', name: 'AcmList', component: AcmList },
  {
    path: '/acm/new',
    name: 'AcmCreateSelectClient',
    component: AcmCreateSelectClient
  },
  {
    path: '/acm/:acmId',
    name: 'AcmOne',
    component: AcmOne,
    props: (route) => {
      // we keep the params.id Type from changing when entered from url
      const props = { ...route.params };
      // @ts-ignore
      props.acmId = Number.parseInt(props.acmId, 10);
      return props;
    }
  },
  {
    path: '/acm/:acmId/clone',
    name: 'AcmDuplicate',
    component: AcmDuplicate,
    props: (route) => {
      // we keep the params.id Type from changing when entered from url
      const props = { ...route.params };
      // @ts-ignore
      props.acmId = Number.parseInt(props.acmId, 10);
      return props;
    }
  },
  {
    path: '/acm/:acmId/compare',
    name: 'AcmCreateCompareProduct',
    component: AcmCreateCompareProduct,
    props: (route) => {
      // we keep the params.id Type from changing when entered from url
      const props = { ...route.params };
      // @ts-ignore
      props.acmId = Number.parseInt(props.acmId, 10);
      return props;
    }
  },
  {
    path: '/acm/:acmId/edit',
    name: 'AcmCreateEdit',
    component: AcmCreateEdit,
    props: (route) => {
      // we keep the params.id Type from changing when entered from url
      const props = { ...route.params };
      // @ts-ignore
      props.acmId = Number.parseInt(props.acmId, 10);
      return props;
    }
  },
  {
    path: '/acm/:acmId/edit/sommaire',
    name: 'AcmCreateEditStep2',
    component: AcmCreateEditSommaire,
    props: (route) => {
      // we keep the params.id Type from changing when entered from url
      const props = { ...route.params };
      // @ts-ignore
      props.acmId = Number.parseInt(props.acmId, 10);
      return props;
    }
  },
  {
    path: '/acm/:acmId/edit/preconisation/:acmPrecoId',
    name: 'AcmCreatePreconisation',
    component: AcmCreatePreconisation,
    props: (route) => {
      // we keep the params.id Type from changing when entered from url
      const props = { ...route.params };
      // @ts-ignore
      props.acmId = Number.parseInt(props.acmId, 10);
      // @ts-ignore
      props.acmPrecoId = Number.parseInt(props.acmPrecoId, 10);
      return props;
    }
  },
  {
    path: '/acm/:acmId/edit/preconisation/:acmPrecoId/selection',
    name: 'AcmCreatePreconisationSelection',
    component: AcmCreatePreconisationSelection,
    props: (route) => {
      // we keep the params.id Type from changing when entered from url
      const props = { ...route.params };
      // @ts-ignore
      props.acmId = Number.parseInt(props.acmId, 10);
      // @ts-ignore
      props.acmPrecoId = Number.parseInt(props.acmPrecoId, 10);
      return props;
    }
  },
  {
    path: '/acm/:acmId/edit/last',
    name: 'AcmCreateEditLastPage',
    component: AcmCreateEditLastPage,
    props: (route) => {
      // we keep the params.id Type from changing when entered from url
      const props = { ...route.params };
      // @ts-ignore
      props.acmId = Number.parseInt(props.acmId, 10);
      return props;
    }
  },
  {
    path: '/acm/:acmId/end',
    name: 'AcmCreated',
    component: AcmCreated,
    props: (route) => {
      // we keep the params.id Type from changing when entered from url
      const props = { ...route.params };
      // @ts-ignore
      props.acmId = Number.parseInt(props.acmId, 10);
      return props;
    }
  },
  {
    path: '/produit/selection/new',
    name: 'Selection',
    component: Selection,
    props: true
  },
  {
    path: '/produit/selection/:selectionId',
    name: 'SelectionOne',
    component: Selection,
    props: (route) => {
      // we keep the params.id Type from changing when entered from url
      const props = { ...route.params };
      // @ts-ignore
      props.selectionId = Number.parseInt(props.selectionId, 10);
      return props;
    }
  },
  {
    path: '/produit/selection',
    name: 'AllSelection',
    component: AllSelection,
    props: true
  },
  { path: '/produit', name: 'Produit', component: ProduitList },
  {
    path: '/produit/new',
    name: 'ProduitCreateSelectCategorie',
    component: ProduitCreateSelectCategorie
  },
  {
    path: '/produit/new/:id',
    name: 'ProduitCreateAssociateOrganisation',
    component: ProduitCreateAssociateOrganisation,
    props: (route) => {
      // we keep the params.id Type from changing when entered from url
      const props = { ...route.params };
      // @ts-ignore
      props.id = Number.parseInt(props.id, 10);
      return props;
    }
  },
  {
    path: '/produit/new/:id/:orga',
    name: 'ProduitCreateContent',
    component: ProduitCreateContent,
    props: (route) => {
      // we keep the params.id Type from changing when entered from url
      const props = { ...route.params };
      // @ts-ignore
      props.id = Number.parseInt(props.id, 10);
      // @ts-ignore
      props.orga = Number.parseInt(props.orga, 10);
      return props;
    }
  },
  {
    path: '/produit/:produitId',
    name: 'ProduitOne',
    component: ProduitOne,
    props: (route) => {
      // we keep the params.id Type from changing when entered from url
      const props = { ...route.params };
      // @ts-ignore
      props.produitId = Number.parseInt(props.produitId, 10);
      return props;
    }
  },
  {
    path: '/produit/:produitId/edit',
    name: 'ProduitEditContent',
    component: ProduitEditContent,
    props: (route) => {
      // we keep the params.id Type from changing when entered from url
      const props = { ...route.params };
      // @ts-ignore
      props.produitId = Number.parseInt(props.produitId, 10);
      return props;
    }
  },
  { path: '/contact', name: 'Contact', component: ContactList },
  {
    path: '/settings',
    component: Settings,
    children: [
      { path: '', component: SettingsUser, name: 'Settings.User' },
      {
        path: 'produit/categorie',
        component: SettingsCategorieProduit,
        name: 'Settings.Categorie'
      },
      {
        path: 'produit/caracteristique',
        component: SettingsCaracteristique,
        name: 'Settings.Caracteristique'
      },
      {
        path: 'produit/evaluation',
        component: SettingsEvaluation,
        name: 'Settings.Evaluation'
      },
      {
        path: 'acm/preconisation',
        component: SettingsAcmPreconisation,
        name: 'Settings.AcmPreconisation'
      },
      {
        path: 'master/avantpropos',
        component: SettingsAvantProposMaster,
        name: 'Settings.AvantPropos'
      },
      {
        path: 'master/bordereau',
        component: SettingsBordereauMaster,
        name: 'Settings.Bordereau'
      }
    ]
  },
  {
    path: '/settings/produit/categorie/:id/master',
    component: SettingsCategorieMaster,
    name: 'Settings.CaracteristiqueMaster',
    props: (route) => {
      // we keep the params.id Type from changing when entered from url
      const props = { ...route.params };
      // @ts-ignore
      props.id = Number.parseInt(props.id, 10);
      return props;
    }
  },
  {
    path: '/settings/acm/sommaire/preconisastion/:precoId/master',
    component: SettingsAcmSommairePreconisationMaster,
    name: 'Settings.AcmSommairePreconisationMaster',
    props: (route) => {
      // we keep the params.id Type from changing when entered from url
      const props = { ...route.params };
      // @ts-ignore
      props.precoId = Number.parseInt(props.precoId, 10);
      return props;
    }
  },
  {
    path: '/template',
    component: TemplateList,
    name: 'TemplateList'
  },
  {
    path: '/template/new',
    component: TemplateCreate,
    name: 'TemplateCreate'
  },
  {
    path: '/template/:templateId',
    component: TemplateOne,
    name: 'TemplateOne',
    props: (route) => {
      // we keep the params.id Type from changing when entered from url
      const props = { ...route.params };
      // @ts-ignore
      props.templateId = Number.parseInt(props.templateId, 10);
      return props;
    }
  },
  {
    path: '/accounts/:accountId/diagnostic',
    component: DiagnosticCreate,
    name: 'DiagnosticCreate',
    props: (route) => {
      // we keep the params.id Type from changing when entered from url
      const props = { ...route.params };
      // @ts-ignore
      props.acmId = Number.parseInt(props.acmId, 10);
      return props;
    }
  },
  { path: '/logout', name: 'Logout', component: Logout },
  { path: '*', component: NotFound }
];

const createRouter = () =>
  new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  });

const router = createRouter();

router.beforeEach((to, from, next) => {
  if (!UserModule.jwtToken && !to.matched.some((record) => record.meta.noAuth)) {
    next(`/login?redirect=${to.path}`);
  }

  next();
});

export default router;

export function resetRouter() {
  const newRouter = createRouter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (router as any).matcher = (newRouter as any).matcher; // reset router
}
