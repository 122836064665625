// @ts-nocheck
export default function MentionCustomization(editor: Editor) {
  // Downcast the model "mention" text attribute to a view <a> element.
  editor.conversion.for('downcast').attributeToElement({
    model: 'mention',
    view: (modelAttributeValue, { writer }) => {
      // Do not convert empty attributes (lack of value means no mention).
      if (!modelAttributeValue) {
        return;
      }

      return writer.createAttributeElement(
        'span',
        {
          class: 'mention',
          'data-mention': modelAttributeValue.id
        },
        {
          // Make mention attribute to be wrapped by other attribute elements.
          priority: 20,
          // Prevent merging mentions together.
          id: modelAttributeValue.uid
        }
      );
    },
    converterPriority: 'high'
  });
}
