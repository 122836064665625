// @ts-nocheck
import Vue from 'vue';
import * as CKEditor5 from '@ckeditor/ckeditor5-vue2';

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image';
import ImageToolbarPlugin from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageCaptionPlugin from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStylePlugin from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload';
import ImageResizePlugin from '@ckeditor/ckeditor5-image/src/imageresize';
import PageBreakPlugin from '@ckeditor/ckeditor5-page-break/src/pagebreak';
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
import ListPlugin from '@ckeditor/ckeditor5-list/src/list';
import AlignmentPlugin from '@ckeditor/ckeditor5-alignment/src/alignment';
import Mention from '@ckeditor/ckeditor5-mention/src/mention';
import MentionCustomization from './ckeditor_mention_customization';
import TablePlugin from '@ckeditor/ckeditor5-table/src/table';
import TableToolbarPlugin from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import * as frTranslations from '@ckeditor/ckeditor5-build-classic/build/translations/fr.js';

Vue.use(CKEditor5);

export const Editor = ClassicEditor;

export const TitleEditorConfig = {
  language: 'fr',
  plugins: [EssentialsPlugin, ParagraphPlugin],
  toolbar: []
};

export const BodyEditorConfig = {
  ...TitleEditorConfig,
  plugins: [
    EssentialsPlugin,
    BoldPlugin,
    ItalicPlugin,
    UnderlinePlugin,
    LinkPlugin,
    ParagraphPlugin,
    ImagePlugin,
    ImageToolbarPlugin,
    ImageCaptionPlugin,
    ImageStylePlugin,
    ImageUploadPlugin,
    ImageResizePlugin,
    SimpleUploadAdapter,
    ListPlugin,
    AlignmentPlugin,
    PageBreakPlugin,
    Mention,
    MentionCustomization,
    TablePlugin,
    TableToolbarPlugin
  ],
  simpleUpload: {},
  toolbar: [
    'bold',
    'italic',
    'underline',
    'bulletedList',
    'numberedList',
    '|',
    'alignment',
    '|',
    'pageBreak',
    '|',
    'imageUpload',
    'imageStyle:full',
    'imageStyle:side',
    '|',
    'insertTable',
    '|',
    'undo',
    'redo'
  ],
  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
  },
  alignment: {
    options: ['left', 'right', 'center', 'justify']
  }
};

// Adapted from example in source to add translations
// https://github.com/ckeditor/ckeditor5/blob/c9fc5ca1ba41ac5646c44620db030e9540f0854e/packages/ckeditor5-utils/src/translation-service.js#L66
if (!window.CKEDITOR_TRANSLATIONS) {
  window.CKEDITOR_TRANSLATIONS = {};
}
if (!window.CKEDITOR_TRANSLATIONS.fr) {
  window.CKEDITOR_TRANSLATIONS.fr = {};
}

const languageTranslations = window.CKEDITOR_TRANSLATIONS.fr;

languageTranslations.dictionary = languageTranslations.dictionary || {};

// Extend the dictionary for the given language.
Object.assign(languageTranslations.dictionary, frTranslations);
