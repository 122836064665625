export default {
  allState: [
    {
      text: 'en brouillon',
      code: 'BROUILLON',
      color: 'secondary',
      hexa: '#90c1c4'
    },
    {
      text: 'présentable',
      code: 'PRESENTE',
      color: 'primary',
      hexa: '#e9b46d'
    },
    {
      text: 'à remodifier',
      code: 'ATRAITER',
      color: 'danger',
      hexa: '#C0232C'
    },
    { text: 'accepté', code: 'ACCEPTE', color: 'success', hexa: '#14746b' },
    { text: 'rejeté', code: 'REJETER', color: 'danger', hexa: '#C0232C' }
  ],
  allText() {
    return this.allState.map((x) => x.text);
  },
  allCode() {
    return this.allState.map((x) => x.code);
  },
  allColor() {
    return this.allState.map((x) => x.hexa);
  },
  indexOf(code) {
    return this.allState.map((x) => x.code).indexOf(code);
  },
  stateForCode(code) {
    return this.allState.find((x) => x.code === code);
  },
  colorForCode(code) {
    return this.stateForCode(code).hexa;
  },
  textForCode(code) {
    return this.stateForCode(code).text;
  }
};
