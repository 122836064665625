









































































































































































































































































import { Vue, Prop, Component, Watch } from 'vue-property-decorator';

import { Diagnostic } from '../../models/diagnostic';
import { DiagnosticStage, DiagnosticStageName } from '../../models/diagnostic_stage';
import { DiagnosticConstat } from '../../models/diagnostic_constat';
import { DiagnosticAnnex } from '../../models/diagnostic_annex';

import { Template, TemplateCategory } from '../../models/template';
import { Section } from '../../models/section';
import { Placeholder } from '../../models/placeholder';
// @ts-ignore
import draggable from 'vuedraggable';
import { Editor, TitleEditorConfig, BodyEditorConfig } from '@/plugins/ckeditor';
import FileUpload from 'vue-upload-component';
import DictionaryService from '@/services/dictionary';
import { diagnosticService } from '@/services/diagnostic';
import TemplateService from '@/services/template';
import { UserModule } from '@/store/modules/user';
import PdfViewer from '@/components/elements/PdfViewer.vue';
import Body from '../livrable/Body.vue';
import { Trait } from '@/models/dictionary';

@Component({
  components: {
    draggable,
    FileUpload,
    PdfViewer,
    Body
  },
  filters: {
    capitalize(value: string) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }
})
export default class ContentForm extends Vue {
  @Prop({ required: false }) accountId?: number;
  @Prop({ required: false }) template?: Template;
  @Prop({ required: false }) diagnostic?: Diagnostic;
  @Prop({ required: true }) contents!: Section[];
  @Prop({ required: false }) stage?: DiagnosticStage;
  @Prop({ required: false }) constat?: DiagnosticConstat;
  @Prop({ required: true }) loadingContents!: boolean;
  @Prop({ required: true }) annexes!: DiagnosticAnnex[];

  stageNames = DiagnosticStageName;
  placeholders = Placeholder;
  editor = Editor;

  uploadAccept = {
    extensions: ['jpg', 'jpeg', 'gif', 'png', 'webp', 'doc', 'docx', 'pdf'],
    mimeTypes: [
      'image/png',
      'image/gif',
      'image/jpeg',
      'image/webp',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf'
    ]
  };

  annexesUpload = [];

  traits: Array<Trait> = [];

  prevCenteredContent: Section | null = null;

  organizingEditors = false;

  get annexesAllUploaded() {
    return this.annexesUpload.every((a: { success: boolean }) => a.success);
  }

  get uploadImageUrl() {
    if (this.accountId) return diagnosticService.uploadDiagnosticImageUrl(this.accountId);
    else if (this.template) return TemplateService.uploadTemplateImageUrl(this.template.id);
    else return undefined;
  }

  get uploadAnnexUrl() {
    if (this.diagnostic && this.stage && this.accountId)
      return diagnosticService.uploadDiagnosticAnnexUrl(this.accountId, this.stage.name, this.constat);
    else if (this.template) return TemplateService.uploadTemplateAnnexUrl(this.template.id);
    else return undefined;
  }

  get token() {
    return UserModule.jwtToken;
  }

  get availablePlaceholders() {
    if (
      (this.stage && this.stage.name === DiagnosticStageName.OBJECTIFS) ||
      (this.template && this.template.subcategory === DiagnosticStageName.OBJECTIFS)
    )
      return [Placeholder.ENJEU, Placeholder.OBJECTIF, Placeholder.BLOC_TEXTE];
    return [
      Placeholder.TITRE,
      Placeholder.SOUS_TITRE,
      Placeholder.SOUS_TITRE_INTERNE,
      Placeholder.BLOC_TEXTE,
      Placeholder.AVANTAGES_RISQUES
    ];
  }

  @Watch('annexesUpload')
  onAnnexUpload() {
    if (this.annexesAllUploaded) {
      this.updateAnnexes();
      if (this.annexesUpload.length) this.annexesUpload = [];
    }
  }

  updateAnnexes() {
    if (this.stage) this.$emit('update-annexes', this.stage.name, this.constat);
    else if (this.template) this.$emit('update-annexes');
  }

  removeAnnex(annexName: string) {
    if (this.diagnostic && this.stage && this.accountId)
      diagnosticService
        .diagnosticAnnexDelete(this.accountId, annexName, this.stage.name, this.constat)
        .then(() => this.updateAnnexes());
    else if (this.template)
      TemplateService.templateAnnexDelete(this.template.id, annexName).then(() => this.updateAnnexes());
  }

  async uploadAllAnnexes() {
    if (this.annexesUpload.length === 0) return;

    // Update with link to persisted template's annex directory
    this.annexesUpload.forEach((annex: { postAction: string }) => {
      if (this.uploadAnnexUrl) annex.postAction = this.uploadAnnexUrl;
    });
    // @ts-ignore
    this.$refs.upload.active = true;
    while (!this.$refs.annexesUploadStatus) {
      await new Promise((resolve) => setTimeout(resolve, 500));
    }
  }

  cancelAnnexUpload(annexNameToRemove: string) {
    this.annexesUpload = this.annexesUpload.filter((annex: { name: string }) => annex.name !== annexNameToRemove);
  }

  getAnnexFiletype = (name: string): string => {
    if (name.match(/\.(jpg|jpeg|gif|png|webp)$/)) {
      return 'image';
    }
    if (name.match(/\.(doc|docx|pdf|html)$/)) {
      return 'document';
    }
    return 'unknown';
  };

  previewableAnnex(name: string) {
    return this.getAnnexFiletype(name) === 'image' || name.match(/\.(pdf|html)$/);
  }

  uppercase = (value: string) => {
    if (!value) return '';
    return value.toString().toUpperCase();
  };

  mounted() {
    console.log('ContentForm - mounted');
    this.updateAnnexes();

    DictionaryService.traits().then((response) => {
      this.traits = response.data;
    });

    window.addEventListener('scroll', this.updateCenteredContent);
  }

  updateCenteredContent() {
    if (!this.$el.querySelectorAll) return;
    const contentsNodes: Array<HTMLElement> = Array.from(this.$el.querySelectorAll('li.content'));
    if (contentsNodes.length === 0) return;

    const centeredContentNode = contentsNodes.reduce((acc, node) => {
      if (this.distanceToCenter(node.getBoundingClientRect()) < this.distanceToCenter(acc.getBoundingClientRect())) {
        return node;
      }
      return acc;
    });
    const centeredDisplayOrder = parseInt(centeredContentNode.dataset.contentDisplayOrder || '', 10);
    const centeredContent = this.contents.find(
      (c) => this.stage && c.stageName === this.stage.name && c.displayOrder === centeredDisplayOrder
    );

    if (centeredContent && (!this.prevCenteredContent || centeredContent.id !== this.prevCenteredContent.id)) {
      this.prevCenteredContent = centeredContent;
      this.$emit('focus-content', centeredContent);
    }
  }

  distanceToCenter(rect: DOMRect) {
    return Math.min(Math.abs(this.viewportFloatingLine - rect.top), Math.abs(this.viewportFloatingLine - rect.bottom));
  }

  get viewportFloatingLine() {
    const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    return viewportHeight / 3;
  }

  get draggableContents() {
    return this.contents.filter((content) => content.displayOrder !== undefined);
  }

  set draggableContents(draggedContents) {
    this.$emit('reorder-contents', draggedContents);
  }

  get avantProposLieuContent() {
    if (this.stage && this.stage.name !== DiagnosticStageName.AVANTPROPOS) return null;
    return this.contents.find((c) => c.placeholder === Placeholder.LIEU);
  }

  get avantProposDateContent() {
    if (this.stage && this.stage.name !== DiagnosticStageName.AVANTPROPOS) return null;
    return this.contents.find((c) => c.placeholder === Placeholder.DATE);
  }

  contentOvertext(content: Section): { tag: string; classList: string; text: string } | null {
    if (content.placeholder === Placeholder.OBJECTIF)
      return {
        tag: 'h3',
        classList: 'placeholder-overtext text-primary mb-3',
        text: `Objectif ${this.objectifIndexes[content.displayOrder]}`
      };

    const emptyContent = content.content && content.content !== '' && content.placeholder !== undefined;
    const firstContentInAvantPropos =
      this.diagnostic &&
      this.stage &&
      this.stage.name === DiagnosticStageName.AVANTPROPOS &&
      content.displayOrder === 0;

    if (emptyContent && !firstContentInAvantPropos)
      return {
        tag: 'small',
        classList: 'placeholder-overtext text-muted',
        text: this.$t(`placeholder.${content.placeholder}`).toString()
      };

    return null;
  }

  addContent(placeholder: Placeholder | null) {
    console.log('addContent');
    const existingDisplayOrders = this.draggableContents.map((c) => c.displayOrder);
    const displayOrder =
      // Add -1 to cover case with no contents ; math.floor would return -Infinity
      existingDisplayOrders.reduce((do1, do2) => Math.max(do1, do2), 0) + 1;

    const content: Section = {
      displayOrder,
      placeholder: placeholder || Placeholder.BLOC_TEXTE
    };

    if (this.stage && this.diagnostic) {
      content.diagnosticId = this.diagnostic.id;
      content.stageName = this.stage.name;
      if (this.constat) content.constat = this.constat;

      this.$emit('add-content', content);
    } else {
      content.template = this.template;
      this.contents.push(content);

      this.$nextTick().then(() => {
        const newEditor = document.querySelector(`#content-${displayOrder}`);
        if (newEditor) {
          newEditor.scrollIntoView({ behavior: 'smooth' });
          newEditor.animate([{ opacity: '0' }, { opacity: '1' }], {
            duration: 500
          });
        }
      });
    }
  }

  async animateRemoveContent(content: Section) {
    const deletedEditor = document.querySelector(`li[data-content-key='${this.contentKey(content)}']`);
    if (deletedEditor) {
      const removeAnimation = deletedEditor.animate([{ opacity: '1' }, { opacity: '0' }], { duration: 500 });
      removeAnimation.onfinish = () => {
        this.removeContent(content);
      };
    } else {
      this.removeContent(content);
    }
  }

  removeContent(content: Section) {
    this.$emit('remove-content', content);
  }

  get titlePlaceholders() {
    return [Placeholder.TITRE, Placeholder.SOUS_TITRE];
  }

  editorConfig(placeholder: Placeholder) {
    return {
      placeholder: this.$t(`placeholder.${placeholder}`),
      ...(this.titlePlaceholders.includes(placeholder) ? this.titleConfig : this.editorPluginsConfig)
    };
  }

  get titleConfig() {
    return TitleEditorConfig;
  }

  get editorPluginsConfig() {
    return {
      ...BodyEditorConfig,
      simpleUpload: {
        uploadUrl: this.uploadImageUrl,
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      },
      mention: {
        feeds: [
          {
            marker: '@',
            feed: this.getAvailableTraits
          }
        ]
      }
    };
  }

  @Watch('organizingEditors')
  updatePlaceholders(organizingEditors: boolean, oldOrganizingEditors: boolean) {
    if ((organizingEditors && !oldOrganizingEditors) || !this.$el.querySelectorAll) return;

    this.$el.querySelectorAll('.ck-placeholder').forEach((ph) => {
      const contentPlaceholder =
        ((ph.closest('.content')?.querySelector('[data-content-placeholder]') as unknown) as {
          dataset: { [key: string]: string };
        }) || null;
      if (contentPlaceholder === null) return;

      ph.setAttribute(
        'data-placeholder',
        this.$t(`placeholder.${contentPlaceholder.dataset.contentPlaceholder}`).toString()
      );
    });
  }

  get getAvailableTraits() {
    return this.traits.flatMap((tr: Trait) => {
      if (tr.onContact) return [{ id: `@${tr.name}1` }, { id: `@${tr.name}2` }];
      else return { id: `@${tr.name}` };
    });
  }

  onEditorReady(editor: {
    sourceElement: { dataset: { contentPlaceholder: string } };
    getData: () => string;
    setData: (data: string) => void;
  }) {
    if (editor.sourceElement.dataset.contentPlaceholder === Placeholder.AVANTAGES_RISQUES && editor.getData() === '') {
      editor.setData(
        '<table><thead><tr><th>Avantages</th>' +
          '<th>Inconvénients</th></tr></thead>' +
          '<tbody><tr><td><ul><li>...</li></ul></td>' +
          '<td><ul><li>...</li></ul>' +
          '</td></tr></tbody></table>'
      );
    }
  }

  get objectifIndexes() {
    const map: { [key: number]: number } = {};
    let objectifIndex = 1;
    this.draggableContents.forEach((c) => {
      if (c.placeholder === Placeholder.OBJECTIF) {
        map[c.displayOrder] = objectifIndex;
        objectifIndex += 1;
      }
    });
    return map;
  }

  get isAnnexTemplate() {
    return this.template && this.template.category === TemplateCategory.ANNEXES;
  }

  get annexUploadStage() {
    const allowedStages = [
      DiagnosticStageName.ANALYSE,
      DiagnosticStageName.CIVIL,
      DiagnosticStageName.PATRIMONIAL,
      DiagnosticStageName.SOCIAL,
      DiagnosticStageName.ECONOMIQUE,
      DiagnosticStageName.FISCAL
    ];
    return this.diagnostic && this.stage && allowedStages.includes(this.stage.name);
  }

  get canUploadAnnex() {
    return (this.template && !this.isAnnexTemplate) || (this.diagnostic && this.annexUploadStage);
  }

  contentKey(content: Section): string {
    return [content.id, content.stageName, content.displayOrder, content.placeholder].join('-');
  }

  moveToTop(content: Section): void {
    let clone = this.draggableContents.slice(0);
    clone = clone.filter((item) => item.displayOrder !== content.displayOrder);
    clone.unshift(content);
    this.draggableContents = clone;
  }
  moveToDown(content: Section): void {
    let clone = this.draggableContents.slice(0);
    clone = clone.filter((item) => item.displayOrder !== content.displayOrder);
    clone.push(content);
    this.draggableContents = clone;
  }
}
