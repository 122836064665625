import axiosInstance from './axios';
import axios, { CancelTokenSource } from 'axios';
import { Account } from '../models/account';

class AccountService {
  accountList(page?: number | undefined) {
    const params: string[] = [];
    if (page) params.push(`page=${page}`);

    return axiosInstance.get<Account[]>(`/accounts?${params.join('&')}`);
  }

  allAccountsSync() {
    return axiosInstance.get<void>('/update/accounts');
  }

  oneAccountSync(accountId: number) {
    return axiosInstance.get<void>(`/update/account/${accountId}`);
  }

  searchAccount(search: string, page?: number | null, cancelToken?: CancelTokenSource) {
    let url = `/accounts/?search=${search}`;
    if (page) url = `${url}&page=${page}`;

    let options = {};
    if (cancelToken) options = { cancelToken: cancelToken.token };

    return axiosInstance.get<Account[]>(url, options);
  }

  searchAccountsCancelToken() {
    return axios.CancelToken.source();
  }

  accountById(accountId: number) {
    return axiosInstance.get<Account>(`/accounts/${accountId}`);
  }
}

export default new AccountService();
