import axios from './axios';
import { Produit } from '@/models/produit';
import { ConseillerHasEditedProduit } from '@/models/conseillerhaseditedproduit';
import { ProduitHasCaracteristique } from '@/models/produithascaracteristique';
import { Caracteristique, CaracteristiqueWithValue } from '@/models/caracteristique';
import { ProduitHasEvaluation } from '@/models/produithasevaluation';
import { Notation } from '@/models/notation';
import { Evaluation } from '@/models/evaluation';
import { InputType } from '@/models/inputtype';
import { CategorieType } from '@/models/categorietype';
import { CategorieProduit } from '@/models/categorieproduit';

class ProduitService {
  async searchProduit(search: string): Promise<Produit[]> {
    return axios.get('/produit/?search=' + search).then((response) => {
      return response.data as Produit[];
    });
  }

  async produitList(): Promise<Produit[]> {
    return axios.get('/produit/').then((response) => {
      return response.data as Produit[];
    });
  }

  async produitOne(produitId: number): Promise<Produit> {
    return axios.get('/produit/' + produitId).then((response) => {
      return response.data as Produit;
    });
  }

  async produitHistory(acmId: number): Promise<ConseillerHasEditedProduit[]> {
    return axios.get('/produit/' + acmId + '/history').then((response) => {
      return response.data as ConseillerHasEditedProduit[];
    });
  }

  produitDelete(produitId: number) {
    return axios.post('/produit/' + produitId + '/delete');
  }

  async produitCaracteristique(produitId: number): Promise<ProduitHasCaracteristique[]> {
    return axios.get('/produit/' + produitId + '/content').then((response) => {
      return response.data as ProduitHasCaracteristique[];
    });
  }

  async produitCreate(
    organisation: number,
    categorieType: number,
    content: CaracteristiqueWithValue[],
    name: string,
    pointFort: string,
    pointFaible: string
  ): Promise<Produit> {
    return axios
      .post('/produit/new/save', {
        organisation,
        categorieType,
        content: content,
        name,
        pointFort,
        pointFaible
      })
      .then((response) => {
        return response.data as Produit;
      });
  }

  async produitEdit(
    produitId: number,
    content: CaracteristiqueWithValue[],
    name: string,
    pointFort: string,
    pointFaible: string
  ): Promise<Produit> {
    return axios
      .post('/produit/' + produitId + '/save', {
        content: content,
        name: name,
        pointFort: pointFort,
        pointFaible: pointFaible
      })
      .then((response) => {
        return response.data as Produit;
      });
  }

  async loadCategorieType(): Promise<CategorieType[]> {
    return axios.get('/produit/category-type/').then((response) => {
      return response.data as CategorieType[];
    });
  }

  async loadCategorie(): Promise<CategorieProduit[]> {
    return axios.get('/produit/category/').then((response) => {
      return response.data as CategorieProduit[];
    });
  }

  async loadOneCategorie(id: number): Promise<CategorieProduit> {
    return axios.get('/produit/category/' + id).then((response) => {
      return response.data as CategorieProduit;
    });
  }

  async loadCategorieMaster(id: number): Promise<Caracteristique[]> {
    return axios.get('/produit/category/' + id + '/caracteristique').then((response) => {
      return response.data as Caracteristique[];
    });
  }

  categorieSaveMaster(id: number, content: Caracteristique[]) {
    return axios.post('/settings/produit/category/' + id + '/master', content);
  }

  createCategorie(id: number | undefined, nom: string, categoryParent: number) {
    return axios.post('/settings/produit/category/add', {
      id: id,
      nom: nom,
      categoryParent
    });
  }

  categorieDelete(id: number) {
    return axios.post('/settings/produit/category/' + id + '/delete', {});
  }

  async loadCaracteristique(): Promise<Caracteristique[]> {
    return axios.get('/produit/caracteristique').then((response) => {
      return response.data as Caracteristique[];
    });
  }

  async loadCaracteristiqueByCategoryType(id: number): Promise<Caracteristique[]> {
    return axios.get('/produit/caracteristique/categorytype/' + id).then((response) => {
      return response.data as Caracteristique[];
    });
  }

  async loadInputType(): Promise<InputType[]> {
    return axios.get('/produit/caracteristique/input-type').then((response) => {
      return response.data as InputType[];
    });
  }

  produitCreateCaracteristique(
    nom: string,
    type: number,
    information: string,
    itemId: number | undefined,
    categorieSelected: number[],
    inputCustomValues: { value: string }[]
  ) {
    return axios.post('/settings/produit/caracteristique/add', {
      id: itemId,
      nom,
      information,
      type,
      categorieSelected,
      inputCustomValues
    });
  }

  async toggleProduitCaracteristique(caracteristiqueId: number, categorieType: number): Promise<Caracteristique> {
    return axios
      .post('/settings/produit/caracteristique/' + caracteristiqueId + '/toggle/' + categorieType)
      .then((response) => {
        return response.data as Caracteristique;
      });
  }

  caracteristiqueDelete(id: number) {
    return axios.post('/settings/produit/caracteristique/' + id + '/delete', {});
  }

  async produitEvaluation(produitId: number) {
    return axios.get('/produit/' + produitId + '/evaluation').then((response) => {
      return response.data as ProduitHasEvaluation[];
    });
  }

  produitEvaluationEdit(produitId: number, evaluationId: number, content: string) {
    return axios.post('/produit/' + produitId + '/evaluation/' + evaluationId, {
      content
    });
  }

  produitEvaluationDelete(produitId: number, evaluationId: number) {
    return axios.post('/produit/' + produitId + '/evaluation/' + evaluationId + '/delete');
  }

  async produitComment(produitId: number): Promise<Notation[]> {
    return axios.get('/produit/' + produitId + '/comment').then((response) => {
      return response.data as Notation[];
    });
  }

  produitCommentNew(produitId: number, comment: string, note: number) {
    return axios.post('/produit/' + produitId + '/comment', {
      comment,
      note
    });
  }

  produitCommentEdit(produitId: number, commentId: number, comment: string, note: number) {
    return axios.post('/produit/' + produitId + '/comment/' + commentId, {
      comment,
      note
    });
  }

  produitCommentDelete(produitId: number, commentId: number) {
    return axios.post('/produit/' + produitId + '/comment/' + commentId + '/delete');
  }

  async searchCaracteristiqueValue(caracId: number, keyword: string): Promise<string[]> {
    return axios
      .get('/produit/search/caracteristique/' + caracId, {
        params: {
          q: keyword
        }
      })
      .then((response) => {
        return response.data as string[];
      });
  }

  async evaluations(): Promise<Evaluation[]> {
    return axios.get('/produit/evaluation').then((response) => {
      return response.data as Evaluation[];
    });
  }

  async evaluation(id: number): Promise<Evaluation> {
    return axios.get('/produit/evaluation/' + id).then((response) => {
      return response.data as Evaluation;
    });
  }

  evaluationNew(nom: string, type: number, information: string, itemId?: number) {
    return axios.post('/settings/produit/evaluation/add', {
      id: itemId,
      nom,
      information,
      type
    });
  }

  evaluationDelete(id: number) {
    return axios.post('/settings/produit/evaluation/' + id + '/delete', {});
  }

  async evaluationInputTypes(): Promise<InputType[]> {
    return axios.get('/produit/evaluation/input-type').then((response) => {
      return response.data as InputType[];
    });
  }
}

export default new ProduitService();
