import { DiagnosticComment } from '@/models/diagnostic_comment';
import axiosInstance from './axios';

class CommentsService {
  comments(diagnosticId: number) {
    return axiosInstance.get<DiagnosticComment[]>(`/diagnostic/${diagnosticId}/comments`);
  }

  addComment(commentContent: string, diagnosticId: number, sectionId: number, parentId?: number) {
    let url = `/diagnostic/${diagnosticId}/${sectionId}/comment`;
    if (parentId !== undefined) url = `/diagnostic/${diagnosticId}/${sectionId}/comment/${parentId}/reply`;

    return axiosInstance.post<void>(url, commentContent);
  }

  editComment(commentContent: string, diagnosticId: number, sectionId: number, commentId: number) {
    return axiosInstance.post<void>(`/diagnostic/${diagnosticId}/${sectionId}/comment/${commentId}`, commentContent);
  }

  archiveComment(diagnosticId: number, sectionId: number, commentId: number) {
    return axiosInstance.post<void>(
      '/' + ['diagnostic', diagnosticId, sectionId, 'comment', commentId, 'archive'].join('/')
    );
  }
}

export default new CommentsService();
