import axios from './axios';
import { AcmMaster } from '@/models/acmmaster';

class AcmMasterService {
  async getAcmMaster(): Promise<AcmMaster> {
    return axios.get<AcmMaster>('/acmmaster/').then((response) => {
      return response.data;
    });
  }

  async updateAcmMaster(acmMasterData: { avantProposData: string; bordereauData: string }): Promise<AcmMaster> {
    return axios.post<AcmMaster>('/acmmaster/', acmMasterData).then((response) => {
      return response.data;
    });
  }
}

export default new AcmMasterService();
