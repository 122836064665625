var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-lg-10 col-xl-3"},[_vm._m(0),_c('ul',{staticClass:"nav navbar-nav"},[_c('router-link',{attrs:{"custom":"","exact":"","to":{ name: 'Settings.User' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:{ active: isActive },on:{"click":navigate}},[_vm._v("Gestion des utilisateurs")])]}}])}),_c('router-link',{attrs:{"custom":"","exact":"","to":{ name: 'Settings.Categorie' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:{ active: isActive },on:{"click":navigate}},[_vm._v("Gestion des catégories de produits")])]}}])}),_c('router-link',{attrs:{"custom":"","exact":"","to":{ name: 'Settings.Caracteristique' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:{ active: isActive },on:{"click":navigate}},[_vm._v("Gestion des caractéristiques de produits")])]}}])}),_c('router-link',{attrs:{"custom":"","exact":"","to":{ name: 'Settings.AcmPreconisation' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:{ active: isActive },on:{"click":navigate}},[_vm._v("Gestion des préconisations d'une ACM")])]}}])}),_c('router-link',{attrs:{"custom":"","to":{ name: 'Settings.AvantPropos' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:{ active: isActive },on:{"click":navigate}},[_vm._v("Gestion du master Avant Propos")])]}}])}),_c('router-link',{attrs:{"custom":"","to":{ name: 'Settings.Bordereau' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:{ active: isActive },on:{"click":navigate}},[_vm._v("Gestion du master Bordereau")])]}}])}),_c('hr',{staticClass:"border border-primary w-100"}),_c('router-link',{attrs:{"custom":"","to":{ name: 'Settings.Evaluation' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:{ active: isActive },on:{"click":navigate}},[_vm._v("Gestion des critères d'évaluation interne")])]}}])})],1)]),_c('div',{staticClass:"col-lg-10 col-xl-8 ml-xl-5"},[_c('router-view')],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h5',{staticClass:"mt-4 mb-4 title-line title-line-left"},[_c('span',[_vm._v("Paramètres")])])}]

export { render, staticRenderFns }