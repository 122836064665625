




import { Component, Vue, Prop } from 'vue-property-decorator';
@Component
export default class Breadcrumb extends Vue {
  @Prop({ type: Number, default: undefined }) acmId?: number;
  @Prop({ required: true }) activeStep!: number;
  private breadcrumb: unknown[] = [];
  mounted() {
    this.breadcrumb = [
      {
        text: '1. Choisir un client',
        active: this.activeStep === 1,
        disabled: this.activeStep > 1,
        to: { name: 'AcmCreateSelectClient' }
      },
      {
        text: '2. Avant - propos',
        active: this.activeStep === 2,
        disabled: this.activeStep < 2,
        to: { name: 'AcmCreateEdit', params: { acmId: this.acmId ?? -1 } }
      },
      {
        text: '3. Préconisations',
        active: this.activeStep === 3,
        disabled: this.activeStep < 3,
        to: { name: 'AcmCreateEditStep2', params: { acmId: this.acmId ?? -1 } }
      },
      {
        text: '4. Details',
        active: this.activeStep === 4,
        disabled: this.activeStep < 4,
        to: { name: 'AcmCreateEdit', params: { acmId: this.acmId } }
      },
      {
        text: '5. Bordereau',
        active: this.activeStep === 5,
        disabled: this.activeStep < 5
      }
    ];
  }
}
