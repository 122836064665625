






















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Evaluation } from '@/models/evaluation';
import { InputType } from '@/models/inputtype';
import ProduitService from '@/services/produit';

@Component
export default class SettingsEvaluationModalAddEdit extends Vue {
  @Prop({ type: Object as () => Evaluation | undefined, default: undefined })
  readonly item?: Evaluation;

  nom = '';
  information = '';
  type: number | null = null;
  showDismissibleAlert = false;
  dismissSecs = 5;
  dismissCountDown = 0;
  inputType: InputType[] = [];

  get title() {
    return this.item === undefined ? 'Ajouter une évaluation de produit' : 'Modifier une évaluation de produit';
  }

  get options() {
    const data: { value: number | null; text: string }[] = Array.of({
      value: null,
      text: '-- Please select an option --'
    });
    return data.concat(
      this.inputType.map((item) => {
        return { value: item.id, text: item.nom };
      })
    );
  }

  mounted() {
    ProduitService.evaluationInputTypes().then((response) => {
      this.inputType = response;
    });
  }

  openModal() {
    // @ts-ignore
    this.$refs.modal.show();
    this.loadInputType();
  }

  close() {
    this.clearForm();
    this.dismissCountDown = 0;
    // @ts-ignore
    this.$refs.modal.hide();
  }

  fillForm() {
    if (this.item !== undefined) {
      this.nom = this.item.nom;
      this.information = this.item.information;
      this.type = this.item.inputType.id;
    }
  }

  clearForm() {
    this.nom = '';
    this.information = '';
    this.showDismissibleAlert = false;
  }

  handleOk(evt: Event) {
    // Prevent modal from closing
    evt.preventDefault();
    this.handleSubmit();
  }

  handleSubmit() {
    if (this.nom === '' || this.type === null) {
      this.showDismissibleAlert = true;
    } else {
      this.showDismissibleAlert = false;
      ProduitService.evaluationNew(this.nom, this.type, this.information, this.item ? this.item.id : undefined).then(
        (response) => {
          if (response.status === 202) {
            this.dismissCountDown = this.dismissSecs;
            this.$emit('evaluation-created');
          }
        }
      );
    }
  }

  countDownChanged(dismissCountDown: number) {
    this.dismissCountDown = dismissCountDown;
  }

  loadInputType() {
    ProduitService.loadInputType().then((response) => {
      this.inputType = response;
    });
  }

  @Watch('item')
  onItemChange(n?: Evaluation) {
    if (n === undefined) {
      this.clearForm();
    } else {
      this.fillForm();
    }
  }
}
