







import { Component, Vue } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';

@Component
export default class SalesForceCallbackOauth extends Vue {
  error = false;

  loginSuccessful() {
    this.error = false;
    console.log('SalesForceCallbackOauth - login');
    UserModule.login(this.$route.query.code as string);
    this.$router.replace((this.$route.query.redirect as string) || '/');
  }

  loginFailed() {
    UserModule.logout();
    this.error = true;
  }

  mounted() {
    console.log(this.$route.query.code);
    if (this.$route.query.code !== null || this.$route.query.code !== '') {
      /* axios
        .create({
          baseURL: `${process.env.VUE_APP_AUTH_USER_INFO_URI}`,
          headers: { authorization: `Bearer ${this.$route.query.code}` }
        })
        .get("")
        .then(response => {
          if (response.status === 200) { */
      this.loginSuccessful();
      /* }
        })
        .catch(error => {
          console.error(error);
          if (error.response.status === 400 || error.response.status === 403) {
            this.$router.push("/login");
          }
        }); */
    } else {
      this.$router.push('/login');
    }
  }
}
