import axios from './axios';
import { F5F } from '@/models/f5f';

class F5fService {
  async f5fForProduit(produitId: number): Promise<F5F[]> {
    return axios
      .get('/f5f', {
        params: { produit: produitId }
      })
      .then((response) => {
        return response.data as F5F[];
      });
  }

  async f5fEdit(f5f: F5F): Promise<F5F> {
    return axios.post('/f5f/' + f5f.id, f5f).then((response) => {
      return response.data as F5F;
    });
  }

  f5fUploadUrl(produitId: number): string {
    return axios.defaults.baseURL + '/f5f/new/' + produitId;
  }

  f5fDelete(f5f: { id: number }) {
    return axios.post('/f5f/' + f5f.id + '/delete', {});
  }
}

export default new F5fService();
