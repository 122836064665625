












































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
import F5fService from '@/services/f5f';
import FileUpload from 'vue-upload-component';
// @ts-ignore
import VuePdf from 'vue-pdf';
import VueXeditable from '@/components/elements/xeditable/XEditable.vue';
import { Produit } from '../../models';
import { F5F } from '../../models/f5f';

@Component({
  components: {
    FileUpload,
    VuePdf,
    'vue-xeditable': VueXeditable
  }
})
export default class F5fModal extends Vue {
  @Prop({ required: true, type: Object as () => Produit }) produit!: Produit;
  @Prop({ default: true, type: Boolean }) withSelector!: boolean;

  files = [];
  f5f: F5F[] = [];
  activeTab = 2;
  selected: number | null = null;
  uploadInProgress = false;
  errorF5F = [];

  get currentFile() {
    return this.produit.f5fId;
  }

  get uploadUrl() {
    return F5fService.f5fUploadUrl(this.produit.id);
  }

  get token() {
    return `Bearer ${UserModule.jwtToken}`;
  }

  @Watch('currentFile')
  onCurrentFileChange(after: number) {
    if (after !== null) {
      this.load().then(() => {
        this.activeTab = 2;
        this.files = [];
        this.selected = after;
      });
    } else {
      this.activeTab = 1;
    }
  }

  @Watch('uploadInProgress')
  onUploadInProgressChnage(after: boolean, before: boolean) {
    if (before && !after && this.files.length !== 0) {
      setTimeout(() => {
        this.$emit('empty', false);
        this.changeTab(2);
      }, 2000);
    }
  }

  @Watch('files', { deep: true })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handler(after: any[]) {
    if (after.length > 0) {
      if (!this.uploadInProgress && after[0].active) {
        this.uploadInProgress = true;
      } else if (!after[0].active && !after[0].error) {
        this.uploadInProgress = false;
      }
    } else {
      this.uploadInProgress = false;
    }
  }

  created() {
    this.load();
  }

  load() {
    return F5fService.f5fForProduit(this.produit.id).then((response) => {
      this.f5f = response;
      if (this.f5f.length > 0) {
        this.selected = this.f5f[this.f5f.length - 1].id;
        this.$emit('file-selected', this.selected);
      } else {
        this.$emit('empty', true);
      }
    });
  }

  open() {
    // @ts-ignore
    this.$refs.f5fModal.show();
  }

  changeTab(index: number) {
    if (index === 2) {
      this.load().then(() => {
        this.activeTab = index;
        this.files = [];
      });
    } else {
      this.activeTab = index;
    }
  }

  deleteFile(file: F5F) {
    this.files = this.files.filter((x) => x !== file);
  }

  deleteF5F(file: F5F) {
    F5fService.f5fDelete(file)
      .then(() => {
        this.load();
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          this.errorF5F = error.response.data;
          // @ts-ignore
          this.$refs.modalDeleteError.show();
        } else {
          throw error;
        }
      });
  }

  sendValueDidChangeEvent(f: F5F, $evt: string) {
    f.nom = $evt;
    F5fService.f5fEdit(f).then((response) => {
      // @ts-ignore
      this.$log.debug(response.data);
    });
  }

  okPressed() {
    this.$emit('file-selected', this.selected);
  }
}
