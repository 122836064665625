import axios from './axios';
import { Template, TemplateCategory, TemplateSubCategory } from '@/models/template';
import { Section } from '@/models/section';
import { DiagnosticStageName } from '@/models/diagnostic_stage';

class TemplateService {
  templateAll(includeAnnexTemplates?: boolean) {
    if (includeAnnexTemplates) return axios.get('/template');
    else return axios.get('/template?annexTemplates=false');
  }

  annexTemplates() {
    return axios.get('/template?annexTemplates=true');
  }

  templateById(templateId: number) {
    return axios.get('/template/' + templateId);
  }

  templateDefault(category: TemplateCategory, subcategory: TemplateSubCategory | DiagnosticStageName) {
    return axios.get(`/template/default?category=${category}&subcategory=${subcategory}`);
  }

  async templateNew(category: string, subcategory: string, name: string) {
    const response = await axios.get(`/template/new?category=${category}&subcategory=${subcategory}&name=${name}`);
    return response.data as Template;
  }

  async templateCreate(template: Template) {
    const response = await axios.post('/template/new', template);
    return response.data as Template;
  }

  async templateUpdate(templateId: number, template: Template) {
    const response = await axios.post('/template/' + templateId, template);
    return response.data as Template;
  }

  async templateDelete(templateId: number) {
    const response = await axios.post('/template/' + templateId + '/delete');
    return response.data as Array<Template>;
  }

  templateContentsByTemplateId(templateId: number) {
    return axios.get('/template/' + templateId + '/contents');
  }

  async templateContentsUpdate(templateId: number, contents: Section[]) {
    const response = await axios.post('/template/' + templateId + '/contents', contents);
    return response.data as Section[];
  }

  templateAnnexes(templateId: number) {
    return axios.get('/template/' + templateId + '/annexes');
  }

  async templateAnnexDelete(templateId: number, annexName: string) {
    const response = await axios.post(`/template/${templateId}/annexes/${annexName}/delete`);
    return response.data.deleted;
  }

  uploadTemplateImageUrl(templateId: number): string {
    return `${axios.defaults.baseURL}/template/${templateId}/uploadImage`;
  }

  uploadTemplateAnnexUrl(templateId: number): string {
    return `${axios.defaults.baseURL}/template/${templateId}/uploadAnnex`;
  }
}

export default new TemplateService();
