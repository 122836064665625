export default {
  concat<T>(x: T[], y: T[]): T[] {
    return x.concat(y);
  },
  flatMap<T, U>(xs: T[], f: (x: T) => U[]): U[] {
    return xs.map(f).reduce(this.concat, [] as U[]);
  },
  unique<T>(arr: T[]): T[] {
    const names = new Set();
    return arr.filter((item) => (!names.has(JSON.stringify(item)) ? names.add(JSON.stringify(item)) : false));
  },
  groupBy<T>(key: string, array: T[]) {
    return array.reduce((objectsByKeyValue, obj) => {
      // @ts-ignore
      const value = obj[key];
      // @ts-ignore
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});
  }
};
