var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-3"},[_c('b-btn',{staticClass:"btn-block",attrs:{"variant":"outline-primary","size":"lg","active-class":"false","to":{ name: 'Produit' }}},[_c('i',{staticClass:"pe-7s-angle-left pe-lg pe-va"}),_c('span',{staticClass:"txt-btn"},[_vm._v("Liste des produits")])])],1),_c('div',{staticClass:"col-3"},[_c('b-btn',{staticClass:"btn-block",attrs:{"variant":"outline-primary","size":"lg","to":{
          name: 'Selection',
          query: {
            p: _vm.panier
              .all()
              .map(function (x) { return x.id; })
              .join(',')
          }
        }}},[_c('i',{staticClass:"pe-7s-shopbag"}),_vm._v("Ma sélection actuelle "),_c('div',{staticClass:"hexagon pull-right"},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.panier.all().length))])])])],1)]),_vm._m(0),_c('Scrollable',{staticClass:"row selection-list",scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"col-12"},[(_vm.allSelections.length > 0)?_c('div',{staticClass:"row pt-4"},_vm._l((_vm.allSelections),function(selection){return _c('div',{key:selection.id,staticClass:"w-20 mb-4 px-3"},[_c('SelectionCard',{attrs:{"selection":selection,"with-close":true},on:{"onClose":function($event){return _vm.openModal(selection)}}}),_c('ModalConfirmDelete',{ref:'modalConfirm-' + selection.id,refInFor:true,on:{"eventDeleteSelection":function($event){return _vm.deleteSelection(selection)}}})],1)}),0):_c('div',{staticClass:"row pt-4"},[_c('div',{staticClass:"col-12 text-light-light"},[_vm._v("Aucune selection encore créée.")])])])]},proxy:true}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-4 mt-4"},[_c('h5',{staticClass:"text-uppercase title-line title-line-left"},[_c('span',[_vm._v("SELECTION EXISTENTES")])])])])}]

export { render, staticRenderFns }