import { TemplateCategory, TemplateSubCategory } from '../../models/template';
import { Placeholder } from '../../models/placeholder';
import { DiagnosticStageName } from '@/models/diagnostic_stage';

interface TemplateTreeNode {
  code: string;
  value: TemplateCategory | TemplateSubCategory | DiagnosticStageName;
  layout?: Placeholder[];
  subcategories?: TemplateTreeNode[];
}

const diagnosticTemplatesTree: TemplateTreeNode = {
  code: 'DIAGNOSTICPATRIMONIAL',
  value: TemplateCategory.DIAGNOSTICPATRIMONIAL,
  subcategories: [
    {
      code: 'AVANTPROPOS',
      value: DiagnosticStageName.AVANTPROPOS
    },
    {
      code: 'ANALYSE',
      value: DiagnosticStageName.ANALYSE,
      layout: [Placeholder.TITRE, Placeholder.BLOC_TEXTE, Placeholder.BLOC_TEXTE, Placeholder.AVANTAGES_RISQUES]
    },
    {
      code: 'CIVIL',
      value: DiagnosticStageName.CIVIL,
      layout: [Placeholder.TITRE, Placeholder.BLOC_TEXTE, Placeholder.BLOC_TEXTE, Placeholder.AVANTAGES_RISQUES]
    },
    {
      code: 'PATRIMONIAL',
      value: DiagnosticStageName.PATRIMONIAL,
      layout: [Placeholder.TITRE, Placeholder.BLOC_TEXTE, Placeholder.BLOC_TEXTE, Placeholder.AVANTAGES_RISQUES]
    },
    {
      code: 'ECONOMIQUE',
      value: DiagnosticStageName.ECONOMIQUE,
      layout: [Placeholder.TITRE, Placeholder.BLOC_TEXTE, Placeholder.BLOC_TEXTE, Placeholder.AVANTAGES_RISQUES]
    },
    {
      code: 'FISCAL',
      value: DiagnosticStageName.FISCAL,
      layout: [Placeholder.TITRE, Placeholder.BLOC_TEXTE, Placeholder.BLOC_TEXTE, Placeholder.AVANTAGES_RISQUES]
    },
    {
      code: 'SOCIAL',
      value: DiagnosticStageName.SOCIAL,
      layout: [Placeholder.TITRE, Placeholder.BLOC_TEXTE, Placeholder.BLOC_TEXTE, Placeholder.AVANTAGES_RISQUES]
    },
    {
      code: 'OBJECTIFS',
      value: DiagnosticStageName.OBJECTIFS,
      layout: [Placeholder.BLOC_TEXTE]
    }
  ]
};

const preconisationsTemplatesTree: TemplateTreeNode = {
  code: 'PRECONISATIONS',
  value: TemplateCategory.PRECONISATIONS,
  subcategories: [
    {
      code: 'CIVIL',
      value: DiagnosticStageName.CIVIL,
      layout: [
        Placeholder.TITRE,
        Placeholder.BLOC_TEXTE,
        Placeholder.BLOC_TEXTE,
        Placeholder.AVANTAGES_RISQUES,
        Placeholder.EQUILIBRE_FINANCIER_PRECONISATION
      ]
    },
    {
      code: 'PATRIMONIAL',
      value: DiagnosticStageName.PATRIMONIAL,
      layout: [
        Placeholder.TITRE,
        Placeholder.BLOC_TEXTE,
        Placeholder.BLOC_TEXTE,
        Placeholder.AVANTAGES_RISQUES,
        Placeholder.EQUILIBRE_FINANCIER_PRECONISATION
      ]
    },
    {
      code: 'ECONOMIQUE',
      value: DiagnosticStageName.ECONOMIQUE,
      layout: [
        Placeholder.TITRE,
        Placeholder.BLOC_TEXTE,
        Placeholder.BLOC_TEXTE,
        Placeholder.AVANTAGES_RISQUES,
        Placeholder.EQUILIBRE_FINANCIER_PRECONISATION
      ]
    },
    {
      code: 'FISCAL',
      value: DiagnosticStageName.FISCAL,
      layout: [
        Placeholder.TITRE,
        Placeholder.BLOC_TEXTE,
        Placeholder.BLOC_TEXTE,
        Placeholder.AVANTAGES_RISQUES,
        Placeholder.EQUILIBRE_FINANCIER_PRECONISATION
      ]
    },
    {
      code: 'SOCIAL',
      value: DiagnosticStageName.SOCIAL,
      layout: [
        Placeholder.TITRE,
        Placeholder.BLOC_TEXTE,
        Placeholder.BLOC_TEXTE,
        Placeholder.AVANTAGES_RISQUES,
        Placeholder.EQUILIBRE_FINANCIER_PRECONISATION
      ]
    }
  ]
};

const acmTemplatesTree: TemplateTreeNode = {
  code: 'ACM',
  value: TemplateCategory.ACM,
  subcategories: [
    {
      code: 'AVANTPROPOS',
      value: DiagnosticStageName.AVANTPROPOS
    },
    {
      code: 'PRECONISATIONS',
      value: TemplateSubCategory.PRECONISATIONS
    }
  ]
};

const bordereauxTemplatesTree = {
  code: 'BORDEREAUX',
  value: TemplateCategory.BORDEREAUX,
  subcategories: [
    {
      code: 'BORDEREAUDEREMISE',
      value: TemplateSubCategory.BORDEREAUDEREMISE
    },
    {
      code: 'SYNTHESEETVALIDATION',
      value: TemplateSubCategory.SYNTHESEETVALIDATION
    }
  ]
};

const annexesTemplatesTree: TemplateTreeNode = {
  code: 'ANNEXES',
  value: TemplateCategory.ANNEXES,
  subcategories: [
    {
      code: 'CIVIL',
      value: DiagnosticStageName.CIVIL,
      layout: [Placeholder.TITRE, Placeholder.BLOC_TEXTE, Placeholder.BLOC_TEXTE]
    },
    {
      code: 'PATRIMONIAL',
      value: DiagnosticStageName.PATRIMONIAL,
      layout: [Placeholder.TITRE, Placeholder.BLOC_TEXTE, Placeholder.BLOC_TEXTE]
    },
    {
      code: 'ECONOMIQUE',
      value: DiagnosticStageName.ECONOMIQUE,
      layout: [Placeholder.TITRE, Placeholder.BLOC_TEXTE, Placeholder.BLOC_TEXTE]
    },
    {
      code: 'FISCAL',
      value: DiagnosticStageName.FISCAL,
      layout: [Placeholder.TITRE, Placeholder.BLOC_TEXTE, Placeholder.BLOC_TEXTE]
    },
    {
      code: 'SOCIAL',
      value: DiagnosticStageName.SOCIAL,
      layout: [Placeholder.TITRE, Placeholder.BLOC_TEXTE, Placeholder.BLOC_TEXTE]
    },
    {
      code: 'F5F',
      value: TemplateSubCategory.F5F,
      layout: [Placeholder.TITRE, Placeholder.BLOC_TEXTE, Placeholder.BLOC_TEXTE]
    }
  ]
};

const templateCategoryTree: TemplateTreeNode[] = [
  diagnosticTemplatesTree,
  preconisationsTemplatesTree,
  acmTemplatesTree,
  bordereauxTemplatesTree,
  annexesTemplatesTree
];

const templateNoAnnexesCategoryTree: TemplateTreeNode[] = [
  diagnosticTemplatesTree,
  preconisationsTemplatesTree,
  acmTemplatesTree,
  bordereauxTemplatesTree
];

const annexesCategoryTree: TemplateTreeNode[] = [annexesTemplatesTree];

export { templateCategoryTree, templateNoAnnexesCategoryTree, annexesCategoryTree, TemplateTreeNode };
