


































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import TemplateService from '../../services/template';
import { Template, TemplateCategory, TemplateConstats, TemplateRiskLevel } from '../../models/template';
import { annexesCategoryTree, templateNoAnnexesCategoryTree, TemplateTreeNode } from '../elements/TemplateCategoryTree';
import { BCollapse } from 'bootstrap-vue';
import { DiagnosticStageName } from '@/models/diagnostic_stage';

@Component({
  components: {
    BCollapse
  },
  filters: {
    capitalize: (value: string) => {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    uppercase: (value: string) => {
      if (!value) return '';
      return value.toString().toUpperCase();
    },
    localeDate: (date: Date) => {
      return date.toLocaleString('fr-FR');
    }
  }
})
export default class TemplateSelect extends Vue {
  @Prop({ required: true }) annexTemplate!: boolean;
  @Prop({ required: true }) stageName!: DiagnosticStageName;
  templatesFlat: Array<Template> = [];
  templatesTree: { [key: string]: { [key: string]: Array<Template> } } = {};
  showSpinner = false;
  riskLevels = TemplateRiskLevel;
  search = '';
  selectedTemplateId: number | null = null;

  categoryTree: TemplateTreeNode[] = [];

  created() {
    this.showSpinner = true;
    let promise;
    if (this.annexTemplate) {
      this.categoryTree = annexesCategoryTree;
      promise = TemplateService.annexTemplates();
    } else {
      this.categoryTree = templateNoAnnexesCategoryTree;
      promise = TemplateService.templateAll(false);
    }

    promise.then((response) => {
      this.templatesFlat = response.data;
    });
    this.showSpinner = false;
  }

  @Watch('templatesFlat')
  updateTree(templates: Array<Template>) {
    this.templatesTree = {};
    templates.forEach((template: Template) => {
      const cat = template.category;
      const subcat = template.subcategory;
      if (this.templatesTree[cat] === undefined) {
        Vue.set(this.templatesTree, cat, {});
      }
      if (this.templatesTree[cat][subcat] === undefined) {
        Vue.set(this.templatesTree[cat], subcat, []);
      }

      this.templatesTree[cat][subcat].unshift(template); // Display templates by id
    });
  }

  @Watch('search')
  onSearchChange(query: string) {
    if (query === '') {
      this.updateTree(this.templatesFlat);
    } else {
      this.updateTree(
        this.templatesFlat.filter((template) => {
          const templateName = template.name.toLowerCase();
          return templateName.indexOf(query.toLowerCase()) !== -1;
        })
      );
    }
  }

  checkTemplate(template: Template, checked: boolean) {
    if (checked) {
      this.updateCheckboxes(template.id);
      this.selectedTemplateId = template.id;
      this.$emit('select-template', template);
    } else {
      this.selectedTemplateId = null;
      this.$emit('select-template', null);
    }
  }

  updateCheckboxes(id: number) {
    // @ts-ignore
    (document.querySelectorAll(
      `input[type='checkbox'][data-check-template]:not([data-check-template='${id}'])`
    ) as HTMLInputElement[]).forEach((node) => (node.checked = false));
  }

  isConstat(category: TemplateTreeNode, subcategory: TemplateTreeNode) {
    return (
      category.value === TemplateCategory.DIAGNOSTICPATRIMONIAL &&
      TemplateConstats.includes(subcategory.value as DiagnosticStageName)
    );
  }
}
