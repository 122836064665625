import { Conseiller } from './conseiller';
import { DiagnosticStageName } from './diagnostic_stage';

export enum TemplateCategory {
  DIAGNOSTICPATRIMONIAL = 'DIAGNOSTICPATRIMONIAL',
  PRECONISATIONS = 'PRECONISATIONS',
  ACM = 'ACM',
  BORDEREAUX = 'BORDEREAUX',
  ANNEXES = 'ANNEXES'
}
export enum TemplateSubCategory {
  PRECONISATIONS = 'PRECONISATIONS',

  BORDEREAUDEREMISE = 'BORDEREAUDEREMISE',
  SYNTHESEETVALIDATION = 'SYNTHESEETVALIDATION',
  F5F = 'F5F'
}
export const TemplateConstats: Array<DiagnosticStageName> = [
  DiagnosticStageName.CIVIL,
  DiagnosticStageName.PATRIMONIAL,
  DiagnosticStageName.ECONOMIQUE,
  DiagnosticStageName.FISCAL,
  DiagnosticStageName.SOCIAL
];
export enum TemplateRiskLevel {
  NEGATIF = 'NEGATIF',
  NEUTRE = 'NEUTRE',
  POSITIF = 'POSITIF'
}

export interface Template {
  id: number;
  lastUpdate: Date | string;
  category: TemplateCategory;
  subcategory: TemplateSubCategory | DiagnosticStageName;
  name: string;
  conseiller: Conseiller;
  contentsQuantity: number;
  defaultTemplate: boolean;
  riskLevel?: TemplateRiskLevel;
}
