































import { Component, Vue } from 'vue-property-decorator';
import OrganisationService from '@/services/organisation';

@Component
export default class ModalCreateCompagnie extends Vue {
  nom = '';
  address = '';
  email = '';
  telephone = '';
  site = '';

  openModal() {
    // @ts-ignore
    this.$refs.modalCreateCompagnie.show();
  }

  resetModal() {
    this.nom = '';
    this.address = '';
    this.email = '';
    this.telephone = '';
    this.site = '';
  }

  handleOk(evt: Event) {
    evt.preventDefault();
    this.handleSubmit();
  }

  handleSubmit() {
    OrganisationService.organisationCreate(this.nom, this.address, this.email, this.telephone, this.site).then(
      (response) => {
        if (response.status === 202) {
          this.$emit('organisation-created');
          this.resetModal();
          // @ts-ignore
          this.$refs.modalCreateCompagnie.hide();
        }
      }
    );
  }
}
