








































































import { Component, Vue, Prop } from 'vue-property-decorator';
import AcmService from '@/services/acm';
import SelectionService from '@/services/selection';
import Scrollable from '@/components/elements/Scrollable.vue';
import Breadcrumb from '@/components/acm/Breadcrumb.vue';
import { Selection } from '../../models/selection';
import { AcmPreconisation } from '../../models/acmPreconisation';

@Component({
  components: {
    Scrollable,
    Breadcrumb
  }
})
export default class AcmCreatePreconisationSelection extends Vue {
  @Prop({ required: true }) acmId!: number;
  @Prop({ required: true }) acmPrecoId!: number;

  allSelections: Selection[] = [];
  selectionToRender: Selection[] = [];
  preconisation: AcmPreconisation[] = [];

  created() {
    this.load();
  }

  load() {
    SelectionService.selectionAll().then((response) => {
      this.allSelections = response;
      this.selectionByCategorieType();
    });
    AcmService.acmPreconisation(this.acmId).then((response) => {
      this.preconisation = response;
    });
  }

  selectionByCategorieType() {
    /*
      const categoriesTypesPreconisation = []
      this.preconisation.forEach(preco => {
        categoriesTypesPreconisation.push(preco.preconisation.categorieTypes)
      })
      */
    const categoriesTypesPreconisation = this.preconisation
      .map((preco) => {
        return preco.preconisation.categorieTypes;
      })
      .flat();
    /*
      this.allSelections.forEach(selection => {
        const selectionHasProduits = selection.selectionHasProduit
        selectionHasProduits.forEach(produit => {
          categoriesTypesPreconisation.forEach(categorie => {
            console.log(categorie.id)
            console.log('id produit -> ' + produit.produit.categorieProduit.categorieType.id)
            if (categorie.id === produit.produit.categorieProduit.categorieType.id) {
              this.selectionToRender.push(selection)
            }
          })
        })
      })
      */
    this.selectionToRender = this.allSelections.filter((selection) => {
      const categorieProduitIds = [
        ...new Set(selection.selectionHasProduit.map((produit) => produit.produit.categorieProduit.categorieType.id))
      ];
      return categoriesTypesPreconisation.find((categorie) => categorieProduitIds.includes(categorie.id)) !== undefined;
    });
  }

  onSelected(selection: Selection) {
    AcmService.acmSavePreconisationProduit(
      this.acmId,
      this.acmPrecoId,
      selection.selectionHasProduit.map((x) => x.produit)
    ).then(() => {
      this.$router.push({
        name: 'AcmCreatePreconisation',
        params: { acmId: `${this.acmId}`, acmPrecoId: `${this.acmPrecoId}` }
      });
    });
  }
}
