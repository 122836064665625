
































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Section } from '@/models/section';
import { Placeholder } from '@/models/placeholder';
import moment from 'moment';
import Body from './Body.vue';
import { Conseiller } from '@/models/conseiller';
import { Definition } from '@/models/dictionary';
import ConseillerService from '@/services/conseiller';

@Component({
  components: {
    Body
  },
  filters: {
    dateFormat: (dateStr: string) => {
      return moment(dateStr).format('[Le ]dddd LL[.]');
    }
  }
})
export default class AvantPropos extends Vue {
  @Prop({ required: false }) definitions?: Definition[];
  @Prop({ required: true }) contents!: Section[];
  @Prop({ required: false }) signatures?: string[];

  defaultSignatures: {
    conseiller: Conseiller;
    signature: string;
    url: string;
  }[] = [];

  mounted() {
    ConseillerService.me().then((myself: Conseiller) => {
      this.defaultSignatures = [
        {
          conseiller: myself,
          signature: myself.signature,
          url: myself.signatureUrl
        }
      ];
    });
  }

  get avantProposContents() {
    return this.contents.filter(
      (c, index) => !(index < 2 && [Placeholder.LIEU, Placeholder.DATE].includes(c.placeholder))
    );
  }

  get avantProposLieu() {
    return this.contents.find((c, index) => index < 2 && c.placeholder === Placeholder.LIEU);
  }

  get avantProposDate() {
    return this.contents.find((c, index) => index < 2 && c.placeholder === Placeholder.DATE);
  }
}
