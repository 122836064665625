





















































































































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import AcmService from '@/services/acm';
import Breadcrumb from '@/components/acm/Breadcrumb.vue';
import F5fModal from '@/components/produit/F5fModal.vue';
import MarkdownEditor from '@/utils/MarkdownEditor.vue';
import ModalComparatorACM from './ModalComparatorACM.vue';
import { AcmPreconisation } from '../../models/acmPreconisation';
import { Produit } from '../../models';
import { Acm } from '../../models/acm';

@Component({
  components: {
    Breadcrumb,
    F5fModal,
    MarkdownEditor,
    ModalComparatorACM
  }
})
export default class AcmCreatePreconisation extends Vue {
  @Prop({ required: true }) acmId!: number;
  @Prop({ required: true }) acmPrecoId!: number;

  acm: Acm | null = null;
  recommander: number | null = null;
  preconisation: AcmPreconisation | null = null;
  allPreconisation: AcmPreconisation[] = [];
  produit: Produit[] = [];
  loading = false;
  edited = false;

  created() {
    this.init();
  }

  init() {
    this.produit = [];
    this.loading = true;
    AcmService.acmById(this.acmId)
      .then((response) => {
        this.acm = response;
        AcmService.acmPreconisation(this.acmId)
          .then((response) => {
            this.allPreconisation = response;
            this.preconisation = response.find((e) => e.id === this.acmPrecoId) || null;
            this.produit =
              this.preconisation?.produits.map((p) => {
                p.produit.F5FEmpty = false;
                p.produit.recommander = p.recommandation;
                p.produit.enabled = p.enabled;
                if (p.f5f !== undefined) {
                  p.produit.f5fId = p.f5f.id;
                }
                return p.produit;
              }) || [];
            const reco = this.produit.find((e) => e.recommander);
            if (reco !== undefined) {
              this.recommander = reco.id;
            }
          })
          .then(() => {
            this.loading = false;
          });
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.$router.push({ name: 'AcmList' });
        }
      });
  }

  alertIfEdited() {
    if (this.edited) {
      // @ts-ignore
      this.$refs.modalConfirm.show();
    } else {
      this.modalValidate();
    }
  }

  modalValidate() {
    const index = this.allPreconisation.map((e) => e.id).indexOf(this.acmPrecoId);
    if (index === 0) {
      this.$router.push({
        name: 'AcmCreateEditStep2',
        params: { acmId: `${this.acmId}` }
      });
    } else if (this.acm) {
      this.$router.push({
        name: 'AcmCreatePreconisation',
        params: {
          acmId: `${this.acm.id}`,
          acmPrecoId: `${this.allPreconisation[index - 1].id}`
        }
      });
    }
  }

  modalCancel() {
    // @ts-ignore
    this.$refs.modalConfirm.hide();
  }

  saveBeforeNext() {
    if (this.edited && this.preconisation) {
      AcmService.acmSavePreconisationContent(this.acmId, this.acmPrecoId, this.preconisation).then(() => {
        AcmService.acmSavePreconisationProduit(this.acmId, this.acmPrecoId, this.produit).then(() => {
          this.nextPage();
        });
      });
    } else {
      this.nextPage();
    }
  }

  nextPage() {
    const index = this.allPreconisation.map((e) => e.id).indexOf(this.acmPrecoId);
    if (this.acm && index + 1 < this.allPreconisation.length) {
      this.$router.push({
        name: 'AcmCreatePreconisation',
        params: {
          acmId: `${this.acm.id}`,
          acmPrecoId: `${this.allPreconisation[index + 1].id}`
        }
      });
    } else if (this.acm) {
      this.$router.push({
        name: 'AcmCreateEditLastPage',
        params: { acmId: `${this.acm.id}` }
      });
    }
  }

  onRecommanderChange(b: number, p: Produit) {
    this.onChange();
    p.recommander = true;
    p.enabled = true;
    this.produit
      .filter((p) => p.id !== b)
      .forEach((p) => {
        p.recommander = false;
      });
  }

  filterProduct(p: Produit) {
    this.produit = this.produit.filter((x) => x.id !== p.id);
    this.edited = true;
  }

  onChange() {
    if (!this.loading) {
      this.edited = true;
    }
  }

  openF5FModal(p: Produit) {
    // @ts-ignore
    this.$refs['f5fModal-' + p.id][0].open();
  }

  fileSelected(produit: Produit, data?: number | boolean) {
    if (data === false) {
      data = undefined;
    }
    if (produit.f5fId !== data) {
      this.onChange();
    }
    produit.f5fId = data as number | undefined;
  }

  onF5FEmpty(p: Produit, isEmpty: boolean) {
    p.recommander = false;
    p.enabled = false;
    p.F5FEmpty = isEmpty;
    if (this.produit.map((x) => x.recommander).every((value) => value === false)) {
      this.recommander = null;
    }
    this.$forceUpdate();
  }

  @Watch('$route')
  onRouteChange() {
    this.init();
  }
}
