

















































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import moment from 'moment';
import { Account } from '@/models/account';
import AccountService from '@/services/account';
import SalesforceService from '@/services/salesforce';
import { diagnosticService } from '@/services/diagnostic';
import { Contact } from '@/models/contact';
import { DiagnosticStageName } from '@/models/diagnostic_stage';
import { DiagnosticConstat } from '@/models/diagnostic_constat';
import { DiagnosticWorkingState } from '@/models/diagnostic_working_state';
import { Placeholder } from '../../models/placeholder';
import { Diagnostic } from '@/models/diagnostic';
// @ts-ignore
import draggable from 'vuedraggable';
import ClientService from '@/services/clients';

@Component({
  components: {
    draggable
  },
  filters: {
    dateFormat: (dateStr: string) => {
      return moment(dateStr).format('[Le ]LL[ à ]LT');
    },
    dateFormatShort: (dateStr: string) => {
      return moment(dateStr).format('DD[.]MM[.]YYYY');
    }
  }
})
export default class AccountOne extends Vue {
  @Prop({ required: true }) accountId!: number;

  account: Account | null = null;
  history: string[] = [];
  fullHistory: string[] = [];
  isMore = false;
  loading = false;
  pdfInfo = 'Aucun PDF généré';
  diagnostic: Diagnostic | null = null;
  accountDiagnosticPatrimonial = false;
  selectedBilanIndex = 0;
  bilansStageName = DiagnosticStageName.BILANS;
  userSortedContacts: Contact[] = [];

  workingStates = DiagnosticWorkingState;

  mounted() {
    AccountService.accountById(this.accountId)
      .then((response) => {
        this.account = response.data;
        this.diagnostic = response.data.diagnostic;
        this.initUserSortedContacts();

        SalesforceService.contactsSync(this.accountId).then(() => {
          AccountService.accountById(this.accountId).then((response) => {
            this.account = response.data;
            this.initUserSortedContacts();
          });
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          this.$router.push({ name: 'AccountList' });
        } else {
          console.error(error);
        }
      });
  }

  get salesForceAccountUrl() {
    if (this.account === null) return '';
    return `${process.env.VUE_APP_SALESFORCE_DOMAIN}/lightning/r/Account/${this.account.salesforceId}/view`;
  }

  salesForceContactUrl(contact: Contact) {
    return `${process.env.VUE_APP_SALESFORCE_DOMAIN}/lightning/r/Contact/${contact.salesForceContactId}/view`;
  }

  get sortedContacts() {
    if (!this.account) return [];
    return this.account.contacts.sort((c1, c2) => c1.contactIndex - c2.contactIndex);
  }

  initUserSortedContacts() {
    if (this.account) this.userSortedContacts = this.account.contacts;
  }

  get userSortedContactsChanged() {
    if (!this.account) return false;
    const a = this.account.contacts.map((c) => c.contactIndex).join();
    const b = this.userSortedContacts.map((c) => c.contactIndex).join();
    return a !== b;
  }

  saveSortedContacts() {
    this.userSortedContacts.forEach((c, i) => (c.contactIndex = i + 1));
    ClientService.reorder(this.userSortedContacts).then((contacts) => {
      if (this.account) this.account.contacts = contacts;
      this.initUserSortedContacts();
    });
  }

  deleteDiagnostic() {
    if (!this.account) return;
    diagnosticService.diagnosticDelete(this.account.id).then((account: Account) => {
      this.account = account;
      this.diagnostic = null;
    });
  }

  get diagnosticBilans() {
    if (!this.diagnostic || !this.diagnostic.constats || this.diagnostic.constats.length === 0) return [];

    const bilansStages = [
      DiagnosticStageName.CIVIL,
      DiagnosticStageName.PATRIMONIAL,
      DiagnosticStageName.SOCIAL,
      DiagnosticStageName.ECONOMIQUE,
      DiagnosticStageName.FISCAL
    ];
    return bilansStages
      .filter((stageName) => {
        return (
          this.diagnostic &&
          this.diagnostic.constats &&
          this.diagnostic.constats.filter((constat) => constat.stageName === stageName).length
        );
      })
      .map((stageName) => {
        let constats: DiagnosticConstat[];
        if (this.diagnostic && this.diagnostic.constats)
          constats = this.diagnostic.constats.filter((constat) => constat.stageName === stageName);
        else constats = [];

        return { stageName, constats };
      });
  }

  get selectedBilan() {
    return this.diagnosticBilans[this.selectedBilanIndex];
  }

  constatTitle(constat: DiagnosticConstat) {
    if (!this.diagnostic) return null;
    const titleContent = this.diagnostic.contents?.find(
      (content) => content.constat && content.constat.id === constat.id && content.placeholder === Placeholder.TITRE
    );
    return titleContent ? titleContent.content : null;
  }

  workingStateStyle(workingState: DiagnosticWorkingState) {
    switch (workingState) {
      case DiagnosticWorkingState.BROUILLON:
        return 'text-muted';
      case DiagnosticWorkingState.A_MODIFIER:
        return 'text-warning';
      case DiagnosticWorkingState.VALIDE:
      case DiagnosticWorkingState.PRESENTE:
        return 'text-success';
    }
    return 'text-primary';
  }
}
