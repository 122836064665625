
































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { minValue, maxValue } from 'vuelidate/lib/validators';
import { Validations } from 'vuelidate-property-decorators';

@Component
export default class BoundaryInput extends Vue {
  @Prop({ type: String, default: '0|10' }) value!: string;
  @Prop({ type: Boolean, default: false }) error!: boolean;
  @Prop({ type: Boolean, default: false }) noClose!: boolean;

  data = this.value.split('|');
  modelMin = parseFloat(this.data[0]);
  modelMax = parseFloat(this.data[1]);
  confirm = false;

  @Validations()
  private validations = {
    modelMin: {
      lessThanMax: maxValue(this.modelMax)
    },
    modelMax: {
      greaterThanMin: minValue(this.modelMin)
    }
  };

  emits() {
    this.$emit('input', this.modelMin + '|' + this.modelMax);
    this.$emit('error', this.$v.$invalid);
  }

  @Watch('value')
  onValueChange(n: string) {
    const data = n.split('|');
    this.modelMin = parseInt(data[0]);
    this.modelMax = parseInt(data[1]);
  }

  @Watch('modelMin')
  onModelMin() {
    this.emits();
  }

  @Watch('modelMax')
  onModelMax() {
    this.emits();
  }
}
