




import { Component, Vue, Prop } from 'vue-property-decorator';
@Component
export default class Breadcrumb extends Vue {
  @Prop({ type: Number, required: false }) id!: number | undefined;
  @Prop({ type: Number, required: true }) activeStep!: number;

  breadcrumb = [
    {
      text: '1. Catégoriser le produit',
      active: this.activeStep === 1,
      disabled: this.activeStep < 1,
      to: { name: 'ProduitCreateSelectCategorie' }
    },
    {
      text: '2. Choisir une compagnie',
      active: this.activeStep === 2,
      disabled: this.activeStep < 2,
      to: {
        name: 'ProduitCreateAssociateOrganisation',
        params: { id: this.id }
      }
    },
    {
      text: '3. Décrire le produit',
      active: this.activeStep === 3,
      disabled: this.activeStep < 3
    }
  ];
}
