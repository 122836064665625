


































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import AcmMasterService from '../../services/acmmaster';
import AcmService from '../../services/acm';
import FormInputAppend from '@/components/elements/form-input-append.vue';
// @ts-ignore
import Datepicker from 'vuejs-datepicker';
// @ts-ignore
import { fr } from 'vuejs-datepicker/dist/locale';
import Breadcrumb from '@/components/acm/Breadcrumb.vue';
import MarkdownEditor from '@/utils/MarkdownEditor.vue';
import { Acm } from '../../models/acm';

@Component({
  components: {
    Datepicker,
    FormInputAppend,
    Breadcrumb,
    MarkdownEditor
  }
})
export default class AcmCreateEditLastPage extends Vue {
  @Prop({ required: true }) acmId!: number;

  acm: Acm | null = null;
  acmData = {
    content: '',
    lieu: '',
    date: new Date() as Date | string
  };

  defaultContent = '';
  loading = false;
  edited = false;

  datepickerLanguage = fr;

  created() {
    this.loading = true;
    AcmService.acmById(this.acmId)
      .then((response) => {
        this.acm = response;
        if (this.acm.bordereau !== undefined) {
          this.acmData.content = this.acm.bordereau;
        } else {
          //   const clientName = this.acm.clientName || 'Monsieur et/ou Madame ' + this.acm.contact.nom + ' ' + this.acm.contact.prenom
          //   this.acmData.content = clientName + this.defaultContent
          AcmMasterService.getAcmMaster().then((response) => {
            const clientName =
              this.acm?.clientName || 'Monsieur et/ou Madame ' + this.acm?.contact.prenom + ' ' + this.acm?.contact.nom;
            this.acmData.content = clientName + ',' + '\n' + response.bordereauMaster;
            this.defaultContent = ',\n' + response.bordereauMaster;
          });
        }
        this.acmData.lieu = this.acm.place || 'Tours';
        this.acmData.date = this.acm.date || new Date();
      })
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.$router.push({ name: 'AcmList' });
        }
      });
  }

  alertIfEdited() {
    if (this.edited) {
      // @ts-ignore
      this.$refs.modalConfirm.show();
    } else {
      this.modalValidate();
    }
  }

  modalValidate() {
    this.$router.go(-1);
  }

  modalCancel() {
    // @ts-ignore
    this.$refs.modalConfirm.hide();
  }

  saveBeforeNext() {
    if (this.edited) {
      AcmService.acmSaveBordereau(this.acmId, this.acmData.content).then(() => {
        this.$router.push({
          name: 'AcmCreated',
          params: { acmId: `${this.acmId}` }
        });
      });
    } else {
      this.$router.push({
        name: 'AcmCreated',
        params: { acmId: `${this.acmId}` }
      });
    }
  }

  defaultText() {
    const clientName =
      this.acm?.clientName || 'Monsieur et/ou Madame ' + this.acm?.contact.nom + ' ' + this.acm?.contact.prenom;
    this.acmData.content = clientName + this.defaultContent;
  }

  onChange() {
    if (!this.loading) {
      this.edited = true;
    }
  }
}
