




import { Component, Vue } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
@Component
export default class Logout extends Vue {
  created() {
    UserModule.logout();
    this.$router.push('/login');
  }
}
