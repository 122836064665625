




































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Caracteristique } from '../../../models/caracteristique';
import { InputType } from '../../../models/inputtype';
import { CategorieType } from '../../../models/categorietype';
import ProduitService from '@/services/produit';

@Component
export default class SettingsCaracteristiqueModalAddEdit extends Vue {
  @Prop({
    type: Object as () => Caracteristique | undefined,
    default: undefined
  })
  readonly item: Caracteristique | undefined;

  nom = '';
  information = '';
  caracteristique: number | null = null;
  inputType: InputType[] = [];
  showDismissibleAlert = false;
  dismissSecs = 5;
  dismissCountDown = 0;
  categorieSelected: number[] = [];
  categorieType: CategorieType[] = [];
  inputCustomValues: { value: string | null }[] = [{ value: null }];

  get title() {
    return this.item === undefined ? 'Ajouter une sous categorie de produit' : 'Modifier une sous categorie de produit';
  }

  get options() {
    const data: { value: number | null; text: string }[] = Array.of({
      value: null,
      text: '-- Please select an option --'
    });
    return data.concat(
      this.inputType.map((item) => {
        return { value: item.id, text: item.nom };
      })
    );
  }

  mounted() {
    ProduitService.loadCategorieType().then((response) => {
      this.categorieType = response;
    });
  }

  openModal() {
    // @ts-ignore
    this.$refs.modal.show();
    this.loadInputType();
  }

  close() {
    this.dismissCountDown = 0;
    // @ts-ignore
    this.$refs.modal.hide();
  }

  fillForm() {
    if (this.item !== undefined) {
      this.nom = this.item.nom;
      this.information = this.item.information;
      this.caracteristique = this.item.inputType.id;
      this.categorieSelected = this.item.categorieTypes.map((ctype) => ctype.id);
      if (this.item.inputCustomValues) {
        this.inputCustomValues = [];
        this.item.inputCustomValues.split('**').forEach((value) => {
          this.inputCustomValues.push({ value: value });
        });
      }
    }
  }

  clearForm() {
    this.nom = '';
    this.information = '';
    this.showDismissibleAlert = false;
    this.categorieSelected = [];
    this.caracteristique = null;
    this.inputCustomValues = [{ value: null }];
  }

  handleOk(evt: Event) {
    // Prevent modal from closing
    evt.preventDefault();
    this.handleSubmit();
  }

  handleSubmit() {
    if (this.nom === '' || this.caracteristique === null || this.categorieSelected === []) {
      this.showDismissibleAlert = true;
    } else {
      this.showDismissibleAlert = false;
      ProduitService.produitCreateCaracteristique(
        this.nom,
        this.caracteristique,
        this.information,
        this.item ? this.item.id : undefined,
        this.categorieSelected,
        this.inputCustomValues.filter((v) => v.value !== null) as {
          value: string;
        }[]
      ).then((response) => {
        if (response.status === 202) {
          this.dismissCountDown = this.dismissSecs;
          this.$emit('caracteristique-created');
        }
      });
    }
  }

  countDownChanged(dismissCountDown: number) {
    this.dismissCountDown = dismissCountDown;
  }

  loadInputType() {
    ProduitService.loadInputType().then((response) => {
      this.inputType = response;
    });
  }

  onAddInputCustomValue() {
    this.inputCustomValues.push({ value: null });
  }

  onRemoveInputCustomValue(index: number) {
    this.inputCustomValues.splice(index, 1);
  }

  @Watch('item')
  onItemchange(n?: Caracteristique) {
    if (n === undefined) {
      this.clearForm();
    } else {
      this.fillForm();
    }
  }
}
