









































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import TemplateService from '../../services/template';
import { Template, TemplateRiskLevel, TemplateCategory, TemplateConstats } from '../../models/template';
import { templateCategoryTree, TemplateTreeNode } from '../elements/TemplateCategoryTree';
import { BCollapse } from 'bootstrap-vue';
import { TemplatesIdsHistory } from '@/components/templates_history';
import { DiagnosticStageName } from '@/models/diagnostic_stage';

@Component({
  components: {
    BCollapse
  },
  filters: {
    capitalize: (value: string) => {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    uppercase: (value: string) => {
      if (!value) return '';
      return value.toString().toUpperCase();
    },
    localeDate: (date: Date) => {
      return date.toLocaleString('fr-FR');
    }
  }
})
export default class TemplateList extends Vue {
  templatesFlat: Array<Template> = [];
  templatesTree: { [key: string]: { [key: string]: Array<Template> } } = {};
  templatesHistory: Array<Template> = [];
  showSpinner = false;
  categoryTree = templateCategoryTree;
  riskLevels = TemplateRiskLevel;
  search = '';
  scrollPosition = 0;

  mounted() {
    this.showSpinner = true;
    TemplateService.templateAll(true).then((response) => {
      this.templatesFlat = response.data;
    });
    this.showSpinner = false;

    window.addEventListener('scroll', this.updateScroll);
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScroll);
  }

  updateScroll() {
    this.scrollPosition = window.scrollY;
  }

  @Watch('templatesFlat')
  updateTree(templates: Array<Template>) {
    this.templatesTree = {};
    templates.forEach((template: Template) => {
      const cat = template.category;
      const subcat = template.subcategory;
      if (this.templatesTree[cat] === undefined) {
        Vue.set(this.templatesTree, cat, {});
      }
      if (this.templatesTree[cat][subcat] === undefined) {
        Vue.set(this.templatesTree[cat], subcat, []);
      }

      this.templatesTree[cat][subcat].push(template); // Display templates by id
      this.templatesTree[cat][subcat].sort((a, b) => {
        if (a.name.startsWith(this.$t('templates.newTemplate').toString())) return 1;
        if (b.name.startsWith(this.$t('templates.newTemplate').toString())) return -1;
        return a.id - b.id;
      });
    });
  }

  @Watch('search')
  onSearchChange(query: string) {
    if (query === '') {
      this.updateTree(this.templatesFlat);
    } else {
      this.updateTree(
        this.templatesFlat.filter((template) => {
          const templateName = template.name.toLowerCase();
          return templateName.indexOf(query.toLowerCase()) !== -1;
        })
      );
    }
  }

  @Watch('templatesFlat')
  updateHistory() {
    this.templatesHistory = [];
    TemplatesIdsHistory.all().forEach((id: number) => {
      const t = this.templatesFlat.find((t) => t.id === id);
      if (t) this.templatesHistory.push(t);
    });
  }

  registerHistory(e: Event) {
    if (this.search === '') return;

    const eventTarget = e.target as HTMLElement;
    const clickedTemplate: Template | undefined = this.templatesFlat.find(
      (t) => t.id === parseInt(eventTarget.dataset.templateId + '')
    );

    if (clickedTemplate) TemplatesIdsHistory.add(clickedTemplate);
  }

  removeTemplate(templateId: number) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const templateNode = document.querySelector(`#template-${templateId}`)!;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    templateNode.querySelector('.trash')!.toggleAttribute('hidden');
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    templateNode.querySelector('.spinner')!.toggleAttribute('hidden');
    TemplateService.templateDelete(templateId).then((templates) => {
      this.templatesFlat = templates;
    });
  }

  isConstat(category: TemplateTreeNode, subcategory: TemplateTreeNode) {
    return (
      category.value === TemplateCategory.DIAGNOSTICPATRIMONIAL &&
      TemplateConstats.includes(subcategory.value as DiagnosticStageName)
    );
  }
}
