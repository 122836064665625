


















































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { DiagnosticStageName } from '@/models/diagnostic_stage';
import { Placeholder } from '@/models/placeholder';
import Body from './Body.vue';
import { DiagnosticConstat } from '@/models/diagnostic_constat';
import { Section } from '@/models/section';
import { Definition } from '@/models/dictionary';
import { DiagnosticAnnex } from '@/models/diagnostic_annex';
import PdfViewer from '@/components/elements/PdfViewer.vue';

@Component({
  components: {
    Body,
    PdfViewer
  }
})
export default class Bilan extends Vue {
  @Prop({ required: false }) definitions?: Definition[];
  @Prop({ required: false }) constats?: DiagnosticConstat[];
  @Prop({ required: true }) stageName!: DiagnosticStageName;
  @Prop({ required: false }) constatBaseIndex?: number;
  @Prop({ required: true }) contents!: Section[];
  @Prop({ required: true }) bilanAnnexes!: DiagnosticAnnex[];

  mounted() {
    const totalConstats = this.constats ? this.constats.length : 1;
    const totalPageBreaks = this.$el.querySelectorAll('.page-break').length;
    const totalPages = totalConstats + totalPageBreaks;
    this.$emit('update-sommaire', this.stageName, 'total', totalPages);
    this.updateSommaire();
  }

  updateSommaire() {
    const titlesAndPageBreaks = Array.from(this.$el.querySelectorAll('h2, .page-break'));
    if (titlesAndPageBreaks.length === 0) return;

    titlesAndPageBreaks.forEach((element, index) => {
      if (element.tagName !== 'H2') return;
      const pageBreaksBeforeTitle = titlesAndPageBreaks.filter(
        (element2, index2) => element2.classList.contains('page-break') && index2 < index
      ).length;
      const title = element.textContent ? element.textContent.replace(/^\s*Constat \d\s*: /, '') : '';
      this.$emit('update-sommaire', this.stageName, title, pageBreaksBeforeTitle);
    });
  }

  get bilanLogo() {
    switch (this.stageName) {
      case DiagnosticStageName.CIVIL:
        return require('@/assets/images/livrable/Bilan Logo Titre Bilan Civil.png');
      case DiagnosticStageName.ECONOMIQUE:
        return require('@/assets/images/livrable/Bilan Logo Titre Bilan Economique.png');
      case DiagnosticStageName.FISCAL:
        return require('@/assets/images/livrable/Bilan Logo Titre Bilan Fiscal.png');
      case DiagnosticStageName.PATRIMONIAL:
        return require('@/assets/images/livrable/Bilan Logo Titre Bilan Patrimonial.png');
      case DiagnosticStageName.SOCIAL:
        return require('@/assets/images/livrable/Bilan Logo Titre Social.png');
    }
    return '';
  }

  constatContents(constat: DiagnosticConstat) {
    return this.contents.filter((c) => c.constat && c.constat.id === constat.id);
  }

  constatTitlePredicate = (content: Section, index: number): boolean => {
    return index === 0 && content.placeholder === Placeholder.TITRE;
  };

  constatTitle(contents: Section[]): Section | undefined {
    return contents.find(this.constatTitlePredicate);
  }

  constatBody(contents: Section[]): Section[] {
    return contents.filter((c, i) => !this.constatTitlePredicate(c, i));
  }

  get bilanSubtitle(): string | null {
    const key = `diagnostic.livrable.soustitre.${this.stageName}`;
    if (!this.$te(key)) return null;
    return this.$t(key).toString();
  }

  bilanAnnexesByConstat(constatId: number) {
    return this.bilanAnnexes.filter((a) => a.constat && a.constat.id === constatId);
  }

  getAnnexFiletype = (name: string): string => {
    if (name.match(/\.(jpg|jpeg|gif|png|webp)$/)) {
      return 'image';
    }
    if (name.match(/\.(doc|docx|pdf|html)$/)) {
      return 'document';
    }
    return 'unknown';
  };
}
