












import { Component, Vue, Prop } from 'vue-property-decorator';
@Component
export default class BackgroundVideo extends Vue {
  @Prop({ type: Array, required: true }) sources!: [];
  @Prop({ type: Boolean, default: false }) autoplay!: boolean;
  @Prop({ type: Boolean, default: false }) loop!: boolean;
  @Prop({ type: Boolean, default: true }) muted!: boolean;
  @Prop({ type: Boolean, default: false }) playsinline!: boolean;
  @Prop({ type: String, default: null }) fallbackImg!: string;

  getSourceType(src: string) {
    const extension = src.substr(src.lastIndexOf('.') + 1);
    return 'video/' + extension;
  }
}
