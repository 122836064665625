

















import { Vue, Component } from 'vue-property-decorator';
import SidebarDictionary from './SidebarDictionary.vue';
import SidebarComments from './SidebarComments.vue';
import { BSidebar, BIconX } from 'bootstrap-vue';

@Component({
  components: {
    BSidebar,
    BIconX,
    SidebarDictionary,
    SidebarComments
  }
})
export default class TemplateSidebar extends Vue {}
