







































































import { Component, Vue, Prop } from 'vue-property-decorator';
import ProduitService from '../../../services/produit';
import PreconisationService from '../../../services/preconisation';
import { Preconisation } from '@/models/preconisation';
import { PreconisationType } from '@/models/preconisationtype';
import { CategorieType } from '@/models/categorietype';

@Component
export default class SettingsAcmPreconisationModalAddPreconisation extends Vue {
  @Prop({ required: true }) readonly item!: Preconisation;

  preconisationTypesAll: PreconisationType[] = [];
  categorieTypesAll: CategorieType[] = [];
  showDismissibleAlert = false;
  dismissSecs = 5;
  dismissCountDown = 0;

  get title(): string {
    return this.item === undefined ? 'Ajouter une préconisation' : 'Modifier une préconisation';
  }

  get preconisationTypesAllOptions() {
    return [
      {
        text: '-- Choisissez un type de préconisation --',
        value: undefined,
        disabled: true
      },
      ...this.preconisationTypesAll.map((type) => {
        return { text: type.nom, value: type.id.toString() };
      })
    ];
  }

  openModal() {
    // @ts-ignore
    this.$refs.modal.show();
    this.loadType();
    this.loadCategoryType();
  }

  close() {
    // this.clearForm();
    // @ts-ignore
    this.$refs.modal.hide();
  }

  handleOk(evt: Event) {
    // Prevent modal from closing
    evt.preventDefault();
    this.handleSubmit();
  }

  handleSubmit() {
    if (this.item.nomDefault === '' || this.item.preconisationType === null) {
      this.showDismissibleAlert = true;
    } else {
      this.showDismissibleAlert = false;
      PreconisationService.preconisationSave(
        this.item ? this.item.id : undefined,
        this.item.nomDefault,
        this.item.preconisationType.id,
        this.item.categorieTypes.map((cat) => cat.id)
      ).then((response) => {
        if (response.status === 202) {
          this.dismissCountDown = this.dismissSecs;
          this.$emit('preconisation-created');
        }
      });
    }
  }

  countDownChanged(dismissCountDown: number) {
    this.dismissCountDown = dismissCountDown;
  }

  loadType() {
    PreconisationService.preconisationTypeAll().then((types) => {
      this.preconisationTypesAll = types;
    });
  }

  loadCategoryType() {
    ProduitService.loadCategorieType().then(async (types) => {
      this.categorieTypesAll = types.sort((type) => type.id);

      // ugly sleep hack because vue does not bind checkboxes to values if they are rendered after the model was loaded
      await new Promise((resolve) => setTimeout(resolve, 1));
      this.item.categorieTypes = this.item.categorieTypes.sort((type) => type.id);
    });
  }
}
