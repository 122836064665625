






















import { Vue, Component } from 'vue-property-decorator';
import TemplateService from '../../services/template';
import { Template, TemplateCategory, TemplateSubCategory } from '../../models/template';
import { Section } from '../../models/section';
import { Placeholder } from '../../models/placeholder';
import TemplateForm from '@/components/template/TemplateForm.vue';
import { templateCategoryTree } from '../elements/TemplateCategoryTree';
import { DiagnosticStageName } from '@/models/diagnostic_stage';

@Component({
  components: {
    TemplateForm
  }
})
export default class TemplateCreate extends Vue {
  formSubmission = false;
  errors: string[] = [];

  categories = TemplateCategory;
  subcategories = TemplateSubCategory;

  defaultCategory = TemplateCategory.DIAGNOSTICPATRIMONIAL;
  defaultSubcategory = DiagnosticStageName.AVANTPROPOS;

  template: Template | null = null;

  templateContents: Array<Section> = [];

  layoutVersion = 0;

  get defaultName() {
    return this.$t(`templates.newTemplate`) + ' ' + this.$t(`templates.${this.category}.${this.subcategory}`);
  }

  get category() {
    return (this.$route.query.category as TemplateCategory) || this.defaultCategory;
  }

  get subcategory() {
    return (this.$route.query.subcategory as DiagnosticStageName) || this.defaultSubcategory;
  }

  async created() {
    TemplateService.templateNew(this.category, this.subcategory, this.defaultName).then((template: Template) => {
      this.template = template;
      this.updateContentLayout();
    });
  }

  handleCreate() {
    if (!this.template) return;
    this.errors = [];
    this.formSubmission = true;

    TemplateService.templateUpdate(this.template.id, this.template)
      .then((template: Template) => {
        this.template = template;

        TemplateService.templateContentsUpdate(this.template.id, this.templateContents)
          .then((contents: Section[]) => {
            this.templateContents = contents;

            this.$router.push({
              name: 'TemplateOne',
              params: { templateId: template.id.toString() }
            });
          })
          .catch((rejection) => {
            console.warn({ rejection });
            this.formSubmission = false;
          });
      })
      .catch((rejection) => {
        this.formSubmission = false;
        if (rejection.response.data.apierror.status === 'CONFLICT') {
          this.errors.push('nameConflict');
        }
      });
  }

  handleCancel() {
    console.log('handleCancel');
    this.formSubmission = true;
    if (!this.template) return;

    TemplateService.templateDelete(this.template.id).then(() => {
      console.log('template deleted');
      this.formSubmission = false;
      this.$router.push({ name: 'TemplateList' });
    });
  }

  confirmNewLayout(): Promise<boolean> {
    const allContentsEmpty = this.templateContents.every((c) => !c.content || (c.content as string).length === 0);
    if (allContentsEmpty) {
      console.log('confirmNewLayout', 'allEmpty');
      return Promise.resolve(true);
    } else {
      return this.$bvModal.msgBoxConfirm(
        'Souhaitez-vous utiliser la mise en page dédiée de cette catégorie ? Si oui, les champs textes seront réinitialisés.',
        {
          headerTextVariant: 'secondary',
          bodyTextVariant: 'secondary',
          headerBgVariant: 'dark',
          bodyBgVariant: 'dark',
          footerBgVariant: 'dark',
          okVariant: 'outline-danger',
          okTitle: 'Oui',
          cancelTitle: 'Non'
        }
      );
    }
  }

  updateContentLayout() {
    if (!this.template) return;
    const template = this.template;

    const categoryNode = templateCategoryTree.find((node) => node.code === (this.template as Template).category);
    if (!categoryNode || !categoryNode.subcategories) return;

    const subCategoryNode = categoryNode.subcategories.find(
      (node) => node.code === (this.template as Template).subcategory
    );
    if (!subCategoryNode) return;

    let layout: Placeholder[];
    if (subCategoryNode.layout) {
      layout = subCategoryNode.layout;
    } else {
      layout = [Placeholder.BLOC_TEXTE];
    }

    if (JSON.stringify(layout) === JSON.stringify(this.templateContents.map((c) => c.placeholder))) return;

    this.confirmNewLayout().then((confirm) => {
      console.log('updateContentLayout', { confirm });
      if (!confirm) return;

      this.templateContents = layout.map((placeholder, index) => {
        const content: Section = {
          displayOrder: index,
          template,
          placeholder
        };
        return content;
      });

      this.layoutVersion += 1;
    });
  }

  removeTemplateContent(contentToRemove: Section) {
    let displayOrderToRemove = contentToRemove.displayOrder;
    this.templateContents = this.templateContents
      .filter((c) => c.displayOrder !== contentToRemove.displayOrder)
      .map((c) => {
        // Shift down contents with higher order
        if (c.displayOrder === displayOrderToRemove + 1) {
          c.displayOrder = displayOrderToRemove;
          displayOrderToRemove = displayOrderToRemove + 1;
        }
        return c;
      });
  }

  reorderTemplateContents(contents: Section[]) {
    this.templateContents = contents;
    this.templateContents.forEach((c, index) => (c.displayOrder = index));
  }
}
