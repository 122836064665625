<template class="custom-select">
  <select ref="$_VueXeditable_Select" :value="internalOptionValue(value)" @change="onChange($event.target.value)">
    <option v-for="(option, index) in options" :key="index" ref="options" :value="index" :selected="isSelected(option)">
      {{ displayedOptionValue(option) }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'VueXcustomSelect',
  props: {
    value: {
      type: [String, Array],
      default: ''
    },
    options: {
      type: [String, Array],
      default: ''
    }
  },
  emits: ['keydown', 'input'],
  mounted() {
    this.$refs.$_VueXeditable_Select.addEventListener('keydown', this.onKeyDown, false);
  },
  beforeUnmount() {
    this.$refs.$_VueXeditable_Select.removeEventListener('keydown', this.onKeyDown, false);
  },
  methods: {
    displayedOptionValue(option) {
      if (Array.isArray(option)) {
        return option[option.length - 1];
      } else {
        return option;
      }
    },
    internalOptionValue(option) {
      if (Array.isArray(option)) {
        return option[0];
      } else {
        return option;
      }
    },
    onChange(newSelectionIndex) {
      // MUST be called 'input'
      this.$emit('input', this.options[newSelectionIndex]);
    },
    isSelected(option) {
      return option === this.value;
    },
    onKeyDown(e) {
      this.$emit('keydown', e);
    }
  },
  template: '#custom-select'
};
</script>
