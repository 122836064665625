import { render, staticRenderFns } from "./SettingsCategorieModalError.vue?vue&type=template&id=21ad6e56&scoped=true&"
import script from "./SettingsCategorieModalError.vue?vue&type=script&lang=ts&"
export * from "./SettingsCategorieModalError.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21ad6e56",
  null
  
)

export default component.exports