















































import { Component, Vue } from 'vue-property-decorator';
import ProduitService from '@/services/produit';
import Breadcrumb from './Breadcrumb.vue';
import { CategorieType } from '../../models/categorietype';
import { CategorieProduit } from '../../models/categorieproduit';
@Component({
  components: {
    Breadcrumb
  }
})
export default class ProduitCreateSelectCategorie extends Vue {
  categories: CategorieProduit[] = [];
  typeCategories: CategorieType[] = [];

  created() {
    this.loadCategorie();
    this.loadCategorieType();
  }

  loadCategorieType() {
    ProduitService.loadCategorieType().then((response) => {
      this.typeCategories = response;
    });
  }

  loadCategorie() {
    ProduitService.loadCategorie().then((response) => {
      this.categories = response;
    });
  }

  categorieOf(type: CategorieProduit) {
    return this.categories.filter((x) => x.categorieType.id === type.id);
  }
}
