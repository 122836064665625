/* eslint-disable @typescript-eslint/no-explicit-any */
import app from '../main';
import Axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import store from '@/store';

const myAxios = Axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}/api`,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Function that will be called to refresh authorization
const refreshAuthLogic = async (failedRequest: any) => {
  console.log('refreshAuthLogic');
  try {
    const tokenRefreshResponse = await myAxios.post('../oauth2/refresh_token');
    // @ts-ignore
    store.user.commit('setToken', tokenRefreshResponse.data.token);
    failedRequest.response.config.headers.Authorization = 'Bearer ' + tokenRefreshResponse.data.token;
    return Promise.resolve();
  } catch (e) {
    if (document.querySelector('.modal.reauth-prompt')) return;
    app.$bvModal
      .msgBoxOk('Vous avez été déconnecté, veuillez vous reconnecter', {
        title: '',
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'Reconnexion',
        okVariant: 'success',
        modalClass: 'reauth-prompt',
        bodyClass: 'text-light bg-dark',
        headerClass: 'p-2 border-bottom-0 text-dark',
        footerClass: 'p-2 border-top-0 text-dark',
        centered: true
      })
      .then(() => {
        window.location.replace(`${process.env.VUE_APP_API_BASE_URL}/oauth2/authorization/salesforce`);
      });
  }
};

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(myAxios, refreshAuthLogic);

// Add a request interceptor
myAxios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    if (store.state.user.jwtToken !== '') {
      config.headers.Authorization = `Bearer ${store.state.user.jwtToken}`;
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a 401 response interceptor
myAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 500 && !document.querySelector('.modal.error-message')) {
      app.$bvModal.msgBoxOk('Une erreur interne est survenue', {
        title: '',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'error',
        modalClass: 'error-message',
        bodyClass: 'text-danger',
        headerClass: 'p-2 border-bottom-0 text-danger',
        footerClass: 'p-2 border-top-0 text-danger',
        centered: true
      });
    }
    return Promise.reject(error);
  }
);

export default myAxios;
