



























































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import AcmService from '@/services/acm';
import ConseillerService from '@/services/conseiller';
import ContactService from '@/services/clients';
import FormInputAppend from '@/components/elements/form-input-append.vue';
import Scrollable from '@/components/elements/Scrollable.vue';
import ModalContactInfo from '@/components/contact/ModalContactInfo.vue';
import { Contact } from '../../models/contact';
import { Conseiller } from '../../models/conseiller';

@Component({
  components: {
    FormInputAppend,
    Scrollable,
    ModalContactInfo
  }
})
export default class AcmDuplicate extends Vue {
  @Prop({ required: true }) acmId!: number;

  clients: Contact[] = [];
  itemsToRender: Contact[] = [];
  filter = null;
  conseiller = null;
  conseillerList: { value: number; text: string }[] = [];

  created() {
    ContactService.loadContact().then((response) => {
      this.clients = response;
      this.itemsToRender = this.clients.slice(0);
    });
    ConseillerService.conseillerList().then((response) => {
      this.conseillerList = [];
      this.conseillerList.push(
        ...response.map((item) => {
          return { value: item.id, text: item.firstname + ' ' + item.lastname };
        })
      );
    });
  }

  saveAcm(clientId: number) {
    AcmService.acmClone(this.acmId, clientId).then((response) => {
      this.$router.push({
        name: 'AcmCreateEdit',
        params: { acmId: `${response.id}` }
      });
    });
  }

  @Watch('conseiller')
  onconseiller(newVal: Conseiller | null) {
    if (newVal === null) {
      this.itemsToRender = this.clients;
    } else {
      this.itemsToRender = this.clients.filter(
        (client) => client.conseiller.id !== undefined && client.conseiller.id === newVal.id
      );
    }
  }
}
