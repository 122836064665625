


























































import { Component, Vue } from 'vue-property-decorator';
import SelectionService from '@/services/selection';
import SelectionCard from '@/components/produit/card/SelectionCard.vue';
import Scrollable from '@/components/elements/Scrollable.vue';
import ModalConfirmDelete from './ModalConfirmDelete.vue';
import Panier from '@/components/selection/panier';
import { Selection } from '@/models/selection';

@Component({
  components: {
    SelectionCard,
    Scrollable,
    ModalConfirmDelete
  }
})
export default class AllSelection extends Vue {
  allSelections: Selection[] = [];

  mounted() {
    this.load();
  }

  get panier() {
    return Panier;
  }

  load() {
    SelectionService.selectionAll().then((response) => {
      this.allSelections = response;
    });
  }

  openModal(selection: Selection) {
    // @ts-ignore
    this.$refs['modalConfirm-' + selection.id][0].showModal();
  }

  deleteSelection(selection: Selection) {
    if (selection.id !== undefined) {
      SelectionService.selectionDeleteById(selection.id).then((response) => {
        if (response.status === 202) {
          this.load();
        }
      });
    }
  }
}
