

































import { Component, Vue } from 'vue-property-decorator';
import SettingsEvaluationModalAddEdit from './SettingsEvaluationModalAddEdit.vue';
import SettingsEvaluationModelError from './SettingsEvaluationModalError.vue';
import { Evaluation } from '@/models/evaluation';
import ProduitService from '@/services/produit';

@Component({
  components: {
    SettingsEvaluationModalAddEdit,
    SettingsEvaluationModelError
  }
})
export default class SettingsEvaluation extends Vue {
  fields = [
    {
      key: 'nom',
      sortable: true
    },
    {
      key: 'inputType',
      label: 'Type',
      sortable: true
    },
    {
      key: 'parametre',
      label: 'Paramètre'
    }
  ];

  evaluations: Evaluation[] = [];
  selected: Evaluation | null = null;
  errorProduit = [];

  mounted() {
    this.load();
  }

  load() {
    ProduitService.evaluations().then((response) => {
      this.evaluations = response;
    });
  }

  openModalCreateMode() {
    this.selected = null;
    // @ts-ignore
    this.$refs.modalAddEvaluation.openModal();
  }

  openModalEditMode(item: Evaluation) {
    this.selected = item;
    // @ts-ignore
    this.$refs.modalAddEvaluation.openModal();
  }

  deleteEvaluation(evaluation: Evaluation) {
    ProduitService.evaluationDelete(evaluation.id)
      .then((response) => {
        if (response.status === 202) {
          this.load();
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          this.errorProduit = error.response.data;
          // @ts-ignore
          this.$refs.modalError.openModal();
        } else {
          throw error;
        }
      });
  }
}
