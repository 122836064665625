<template>
  <background-video
    class="h-100"
    :sources="[require('../assets/videos/home_screen.mp4')]"
    :autoplay="true"
    :loop="true"
    :playsinline="true"
    :muted="true"
  >
    <div class="row h-100 align-items-center">
      <div class="col-12">
        <div class="row justify-content-center">
          <img src="@/assets/images/logo_seve_vertical@300x.png" class="mw-100" />
        </div>
        <div class="row justify-content-center">
          <div class="col-10 col-lg-3 text-center">
            <b-button ref="loginBtn" class="btn-outline-primary" type="submit" @click="login">
              <i class="fas fa-cloud mr-1"></i>
              Connectez-vous
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </background-video>
</template>

<script>
import BackgroundVideo from './BackgroundVideo';
import { UserModule } from '@/store/modules/user';

export default {
  name: 'Login',
  components: {
    BackgroundVideo
  },
  computed: {
    currentUser() {
      return UserModule.user;
    }
  },
  created() {
    this.checkCurrentLogin();
    document.body.classList.add('h-100');
    document.documentElement.classList.add('h-100');
  },
  beforeUnmount() {
    document.body.classList.remove('h-100');
    document.documentElement.classList.remove('h-100');
  },
  updated() {
    this.checkCurrentLogin();
  },
  methods: {
    checkCurrentLogin() {
      if (this.currentUser) {
        this.$router.replace(this.$route.query.redirect || '/');
      }
    },
    login() {
      window.location.replace(`${process.env.VUE_APP_API_BASE_URL}/oauth2/authorization/salesforce`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/sass/offline-style';
</style>
