import axios from './axios';

class SalesforceService {
  contactsSync(accountId?: number) {
    if (accountId) return axios.get(`/update/contacts/${accountId}`);
    else return axios.get('/update/contacts');
  }
}

export default new SalesforceService();
