









































import { Component, Vue, Prop } from 'vue-property-decorator';
import ProduitService from '@/services/produit';
import { Produit } from '../../models';
import { ProduitHasCaracteristique } from '../../models/produithascaracteristique';

@Component
export default class ModalViewProduct extends Vue {
  @Prop({ required: true, type: Object as () => Produit }) produit!: Produit;

  content: ProduitHasCaracteristique[] = [];

  open() {
    // @ts-ignore
    this.$refs.modalInfo.show();
    this.loadContent();
  }

  loadContent() {
    ProduitService.produitCaracteristique(this.produit.id).then((data) => {
      this.content = data;
      this.updateBinaryValues();
      this.content = this.content.sort((a, b) => a.index - b.index);
    });
  }

  updateBinaryValues() {
    this.content
      .filter((caractId) => caractId.caracteristique.inputType.nom === 'binaire')
      .map((carac) => {
        if (carac.value === 'true') {
          carac.value = 'Oui';
        } else {
          carac.value = 'Non';
        }
        return carac;
      });
  }

  close() {
    // @ts-ignore
    this.$refs.modalInfo.hide();
  }
}
