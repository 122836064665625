












































































import { Component, Vue, Prop } from 'vue-property-decorator';
import AcmService from '@/services/acm';
import PreconisationService from '@/services/preconisation';
import Breadcrumb from '@/components/acm/Breadcrumb.vue';
import AcmPrecoModalError from './AcmPrecoModalError.vue';
import { Preconisation } from '../../models/preconisation';
import { Acm } from '../../models/acm';
import { AcmPreconisation } from '../../models/acmPreconisation';
@Component({
  components: {
    AcmPrecoModalError,
    Breadcrumb
  }
})
export default class AcmCreateEditSommaire extends Vue {
  @Prop({ required: true }) acmId!: number;
  acm: Acm | null = null;
  acmData = {};
  preco: Preconisation[] = [];
  section: Preconisation[] = [];
  precoUnchecked: Preconisation | null = null;

  get isEdited() {
    let isEdited = false;
    let i = 0;
    while (!isEdited && i < this.section.length) {
      isEdited = this.section[i].enabled !== this.preco[i].enabled;
      isEdited = isEdited || this.section[i].nomDefault !== this.preco[i].nomDefault;
      i += 1;
    }
    return isEdited;
  }

  created() {
    AcmService.acmById(this.acmId)
      .then((response) => {
        this.acm = response;
        PreconisationService.preconisation().then((response) => {
          this.section = response.map((item) => {
            item.enabled = false;
            return item;
          });
          AcmService.acmPreconisation(this.acmId).then((response) => {
            const enabledPrecoId = response.map((preco) => preco.preconisation.id);
            this.section.forEach((preco) => {
              preco.enabled = enabledPrecoId.indexOf(preco.id) !== -1;
              if (preco.enabled) {
                preco.nomDefault = response.find((p) => p.preconisation.id === preco.id)?.nom || '';
              }
            });
            const sectionId = this.section.map((i) => i.id);
            const removedPreco = response
              .filter((item) => {
                return sectionId.indexOf(item.preconisation.id) === -1;
              })
              .map((item, index) => {
                item.id = 0 - index - 1;
                // @ts-ignore
                item.enabled = true;
                // @ts-ignore
                item.nomDefault = item.nom;
                return item;
              });
            // @ts-ignore
            this.section.push(...removedPreco);
            this.preco = JSON.parse(JSON.stringify(this.section));
          });
        });
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.$router.push({ name: 'AcmList' });
        }
      });
  }

  alertIfEdited() {
    if (this.isEdited) {
      // @ts-ignore
      this.$refs.modalConfirm.show();
    } else {
      this.$router.push({
        name: 'AcmCreateEdit',
        params: { acmId: `${this.acmId}` }
      });
    }
  }

  toBeDeleted(preco: Preconisation) {
    const precoToDelete: Preconisation | undefined = this.preco.find((preconisation) => {
      return preconisation.id === preco.id;
    });
    if (precoToDelete && precoToDelete.enabled && preco.enabled) {
      this.precoUnchecked = preco;
      // @ts-ignore
      this.$refs.modalError.openModal();
    }
  }

  modalValidate() {
    this.$router.push({
      name: 'AcmCreateEdit',
      params: { acmId: `${this.acmId}` }
    });
  }

  modalCancel() {
    // @ts-ignore
    this.$refs.modalConfirm.hide();
  }

  saveBeforeNext() {
    let request;
    if (this.isEdited) {
      const preco = this.section.filter((s) => s.enabled);
      request = AcmService.acmSavePreconisation(this.acmId, preco);
    } else {
      request = AcmService.acmPreconisation(this.acmId);
    }
    request.then((response) => {
      if (response.length > 0 && this.acm) {
        this.$router.push({
          name: 'AcmCreatePreconisation',
          params: {
            acmId: `${this.acm.id}`,
            acmPrecoId: `${response[0].id}`
          }
        });
      }
    });
  }

  noDeleteAcmPreco(acmPreco: AcmPreconisation) {
    const precoStayEnabled = this.section.find((preco) => {
      return preco.id === acmPreco.id;
    });
    if (precoStayEnabled) {
      precoStayEnabled.enabled = true;
    }
  }
}
