














































import { Component, Vue, Prop } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
import UploaderWithCropper from '@/components/elements/UploaderWithCropper.vue';
import { Conseiller } from '@/models/conseiller';
import ConseillerService from '@/services/conseiller';

@Component({
  components: {
    UploaderWithCropper
  }
})
export default class SettingsConseillerModalMedia extends Vue {
  @Prop({ type: Object as () => Conseiller | null, default: null })
  readonly user!: Conseiller | null;

  uploading = 0;

  get currentUser() {
    return UserModule.user;
  }

  openModal() {
    // @ts-ignore
    this.$refs.modal.show();
  }

  close() {
    // @ts-ignore
    this.$refs.modal.hide();
  }

  onClose() {
    if (this.uploading > 0) {
      // @ts-ignore
      this.$refs.signature.cancel();
    }
  }

  uploadSignatureUrl() {
    if (this.currentUser && this.user && this.currentUser.id === this.user.id) {
      return ConseillerService.uploadSignatureUrl();
    } else if (this.user) {
      return ConseillerService.uploadSignatureUrl(this.user.id);
    }
  }

  signatureUploadInProgress() {
    this.uploading = this.uploading + 1;
  }

  signatureUploadDone() {
    this.uploading = this.uploading - 1;
    if (this.uploading === 0) {
      this.$emit('media-updated');
    }
  }
}
