import axiosInstance from './axios';
import { Diagnostic } from '@/models/diagnostic';
import { DiagnosticStageName } from '@/models/diagnostic_stage';
import { DiagnosticConstat } from '@/models/diagnostic_constat';
import { DiagnosticAnnex } from '@/models/diagnostic_annex';
import { Section } from '@/models/section';
import { Account } from '@/models/account';

class DiagnosticService {
  diagnosticByAccountId(accountId: number) {
    return axiosInstance.get<Diagnostic>(`/accounts/${accountId}/diagnostic`);
  }

  diagnosticContentsByAccountId(accountId: number) {
    return axiosInstance.get<Section[]>(`/accounts/${accountId}/diagnostic/contents`);
  }

  diagnosticConstatsByAccountId(accountId: number) {
    return axiosInstance.get<DiagnosticConstat[]>(`/accounts/${accountId}/diagnostic/constats`);
  }

  async diagnosticUpdate(accountId: number, diagnostic: Diagnostic): Promise<Diagnostic> {
    return axiosInstance.post<Diagnostic>(`/accounts/${accountId}/diagnostic`, diagnostic).then((response) => {
      return response.data;
    });
  }

  async diagnosticContentsUpdate(accountId: number, contents: Section[]): Promise<Section[]> {
    return axiosInstance.post<Section[]>(`/accounts/${accountId}/diagnostic/contents`, contents).then((response) => {
      return response.data;
    });
  }

  async diagnosticConstatCreate(accountId: number, stageName: DiagnosticStageName) {
    return axiosInstance
      .post<DiagnosticConstat>(`/accounts/${accountId}/diagnostic/${stageName}/constat`)
      .then((response) => response.data);
  }

  async diagnosticContentDelete(accountId: number, stageName: DiagnosticStageName, constatId: number) {
    const response = await axiosInstance.delete<DiagnosticConstat>(
      `/accounts/${accountId}/diagnostic/${stageName}/constat/${constatId}`
    );
    return response.data;
  }

  async diagnosticDelete(accountId: number): Promise<Account> {
    const response = await axiosInstance.post<Account>(`/accounts/${accountId}/diagnostic/delete`);
    return response.data;
  }

  async diagnosticAnnexes(
    accountId: number,
    stageName: DiagnosticStageName,
    constat: DiagnosticConstat | null
  ): Promise<DiagnosticAnnex[]> {
    let params = '';
    if (constat) params = `constat=${constat.id}`;
    const annexFilesRequest = axiosInstance.get<DiagnosticAnnex[]>(
      `/accounts/${accountId}/diagnostic/${stageName}/annexFiles?${params}`
    );
    const annexDocsRequest = axiosInstance.get<DiagnosticAnnex[]>(
      `/accounts/${accountId}/diagnostic/${stageName}/annexDocuments?${params}`
    );
    const responses = await Promise.all([annexFilesRequest, annexDocsRequest]);
    return responses.flatMap((response) => {
      return response.data;
    });
  }

  diagnosticCopyAnnexes(
    accountId: number,
    templateId: number,
    stageName: DiagnosticStageName,
    constat: DiagnosticConstat | null
  ) {
    let params = `templateId=${templateId}`;
    if (constat) params = `templateId=${templateId}&constat=${constat.id}`;
    return axiosInstance.post(`/accounts/${accountId}/diagnostic/${stageName}/copyAnnexes?${params}`);
  }

  diagnosticAddAnnexTemplate(
    accountId: number,
    templateId: number,
    stageName: DiagnosticStageName,
    constat?: DiagnosticConstat
  ) {
    let params = `templateId=${templateId}`;
    if (constat) params = `templateId=${templateId}&constat=${constat.id}`;
    return axiosInstance.post(`/accounts/${accountId}/diagnostic/${stageName}/addAnnexTemplate?${params}`);
  }

  async diagnosticAnnexDelete(
    accountId: number,
    annexName: string,
    stageName: DiagnosticStageName,
    constat?: DiagnosticConstat
  ) {
    let params = '';
    if (constat) params = `constat=${constat.id}`;

    const response = await axiosInstance.post(
      `/accounts/${accountId}/diagnostic/${stageName}/annexes/${annexName}/delete?${params}`
    );
    return response.data.deleted;
  }

  uploadDiagnosticImageUrl(accountId: number): string {
    return `${axiosInstance.defaults.baseURL}/accounts/${accountId}/diagnostic/uploadImage`;
  }

  uploadDiagnosticAnnexUrl(accountId: number, stageName: DiagnosticStageName, constat?: DiagnosticConstat): string {
    let params = '';
    if (constat) params = `constat=${constat.id}`;

    return `${axiosInstance.defaults.baseURL}/accounts/${accountId}/diagnostic/${stageName}/uploadAnnex?${params}`;
  }
}

export const diagnosticService = new DiagnosticService();
