












import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ConseillerService from '@/services/conseiller';
import { Section } from '@/models/section';
import { Placeholder } from '@/models/placeholder';
import { Definition } from '@/models/dictionary';

@Component({})
export default class Body extends Vue {
  @Prop({ required: false }) definitions?: Definition[];
  @Prop({ required: true }) contents!: Section[];

  mounted() {
    const pageBreaks = this.$el.querySelectorAll('.page-break');
    this.$emit('update-sommaire', this.stageName, 'total', pageBreaks.length + 1);
    this.updateSommaire();
    this.replaceMentions();
  }

  get stageName() {
    if (this.contents.length === 0) return null;

    return this.contents[0].stageName;
  }

  @Watch('definitions')
  replaceMentions() {
    const mentionNodes = this.$el.querySelectorAll<HTMLElement>('[data-mention]');

    mentionNodes.forEach(this.replaceMention);
  }

  replaceMention(mentionNode: HTMLElement) {
    if (!this.definitions) return;
    const definitions: Definition[] = this.definitions;

    const parent = mentionNode.parentNode as HTMLElement;
    const rawMention = mentionNode.dataset.mention as string;

    const regexp = /^@(.*?)(\d*)$/;
    const matches = regexp.exec(rawMention);
    if (!matches) return;

    const mentionName = matches[1];
    const mentionContactIndex = parseInt(matches[2], 10) || null;

    let def: Definition | undefined;
    if (mentionContactIndex === null)
      def = definitions.find((def) => {
        return def.trait.name === mentionName;
      });
    else
      def = definitions.find((def) => {
        return def.trait.name === mentionName && def.contact && def.contact.contactIndex === mentionContactIndex;
      });
    if (!def) return;

    const replacementNode = document.createElement('span');
    replacementNode.classList.add('mention-replaced');

    if (def.trait.name === 'GestionnaireGCFClient') {
      ConseillerService.conseillerList().then((conseillers) => {
        const conseiller = conseillers.find((c) => def && c.salesForceConseillerId === def.value);
        if (!conseiller) return;

        replacementNode.innerText = `${conseiller.firstname} ${conseiller.lastname}`;
        parent.replaceChild(replacementNode, mentionNode);
      });
    } else if (def.trait.name.match(/Date/)) {
      replacementNode.innerText = this.$moment(def.value).format('LL');
      parent.replaceChild(replacementNode, mentionNode);
    } else {
      replacementNode.innerText = def.value;
      parent.replaceChild(replacementNode, mentionNode);
    }
  }

  contentTag(placeholder: string) {
    switch (placeholder) {
      case Placeholder.TITRE:
        return 'h2';
      case Placeholder.SOUS_TITRE:
        return 'h3';
      case Placeholder.SOUS_TITRE_INTERNE:
        return 'h4';
      case Placeholder.BLOC_TEXTE:
        return 'p';
      case Placeholder.OBJECTIF:
        return 'li';
      case Placeholder.ENJEU:
        return 'strong';
    }
    return 'p';
  }

  updateSommaire() {
    const titlesAndPageBreaks = Array.from(this.$el.querySelectorAll('h2, .page-break'));
    if (titlesAndPageBreaks.length === 0) return;

    titlesAndPageBreaks.forEach((element, index) => {
      if (element.tagName !== 'H2') return;
      const pageBreaksBeforeTitle = titlesAndPageBreaks.filter(
        (element2, index2) => element2.classList.contains('page-break') && index2 < index
      ).length;
      this.$emit('update-sommaire', this.stageName, element.textContent, pageBreaksBeforeTitle);
    });
  }
}
