































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ModalViewProduct from '@/components/produit/ModalViewProduct.vue';
import F5fModal from '@/components/produit/F5fModal.vue';
import { Produit } from '../../../models';

@Component({
  components: {
    ModalViewProduct,
    F5fModal
  }
})
export default class DefaultCard extends Vue {
  @Prop({ type: Object }) produit!: Produit;
  @Prop({ type: Boolean, default: false }) selected!: boolean;
  @Prop({ type: Boolean, default: false }) withClose!: boolean;

  select: boolean | number = false;
  noF5F = false;
  get noteGlobal() {
    if (this.produit.notations === undefined || this.produit.notations.length === 0) {
      return '-';
    } else {
      return Math.round(
        this.produit.notations.map((n) => n.note).reduce((a, b) => a + b, 0) / this.produit.notations.length
      );
    }
  }

  mounted() {
    if (this.selected) {
      this.select = this.produit.id;
    } else {
      this.select = false;
    }
  }

  open() {
    this.$router.push({
      name: 'ProduitOne',
      params: { produitId: `${this.produit.id}` }
    });
  }

  voirProduit() {
    // @ts-ignore
    this.$refs.modalViewProduct.open();
  }

  onChange(checked: boolean) {
    this.$emit('onChange', checked !== false);
  }

  onClose() {
    this.$emit('onClose');
  }

  onNoF5FEvent(boolean: boolean) {
    this.noF5F = boolean;
  }

  @Watch('selected')
  onSelectedChange(n: boolean) {
    if (n) {
      this.select = this.produit.id;
    } else {
      this.select = false;
    }
  }
}
