





































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import AccountService from '../../services/account';
import moment from 'moment';
import { CancelTokenSource, AxiosResponse } from 'axios';
import { debounce } from 'ts-debounce';
import { Account } from '../../models/account';

@Component
export default class AccountList extends Vue {
  accountSearch = '';
  accountList: Account[] = [];
  timeoutFlag = false;
  timeoutMilliseconds = 10000;
  pageNumber = 0;
  noMorePages = false;
  loadingPage = false;
  salesForceSyncFlag = false;
  accountFields = [
    {
      key: 'nom',
      label: 'Compte famille'
    },
    {
      key: 'conseillerFullName',
      label: 'Resp. gestion'
    },
    {
      key: 'livrables',
      label: 'Livrables en cours'
    },
    {
      key: 'consulteLe',
      label: 'Consulté le',
      formatter: (value: string) => {
        return value ? moment(value).format('LL[ à ]LT') : '-';
      }
    }
  ];

  cancelToken: CancelTokenSource | undefined;

  errors: string[] = [];

  salesForceSyncTimer = 0;
  timerHandle?: number;

  mounted() {
    this.accountSearch = (this.$route.query.search as string) || '';
    this.loadNextPage().then(() => {
      if (!this.salesForceDataUpToDate) this.refreshSalesForce();
    });

    this.timerHandle = window.setTimeout(this.updateDateTime, 60000);
  }

  beforeDestroy() {
    window.clearTimeout(this.timerHandle);
  }

  get salesForceDataUpToDate() {
    return this.accountList.length > 0 && this.salesForceSyncAge / 1000 < 300;
  }

  get salesForceSyncAge(): number {
    return new Date().getTime() - new Date(this.salesForceSyncAgeString).getTime();
  }

  get salesForceSyncAgeString(): string {
    if (this.accountList.length === 0) return new Date().toString();
    return this.accountList[0].lastSalesforceSyncDate;
  }

  updateDateTime() {
    this.salesForceSyncTimer = Math.floor(new Date().getSeconds() / 10);
    this.timerHandle = window.setTimeout(this.updateDateTime, 60000);
  }

  goToAccount(account: Account) {
    this.$router.push({
      name: 'AccountOne',
      params: { accountId: account.id.toString() }
    });
  }

  @Watch('accountSearch')
  searchAccounts = debounce((input) => {
    this.searchAccountsDebounced(input);
  }, 2000);

  searchAccountsDebounced(search: string) {
    if (this.$route.query.search === search) return;
    this.pageNumber = 0;
    this.noMorePages = false;

    if (search.length > 0) {
      if (this.cancelToken !== undefined) this.cancelToken.cancel('Search query changed');
      this.cancelToken = AccountService.searchAccountsCancelToken();
    }

    this.loadNextPage();
  }

  loadNextPage(): Promise<void> {
    this.loadingPage = true;
    this.timeoutFlag = false;
    let promiseData;
    if (this.accountSearch.length === 0) {
      promiseData = AccountService.accountList(this.pageNumber);
    } else {
      promiseData = AccountService.searchAccount(this.accountSearch, this.pageNumber);
    }
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => reject(Error('Timeout')), this.timeoutMilliseconds);
    });
    return Promise.race([promiseData, timeoutPromise])
      .then((response) => {
        this.errors = [];
        const axiosResponse = response as AxiosResponse;
        if (this.pageNumber === 0) this.accountList = axiosResponse.data;
        else this.accountList = this.accountList.concat(axiosResponse.data);

        if (axiosResponse.data.length < 10) this.noMorePages = true;
        this.pageNumber += 1;
        this.loadingPage = false;

        if (this.accountSearch.length > 0) {
          this.updateQuery({ search: this.accountSearch });
        } else {
          this.updateQuery(undefined);
        }
      })
      .catch((thrown: { message: string }) => {
        if (thrown.message === 'Timeout' || thrown.message === 'Network Error') {
          this.loadingPage = false;
          this.timeoutFlag = true;
        }
        if (this.cancelToken && thrown.message === 'Search query changed') return;
        console.warn(thrown.message);
      });
  }

  updateQuery(query: { [key: string]: string } | undefined) {
    if (JSON.stringify(this.$route.query) !== JSON.stringify(query || {})) this.$router.replace({ query });
  }

  refreshSalesForce() {
    this.salesForceSyncFlag = true;
    AccountService.allAccountsSync()
      .then((response: AxiosResponse) => {
        this.salesForceSyncFlag = false;
        if (response.status === 200) {
          this.pageNumber = 0;
          this.loadNextPage();
        } else if (response.status === 203) {
          this.errors.push('salesforcesyncfailure');
        }
      })
      .catch((err) => {
        console.log({ err });
      });
  }

  get loading() {
    const busyIndex = this.loadingPage && this.accountSearch === '' && this.accountList.length === 0;
    const searchComplete =
      (this.accountSearch === '' && this.$route.query.search === undefined) ||
      this.accountSearch === (this.$route.query.search as string);
    return busyIndex || !searchComplete;
  }
}
