














































import { Component, Vue } from 'vue-property-decorator';
import PreconisationService from '../../../services/preconisation';
import ModalAddPreconisation from './SettingsAcmPreconisationModalAddPreconisation.vue';
import SettingsAcmPreconisationModalError from './SettingsAcmPreconisationModalError.vue';
import { Preconisation } from '@/models/preconisation';

@Component({
  components: {
    SettingsAcmPreconisationModalError,
    ModalAddPreconisation
  }
})
export default class SettingsAcmPreconisation extends Vue {
  preconisations: Preconisation[] = [];
  selected: Preconisation | null = null;
  errorPreco = [];

  mounted() {
    this.load();
  }

  load() {
    PreconisationService.preconisation().then((response) => {
      this.preconisations = response;
    });
  }

  openModalCreateMode() {
    this.selected = null;
    // @ts-ignore
    this.$refs.modalAddPreconisation.openModal();
  }

  openModalEditMode(item: Preconisation) {
    this.selected = item;
    // @ts-ignore
    this.$refs['modalAddPreconisation-' + item.id][0].openModal();
  }

  deleteItem(item: Preconisation) {
    PreconisationService.deletePreconisation(item.id)
      .then((response) => {
        if (response.status === 202) {
          this.load();
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          this.errorPreco = error.response.data;
          // @ts-ignore
          this.$refs.modalError.openModal();
        } else {
          throw error;
        }
      });
  }
}
