import { render, staticRenderFns } from "./NoteInput.vue?vue&type=template&id=5a7479e1&scoped=true&"
import script from "./NoteInput.vue?vue&type=script&lang=ts&"
export * from "./NoteInput.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a7479e1",
  null
  
)

export default component.exports