































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component
export default class CustomListSelect extends Vue {
  @Prop({ required: true }) value!: string;
  @Prop({ required: true }) placeholder!: string;
  @Prop({ required: true }) list!: string;
  @Prop({ type: Boolean, default: false, required: false }) noClose!: boolean;
  model = this.value;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: { value: any; text: any }[] = [];
  confirm = false;

  mounted() {
    this.list.split('**').forEach((item) => this.options.push({ value: item, text: item }));
  }

  @Watch('value')
  onValue(n: string) {
    this.model = n;
  }

  @Watch('model')
  onModel() {
    this.$emit('input', this.model);
  }
}
