













import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component
export default class FormInputAppend extends Vue {
  @Prop({ required: true }) value!: string;
  @Prop({ required: true }) placeholder!: string;
  @Prop({ type: String, default: 'text', required: true }) type!: 'text';
  @Prop({ required: true }) inputClass!: string;

  model = '';

  @Watch('value')
  onValueChange(n: string) {
    this.model = n;
  }

  @Watch('model')
  onModelChange(n: string) {
    this.$emit('input', n);
  }
}
