


































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
import ProduitService from '@/services/produit';
import BinaryInput from '@/components/elements/produit/BinaryInput.vue';
import BoundaryInput from '@/components/elements/produit/BoundaryInput.vue';
import MonetaireInput from '@/components/elements/produit/MonetaireInput.vue';
import NumberInput from '@/components/elements/produit/NumberInput.vue';
import PourcentageInput from '@/components/elements/produit/PourcentageInput.vue';
import TextInput from '@/components/elements/produit/TextInput.vue';
// @ts-ignore
import StarRating from 'vue-star-rating';
import F5fModal from './F5fModal.vue';
import moment from 'moment';
// @ts-ignore
import VueNextLevelScroll from 'vue-next-level-scroll';
import ModalViewProduct from './ModalViewProduct.vue';
import ProduitDeleteModalError from './ProduitDeleteModalError.vue';
import VueXeditable from '@/components/elements/xeditable/XEditable.vue';
import { Evaluation } from '@/models/evaluation';
import { Produit } from '@/models';
import { ProduitHasCaracteristique } from '@/models/produithascaracteristique';
import { ConseillerHasEditedProduit } from '@/models/conseillerhaseditedproduit';
import { ProduitHasEvaluation } from '@/models/produithasevaluation';
import { Notation } from '@/models/notation';

@Component({
  components: {
    BinaryInput,
    BoundaryInput,
    MonetaireInput,
    NumberInput,
    PourcentageInput,
    TextInput,
    StarRating,
    F5fModal,
    ModalViewProduct,
    VueNextLevelScroll,
    ProduitDeleteModalError,
    'vue-xeditable': VueXeditable
  }
})
export default class ProduitOne extends Vue {
  @Prop({ type: Number }) produitId!: number;

  produit: Produit | null = null;
  showCollapse = false;
  history: ConseillerHasEditedProduit[] = [];
  fullHistory: ConseillerHasEditedProduit[] = [];
  isMore = false;
  content: ProduitHasCaracteristique[] = [];
  evaluation: ProduitHasEvaluation[] = [];
  evaluationList: Evaluation[] = [];
  evaluationNew: { type?: Evaluation; value?: string } = {};
  commentaires: Notation[] = [];
  commentaire: { id?: number; note?: number; text?: string } = {};

  note = 0;
  noF5F = false;
  errorACM = [];

  get user() {
    return UserModule.user;
  }

  get evaluationSelectOptions() {
    return this.evaluationList.map((evale) => {
      return { text: evale.nom, value: evale };
    });
  }

  mounted() {
    ProduitService.produitOne(this.produitId).then((response) => {
      this.produit = response;
      this.loadContent();
    });
  }

  loadContent() {
    if (this.produit) {
      ProduitService.produitCaracteristique(this.produit.id).then((data) => {
        this.content = data;
        this.content = this.content.sort((a, b) => a.index - b.index);
      });
      this.refreshProduitEvaluation();
      this.refreshProduitComment();
      this.loadHistory();
    }
  }

  loadHistory() {
    if (this.produit) {
      ProduitService.produitHistory(this.produit.id)
        .then((response) => {
          return response.map((i) => {
            if (i.content !== undefined) {
              i.content = JSON.parse(i.content);
            }
            return i;
          });
        })
        .then((data) => {
          this.fullHistory = data;
          this.seeLess();
        });
    }
  }

  seeMore() {
    this.isMore = true;
    this.history = this.fullHistory;
  }

  seeLess() {
    this.isMore = false;
    this.history = this.fullHistory.slice(0, 4);
  }

  voirProduit() {
    // @ts-ignore
    this.$refs.modalViewProduct.open();
  }

  deleteProduit() {
    ProduitService.produitDelete(this.produitId)
      .then((response) => {
        if (response.status === 200) {
          this.$router.push({ name: 'Produit' });
        } else {
          // @ts-ignore
          this.$log.fatal(response);
        }
      })
      .catch((error) => {
        if (error.response.status === 405) {
          this.errorACM = error.response.data;
          // @ts-ignore
          this.$refs.deleteModalError.openModal();
        } else {
          // @ts-ignore
          this.$log.fatal(error);
        }
      });
  }

  refreshProduitEvaluation() {
    if (this.produit) {
      ProduitService.produitEvaluation(this.produit.id).then((response) => {
        this.evaluation = response;
        ProduitService.evaluations().then((response) => {
          const evalIds = this.evaluation.map((e) => e.evaluation.id);
          this.evaluationList = response.filter((evale) => {
            return evalIds.indexOf(evale.id) === -1;
          });
        });
      });
    }
  }

  refreshProduitComment() {
    ProduitService.produitComment(this.produitId).then((response) => {
      this.commentaires = response;
      this.note = Math.round(
        this.commentaires.map((com) => com.note).reduce((sum, x) => sum + x, 0) / this.commentaires.length
      );
      if (isNaN(this.note)) {
        this.note = 0;
      }
    });
  }

  sendValueDidChangeEvent(evaluation: ProduitHasEvaluation, $evt: string) {
    if (this.produit) {
      ProduitService.produitEvaluationEdit(
        this.produit.id,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        evaluation.evaluation!.id,
        $evt
      ).then(() => {
        this.evaluationNew = { type: undefined, value: undefined };
        this.refreshProduitEvaluation();
        this.loadHistory();
      });
    }
  }

  deleteEvaluation(evaluation: ProduitHasEvaluation) {
    if (this.produit) {
      ProduitService.produitEvaluationDelete(this.produit.id, evaluation.evaluation.id).then(() => {
        this.refreshProduitEvaluation();
        this.loadHistory();
      });
    }
  }

  submitEvaluation() {
    if (this.produit && this.evaluationNew.type && this.evaluationNew.value) {
      if (this.evaluationNew.type.inputType.nom === 'note' && this.evaluationNew.value === undefined) {
        this.evaluationNew.value = '0';
      }
      ProduitService.produitEvaluationEdit(this.produit.id, this.evaluationNew.type.id, this.evaluationNew.value).then(
        () => {
          this.evaluationNew = { type: undefined, value: undefined };
          this.refreshProduitEvaluation();
          this.loadHistory();
        }
      );
    }
  }

  comment() {
    if (this.commentaire.text && this.commentaire.note) {
      ProduitService.produitCommentNew(this.produitId, this.commentaire.text, this.commentaire.note).then(() => {
        this.commentaire = {
          text: '',
          note: 0
        };
        this.showCollapse = false;
        this.refreshProduitComment();
        this.loadHistory();
      });
    }
  }

  editComment() {
    if (this.commentaire.id && this.commentaire.text && this.commentaire.note) {
      ProduitService.produitCommentEdit(
        this.produitId,
        this.commentaire.id,
        this.commentaire.text,
        this.commentaire.note
      ).then(() => {
        this.commentaire = {
          text: '',
          note: 0
        };
        this.showCollapse = false;
        this.refreshProduitComment();
      });
    }
  }

  editCommentClick(comment: Notation) {
    if (!this.showCollapse) {
      this.showCollapse = !this.showCollapse;
    }
    this.commentaire = {
      text: comment.commentaire,
      note: comment.note,
      id: comment.id
    };
  }

  deleteComment(comment: Notation) {
    ProduitService.produitCommentDelete(this.produitId, comment.id).then(() => {
      this.refreshProduitComment();
    });
  }

  format(date: Date) {
    return moment(date).format('Do MMM YY');
  }

  onNoF5FEvent(boolean: boolean) {
    this.noF5F = boolean;
  }
}
