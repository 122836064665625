





















































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
// @ts-ignore
import draggable from 'vuedraggable';
import BinaryInput from '@/components/elements/produit/BinaryInput.vue';
import BoundaryInput from '@/components/elements/produit/BoundaryInput.vue';
import NoteInput from '@/components/elements/produit/NoteInput.vue';
import NumberInput from '@/components/elements/produit/NumberInput.vue';
import PourcentageInput from '@/components/elements/produit/PourcentageInput.vue';
import TextInput from '@/components/elements/produit/TextInput.vue';
import Scrollable from '@/components/elements/Scrollable.vue';
import BoundaryPourcentageInput from '@/components/elements/produit/BoundaryPourcentageInput.vue';
import { CategorieProduit } from '@/models/categorieproduit';
import { Caracteristique } from '@/models/caracteristique';
import ProduitService from '@/services/produit';

@Component({
  components: {
    draggable,
    BinaryInput,
    BoundaryInput,
    NoteInput,
    NumberInput,
    PourcentageInput,
    TextInput,
    Scrollable,
    BoundaryPourcentageInput
  }
})
export default class SettingsCategorieMaster extends Vue {
  @Prop({ required: true }) readonly id!: number;

  categorie: CategorieProduit | null = null;
  caracteristiques: Caracteristique[] = [];
  allCaracteristiques: Caracteristique[] = [];
  content: Caracteristique[] = [];
  search = '';

  mounted() {
    this.loadCategory();
  }

  loadCategory() {
    ProduitService.loadOneCategorie(this.id).then((response) => {
      this.categorie = response;
      this.loadCaracteristique();
    });
  }

  loadMaster() {
    ProduitService.loadCategorieMaster(this.id).then((response) => {
      response.forEach((x) => {
        const item = this.caracteristiques.find((y) => x.id === y.id);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        item!.index = x.index;
        this.caracteristiques = this.caracteristiques.filter((x) => x.id !== item?.id);
        this.allCaracteristiques = this.caracteristiques.slice(0);
        this.content.push(item as Caracteristique);
      });
      this.content = this.content.sort((a, b) => a.index - b.index);
    });
  }

  loadCaracteristique() {
    if (this.categorie) {
      ProduitService.loadCaracteristiqueByCategoryType(this.categorie.categorieType.id).then((response) => {
        this.caracteristiques = response;
        this.allCaracteristiques = this.caracteristiques.slice(0);
        this.loadMaster();
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter(evt: any) {
    const item = this.content.find((x) => x.id === parseInt(evt.item.id));
    this.content = this.content.filter((x) => x.id !== item?.id);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.caracteristiques.push(item!);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.allCaracteristiques.push(item!);
  }

  save() {
    this.content.forEach((value, index) => {
      value.index = index;
    });
    ProduitService.categorieSaveMaster(this.id, this.content).then((response) => {
      if (response.status === 202) {
        this.$router.push({ name: 'Settings.Categorie' });
      }
    });
  }

  @Watch('search')
  onSearchChange(n: string) {
    if (n === '') {
      this.caracteristiques = this.allCaracteristiques.slice(0);
    } else {
      this.caracteristiques = this.allCaracteristiques.filter((c) => {
        return c.nom.toLowerCase().indexOf(n.toLowerCase()) !== -1;
      });
    }
  }

  @Watch('content')
  onContentChange() {
    this.allCaracteristiques = this.allCaracteristiques.filter((x) => {
      return this.content.find((y) => y.id === x.id) === undefined;
    });
  }
}
