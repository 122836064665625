






































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import AcmService from '@/services/acm';
import ConseillerService from '@/services/conseiller';
import AcmState from './acmState/AcmState.vue';
import AcmStateList from './acmState/AcmStateList';
import Scrollable from '@/components/elements/Scrollable.vue';
import { Acm } from '../../models/acm';
import { Produit } from '../../models';
@Component({
  components: {
    AcmState,
    Scrollable
  }
})
export default class AcmList extends Vue {
  items: { acm: Acm; produit: Produit[] }[] = [];
  itemsToRender: { acm: Acm; produit: Produit[] }[] = [];
  typeSelected: string[] = [];
  allTypeSelected = false;
  indeterminate = false;
  conseiller = null;
  conseillerList: { value: number; text: string }[] = [];
  AcmStateList = AcmStateList;
  showSpinner = false;
  errors: string[] = [];

  created() {
    AcmService.acmAll()
      .then((response) => {
        this.showSpinner = true;
        return Promise.all(
          response.map((acm) => {
            return AcmService.acmProduit(acm.id)
              .then((produit) => {
                return { acm, produit };
              })
              .catch((error) => {
                if (error.response.status === 404) return { acm, produit: [] };
              });
          })
        );
      })
      .then((acmWithProduit: ({ acm: Acm; produit: Produit[] } | undefined)[]) => {
        this.showSpinner = false;
        this.errors = [];
        if (acmWithProduit[0] === undefined) {
          this.errors.push('error');
        } else {
          // @ts-ignore
          this.items = acmWithProduit;
          // @ts-ignore
          this.itemsToRender = acmWithProduit;
        }
      });
    ConseillerService.conseillerList().then((response) => {
      this.conseillerList = [];
      this.conseillerList.push(
        ...response.map((item) => {
          return { value: item.id, text: item.firstname + ' ' + item.lastname };
        })
      );
    });
  }

  uniqueType(list: Produit[]) {
    return Array.from(new Set(list.map((p) => p.categorieProduit.categorieType.picto)));
  }

  toggleAll(checked: boolean) {
    this.typeSelected = checked ? AcmStateList.allState.map((s) => s.code).slice() : [];
  }

  itemSelected(item: { acm: Acm; produit: Produit[] }) {
    this.$router.push({ name: 'AcmOne', params: { acmId: `${item.acm.id}` } });
  }

  filterConseiller(conseillerId: number | null = null) {
    if (conseillerId === null) {
      this.itemsToRender = this.items;
    } else {
      this.itemsToRender = this.items.filter(
        (acm) => acm.acm.conseiller.id !== null && acm.acm.conseiller.id === conseillerId
      );
    }
  }

  @Watch('conseiller')
  onconseiller(newVal: number | null) {
    this.filterConseiller(newVal);
  }

  @Watch('typeSelected')
  ontypeSelected(newVal: string[]) {
    this.filterConseiller(this.conseiller);
    if (newVal.length === 0) {
      this.indeterminate = false;
      this.allTypeSelected = false;
    } else if (newVal.length === AcmStateList.allState.length) {
      this.indeterminate = false;
      this.allTypeSelected = true;
    } else {
      this.indeterminate = true;
      this.allTypeSelected = false;
    }
    if (newVal.length !== 0) {
      this.itemsToRender = this.itemsToRender.filter((value) => {
        return newVal.indexOf(value.acm.etat) !== -1;
      });
    }
  }
}
