

















import { Component, Vue } from 'vue-property-decorator';
import AcmMasterService from '../../../services/acmmaster';
import MarkdownEditor from '@/utils/MarkdownEditor.vue';
import { AcmMaster } from '@/models/acmmaster';

@Component({
  components: {
    MarkdownEditor
  }
})
export default class SettingsAvantProposMaster extends Vue {
  acmMaster: AcmMaster | null = null;
  status: string | null = null;

  mounted() {
    this.load();
  }

  load() {
    AcmMasterService.getAcmMaster().then((response) => {
      this.acmMaster = response;
    });
  }

  saveAvantProposMaster() {
    if (this.acmMaster) {
      AcmMasterService.updateAcmMaster({
        avantProposData: this.acmMaster.avantProposMaster,
        bordereauData: this.acmMaster.bordereauMaster
      }).then(() => {
        this.status = 'Vos modifications sont enregistrées !';
      });
    }
  }

  clearStatus() {
    this.status = null;
  }
}
