












import { Component, Vue, Prop } from 'vue-property-decorator';
// import VBar from 'v-bar'
// @ts-ignore
import VuePdf from 'vue-pdf';
@Component({
  components: {
    VuePdf
  }
})
export default class PdfViewer extends Vue {
  @Prop({ required: true }) src!: string;
  viewerList: unknown[] = [];
  numPages = 1;

  loaded(nb?: number) {
    if (nb !== undefined) {
      this.numPages = nb;
      for (let i = 0, p = Promise.resolve(); i < this.numPages; i++) {
        p = p.then(
          () =>
            new Promise((resolve) =>
              setTimeout(() => {
                this.viewerList.push(VuePdf);
                resolve();
              }, 300)
            )
        );
      }
    }
  }
}
