





































import { Component, Vue, Prop } from 'vue-property-decorator';
import AcmService from '@/services/acm';
import { Acm } from '../../models/acm';
@Component
export default class AcmCreated extends Vue {
  @Prop({ required: true }) acmId!: number;
  acm: Acm | null = null;

  created() {
    AcmService.acmById(this.acmId)
      .then((response) => {
        this.acm = response;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.$router.push({ name: 'AcmList' });
        }
      });
  }
}
