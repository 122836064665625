










































import { Component, Vue, Prop } from 'vue-property-decorator';

import ModalCreateCompagnie from './ModalCreateCompagnie.vue';
import Breadcrumb from './Breadcrumb.vue';
import { Compagnie } from '../../models/compagnie';
import { CategorieProduit } from '../../models/categorieproduit';
import ProduitService from '@/services/produit';
import OrganisationService from '@/services/organisation';
@Component({
  components: {
    ModalCreateCompagnie,
    Breadcrumb
  }
})
export default class ProduitAssociateOrganisation extends Vue {
  @Prop({ required: true }) id!: number;

  categorie: CategorieProduit | null = null;
  compagnies: Compagnie[] = [];

  mounted() {
    this.loadSousCategorie();
    this.loadOrganisation();
  }

  loadSousCategorie() {
    ProduitService.loadOneCategorie(this.id).then((response) => {
      this.categorie = response;
    });
  }

  loadOrganisation() {
    OrganisationService.organisationList().then((response) => {
      this.compagnies = response;
    });
  }

  createCompagnie() {
    // @ts-ignore
    this.$refs.modalCreateCompagnie.openModal();
  }
}
