








import { Component, Vue } from 'vue-property-decorator';
@Component
export default class Scrollable extends Vue {
  ops = {
    vuescroll: {
      /** Whether to detect dom resize or not */
      detectResize: true
    },
    rail: {
      background: 'rgba(255, 255, 255, 0.2)',
      opacity: 1,
      border: 'none',
      size: '1px',
      gutterOfSide: '2px'
    },
    hRail: {
      keepShow: false
    },
    vRail: {
      keepShow: true
    },
    bar: {
      size: '2px',
      background: 'white',
      keepShow: true
    }
  };
}
