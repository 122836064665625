




































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
import AcmMasterService from '../../services/acmmaster';
import AcmService from '../../services/acm';
// @ts-ignore
import Datepicker from 'vuejs-datepicker';
// @ts-ignore
import { fr } from 'vuejs-datepicker/dist/locale';
import FormInputAppend from '@/components/elements/form-input-append.vue';
import moment from 'moment';
import Breadcrumb from '@/components/acm/Breadcrumb.vue';
import FileUpload from 'vue-upload-component';
import MarkdownEditor from '@/utils/MarkdownEditor.vue';
import { Acm } from '../../models/acm';
import { Conseiller } from '../../models/conseiller';
import ConseillerService from '@/services/conseiller';

@Component({
  components: {
    Breadcrumb,
    Datepicker,
    FormInputAppend,
    FileUpload,
    MarkdownEditor
  }
})
export default class AcmCreateEdit extends Vue {
  @Prop({ required: true }) acmId!: number;

  acm: Acm | null = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  friseFile: any[] | null = null;
  success: boolean | null = null;
  error = null;
  active: boolean | null = null;
  acmData: {
    contactText: string;
    lieu: string;
    date: string | Date;
    avantPropos: string;
    signature1: string;
    signature1Url: string;
    signature2: string | null;
    signature2Url: string | null;
    frise: string;
    friseUrl: string;
  } = {
    contactText: '',
    lieu: '',
    date: '',
    avantPropos: '',
    signature1: '',
    signature1Url: '',
    signature2: null,
    signature2Url: null,
    frise: '',
    friseUrl: ''
  };

  datepickerLanguage = fr;

  conseiller: Conseiller[] = [];
  anotherSign = false;

  get user() {
    return UserModule.user;
  }

  get token() {
    return `Bearer ${UserModule.jwtToken}`;
  }

  get isEdited() {
    if (this.acm !== null) {
      return (
        this.acm.clientName !== this.acmData.contactText ||
        this.acm.place !== this.acmData.lieu ||
        this.acm.avantPropos !== this.acmData.avantPropos ||
        moment(this.acm.date).format() !== moment(this.acmData.date).format() ||
        this.acm.signature1 !== this.acmData.signature1 ||
        this.acm.signature2 !== this.acmData.signature2 ||
        this.acm.frise !== this.acmData.frise
      );
    } else return false;
  }

  created() {
    AcmService.acmById(this.acmId)
      .then((response) => {
        ConseillerService.conseillerList().then((response) => {
          this.conseiller = response.filter((c) => c.signature !== undefined);
        });
        this.acm = response;
        this.acmData.contactText =
          this.acm.clientName || this.acm.contact.titre + ' ' + this.acm.contact.prenom + ' ' + this.acm.contact.nom;
        this.acmData.lieu = this.acm.place || 'Tours';
        this.acmData.date = this.acm.date || new Date();
        this.acmData.frise = this.acm.frise;
        this.acmData.signature1 = this.acm.signature1;
        this.acmData.signature1Url = this.acm.signature1Url;
        if (this.acm.signature2 !== undefined) {
          this.acmData.signature2 = this.acm.signature2;
          this.acmData.signature2Url = this.acm.signature2Url;
          this.anotherSign = true;
        }
        if (this.acm.frise !== undefined) {
          this.acmData.friseUrl = this.acm.friseUrl;
        }
        if (this.acm.avantPropos !== null && this.acm.avantPropos !== '' && this.acm.avantPropos !== undefined) {
          this.acmData.avantPropos = this.acm.avantPropos;
        } else {
          AcmMasterService.getAcmMaster().then((response) => {
            this.acmData.avantPropos = response.avantProposMaster;
          });
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.status === 404) {
          this.$router.push({ name: 'AcmList' });
        } else {
          throw error;
        }
      });
  }

  uploadFrise() {
    if (this.friseFile !== null) {
      this.active = true;
      AcmService.friseUploadUrl(this.friseFile[0].file)
        .then((response) => {
          this.success = true;
          this.active = null;
          this.acmData.frise = response.data.frisePath;
          this.acmData.friseUrl = response.data.friseUrl;
        })
        .catch((error) => {
          this.error = null;
          throw error;
        });
    }
  }

  deleteFile() {
    this.friseFile = null;
  }

  okPressed() {
    if (this.friseFile !== null) {
      this.friseFile = null;
      this.success = null;
      this.active = null;
      this.error = null;
    }
  }

  cancelPressed() {
    this.friseFile = null;
    this.success = null;
    this.active = null;
    this.error = null;
  }

  alertIfEdited() {
    if (this.isEdited) {
      // @ts-ignore
      this.$refs.modalConfirm.show();
    } else {
      this.$router.push({
        name: 'AcmOne',
        params: { acmId: `${this.acmId}` }
      });
    }
  }

  modalValidate() {
    this.$router.push({ name: 'AcmOne', params: { acmId: `${this.acmId}` } });
  }

  modalCancel() {
    // @ts-ignore
    this.$refs.modalConfirm.hide();
  }

  saveBeforeNext() {
    if (this.isEdited && this.acm) {
      this.acm.clientName = this.acmData.contactText;
      this.acm.place = this.acmData.lieu;
      this.acm.avantPropos = this.acmData.avantPropos;
      this.acm.date = moment(this.acmData.date).format();
      this.acm.signature1 = this.acmData.signature1;
      this.acm.signature2 = this.acmData.signature2 || undefined;
      this.acm.frise = this.acmData.frise;
      AcmService.acmUpdate(this.acm).then((response: Acm) => {
        this.acm = response;
        this.$router.push({
          name: 'AcmCreateEditStep2',
          params: { acmId: `${this.acm.id}` }
        });
      });
    } else if (this.acm) {
      this.$router.push({
        name: 'AcmCreateEditStep2',
        params: { acmId: `${this.acm.id}` }
      });
    }
  }

  toggleConseiller(c: Conseiller) {
    if (this.acmData.signature1 === c.signature) {
      this.acmData.signature1 = '';
      this.acmData.signature1Url = '';
    } else {
      this.acmData.signature1 = c.signature;
      this.acmData.signature1Url = c.signatureUrl;
    }
  }

  toggleCoConseiller(c: Conseiller) {
    if (this.acmData.signature2 === c.signature) {
      this.acmData.signature2 = null;
      this.acmData.signature2Url = null;
    } else {
      this.acmData.signature2 = c.signature;
      this.acmData.signature2Url = c.signatureUrl;
    }
  }
}
