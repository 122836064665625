















































































import { Component, Vue, Watch } from 'vue-property-decorator';

import FormInputAppend from '@/components/elements/form-input-append.vue';
import ModalContactInfo from './ModalContactInfo.vue';
import { Contact } from '../../models/contact';
import ClientsService from '@/services/clients';

@Component({
  components: {
    FormInputAppend,
    ModalContactInfo
  }
})
export default class ContactList extends Vue {
  items: Contact[] = [];
  itemsToRender: Contact[] = [];
  fields = [
    { key: 'titre' },
    { key: 'prenom', label: 'Prénom', sortable: true },
    { key: 'nom', sortable: true },
    { key: 'telephone', label: 'N° tel' },
    { key: 'email', label: 'Adresse mail', sortable: true },
    { key: 'conseiller', label: 'Conseiller', sortable: true }
  ];

  currentPage = 1;
  perPage = 10;
  totalRows = 0;
  pageOptions = [5, 10, 15];
  sortBy = null;
  sortDesc = false;
  filter: string | null = null;
  modalContactId = null;
  syncLoad = 0;
  syncMaxLoad = 4500;
  typeSelected: number[] = [];
  allTypeSelected = false;
  indeterminate = false;

  get contactType() {
    return Array.from(new Set(this.items.map((x) => x.contactType.id + ' - ' + x.contactType.nom))).map((s) => {
      const obj = s.split(' - ');
      return { id: parseInt(obj[0]), nom: obj[1] };
    });
  }

  get filterRegex() {
    if (this.filter !== null) {
      // @ts-ignore
      this.$log.debug(this.filter);
      const str = this.filter.split(' ');
      return str.filter((s) => s !== '').join('|');
    } else {
      return this.filter;
    }
  }

  created() {
    this.load();
  }

  load() {
    ClientsService.loadContact().then((response) => {
      this.items = response.slice(0);
      this.itemsToRender = response.slice(0);
      this.toggleAll(true);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  info(item: any) {
    this.modalContactId = item.id;
    this.$nextTick(() => {
      // @ts-ignore
      this.$refs.modalContactInfo.open();
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFiltered(filteredItems: any[]) {
    // Trigger pagination to update the number of buttons/pages due to filtering
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
  }

  toggleAll(checked: boolean) {
    this.typeSelected = checked ? this.contactType.map((v) => v.id).slice() : [];
  }

  @Watch('typeSelected')
  onTypeSelected(newVal: number[]) {
    // Handle changes in individual flavour checkboxes
    if (newVal.length === 0) {
      this.indeterminate = false;
      this.allTypeSelected = false;
    } else if (newVal.length === this.contactType.length) {
      this.indeterminate = false;
      this.allTypeSelected = true;
    } else {
      this.indeterminate = true;
      this.allTypeSelected = false;
    }
    this.itemsToRender = this.items.filter((value) => newVal.includes(value.contactType.id));
  }
}
