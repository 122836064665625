














































import { Component, Vue } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
import SettingsConseillerModalMedia from './SettingsConseillerModalMedia.vue';
import { Conseiller } from '../../../models/conseiller';
import ConseillerService from '@/services/conseiller';

@Component({
  components: {
    SettingsConseillerModalMedia
  }
})
export default class SettingsConseiller extends Vue {
  conseillers: Conseiller[] = [];
  userToUpdate: Conseiller | null = null;

  get user() {
    return UserModule.user;
  }

  mounted() {
    this.load();
  }

  load() {
    ConseillerService.conseillerList().then((response) => {
      this.conseillers = response;
    });
  }

  openEditMedia(id: number) {
    this.userToUpdate = this.conseillers.find((c) => c.id === id) || null;
    // @ts-ignore
    this.$refs.modalMedia.openModal();
  }
}
