import { Placeholder } from './placeholder';

export interface DiagnosticStage {
  name: DiagnosticStageName;
  layout: Placeholder[];
  substages?: DiagnosticStage[];
  constats?: boolean;
  progress: number;
}

export enum DiagnosticStageName {
  AVANTPROPOS = 'AVANTPROPOS',
  ANALYSE = 'ANALYSE',
  BILANS = 'BILANS',
  CIVIL = 'CIVIL',
  PATRIMONIAL = 'PATRIMONIAL',
  SOCIAL = 'SOCIAL',
  ECONOMIQUE = 'ECONOMIQUE',
  FISCAL = 'FISCAL',
  OBJECTIFS = 'OBJECTIFS',
  CONSEILLERS = 'CONSEILLERS'
}

export const DiagnosticConstatsStageNames = [
  DiagnosticStageName.CIVIL,
  DiagnosticStageName.PATRIMONIAL,
  DiagnosticStageName.SOCIAL,
  DiagnosticStageName.ECONOMIQUE,
  DiagnosticStageName.FISCAL
];
