





import { Component, Vue, Prop } from 'vue-property-decorator';
import DefaultCard from './DefaultCard.vue';
import { Produit } from '../../../models';

@Component({
  components: {
    DefaultCard
  }
})
export default class BanqueCard extends Vue {
  @Prop({ type: Object }) produit!: Produit;
  @Prop({ type: Boolean, default: false }) selected!: boolean;
  @Prop({ type: Boolean, default: false }) withClose!: boolean;

  onChange(checked: boolean) {
    this.$emit('onChange', checked);
  }

  onClose() {
    this.$emit('onClose');
  }
}
