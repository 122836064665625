






























import { Component, Vue, Prop } from 'vue-property-decorator';
import { Section } from '@/models/section';
import { BModal } from 'bootstrap-vue';
import AvantPropos from '../livrable/AvantPropos.vue';
import Bilan from '../livrable/Bilan.vue';
import Objectifs from '../livrable/Objectifs.vue';
import Body from '../livrable/Body.vue';
import { Template, TemplateCategory, TemplateConstats } from '@/models/template';
import { DiagnosticStageName } from '@/models/diagnostic_stage';

@Component({
  components: {
    AvantPropos,
    Bilan,
    Objectifs,
    Body
  }
})
export default class ModalTemplatePreview extends Vue {
  @Prop({ required: true }) template!: Template;
  @Prop({ required: true }) contents!: Section[];

  stageNames = DiagnosticStageName;
  livrablePages = 0;

  mounted() {
    (this.$refs.modalPreview as BModal).show();
  }

  isConstat(template: Template) {
    return (
      template.category === TemplateCategory.DIAGNOSTICPATRIMONIAL &&
      TemplateConstats.includes(template.subcategory as DiagnosticStageName)
    );
  }
}
