















































import { Component, Vue } from 'vue-property-decorator';
import SettingsCategorieProduitModalAddEdit from './SettingsCategorieProduitModalAddEdit.vue';
import SettingsCategorieModalError from './SettingsCategorieModalError.vue';
import { CategorieProduit } from '../../../models/categorieproduit';
import { CategorieType } from '../../../models/categorietype';
import ProduitService from '@/services/produit';
@Component({
  components: {
    SettingsCategorieProduitModalAddEdit,
    SettingsCategorieModalError
  }
})
export default class SettingsCategorieProduit extends Vue {
  categories: CategorieProduit[] = [];
  selected: CategorieProduit | null = null;
  errorProduit = [];

  mounted() {
    this.load();
  }

  compare(a: CategorieProduit, b: CategorieProduit) {
    const nameA = a.nom.toUpperCase();
    const nameB = b.nom.toUpperCase();

    let comparaison = 0;
    if (nameA > nameB) {
      comparaison = 1;
    } else if (nameA < nameB) {
      comparaison = -1;
    }
    return comparaison;
  }

  load() {
    // @ts-ignore
    this.$log.debug('Load categorie for settings');
    ProduitService.loadCategorie().then((response) => {
      this.categories = response;
      this.categories.sort(this.compare);
    });
  }

  openModalCreateMode() {
    // @ts-ignore
    this.$log.debug('Open modal for create categorie');
    this.selected = null;
    // @ts-ignore
    this.$refs.modalAddProduitSousCategorie.openModal();
  }

  openModalEditMode(item: CategorieProduit) {
    // @ts-ignore
    this.$log.debug('Open modal for edit categorie', item);
    this.selected = item;
    // @ts-ignore
    this.$refs.modalAddProduitSousCategorie.openModal();
  }

  categorieOf(type: CategorieType) {
    return this.categories.filter((x) => x.categorieType.id === type.id);
  }

  deleteProduitCat(categorie: CategorieProduit) {
    ProduitService.categorieDelete(categorie.id)
      .then((response) => {
        if (response.status === 202) {
          this.load();
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          this.errorProduit = error.response.data;
          // @ts-ignore
          this.$refs.modalError.openModal();
        } else {
          throw error;
        }
      });
  }
}
