
















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ProduitService from '@/services/produit';
// @ts-ignore
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import { debounce } from 'ts-debounce';

@Component({
  components: {
    VueBootstrapTypeahead
  }
})
export default class AutoCompletion extends Vue {
  @Prop({ required: true }) placeholder!: string;
  @Prop({ required: true }) caractId!: number;
  @Prop({ required: true }) value!: string;

  caracteristique: string[] = [];
  caracteristiqueSearch = '';
  selectedCaracteristique = null;

  mounted() {
    // @ts-ignore
    this.$refs.caractTypeahead.inputValue = this.value;
  }

  async getCaracteristiques(query: string) {
    if (query !== '') {
      ProduitService.searchCaracteristiqueValue(this.caractId, query).then((response) => {
        this.caracteristique = response;
      });
    }
  }

  onUpdateCaracteristiqueValue(value: string) {
    this.$emit('update-caracteristique-value', value);
  }

  @Watch('caracteristiqueSearch')
  onCaracteristiqueSearch = debounce((carac) => {
    this.getCaracteristiques(carac);
  }, 1000);
}
