










































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import ProduitService from '@/services/produit';
import BanqueCard from './card/BanqueCard.vue';
import ConseilCard from './card/ConseilCard.vue';
import DefaultCard from './card/DefaultCard.vue';
import EpargneCard from './card/EpargneCard.vue';
import ImmobilierCard from './card/ImmobilierCard.vue';
import PlacementCard from './card/PlacementCard.vue';
import PrevoyanceCard from './card/PrevoyanceCard.vue';
import Scrollable from '@/components/elements/Scrollable.vue';
import Panier from '@/components/selection/panier';
import AdvancedSearch from './AdvancedSearch.vue';
import { Produit } from '../../models';
import { CategorieType } from '../../models/categorietype';

@Component({
  components: {
    BanqueCard,
    ConseilCard,
    DefaultCard,
    EpargneCard,
    ImmobilierCard,
    PlacementCard,
    PrevoyanceCard,
    Scrollable,
    AdvancedSearch
  }
})
export default class ProduitList extends Vue {
  showCollapse: number | null = null;
  items: Produit[] = [];
  itemsToRender: Produit[] = [];
  produitSelected: Produit[] = [];
  showSpinner = false;

  get produitCategorieType() {
    return Array.from(
      new Set(
        this.items.map((x) => {
          return (
            x.categorieProduit.categorieType.id +
            ' - ' +
            x.categorieProduit.categorieType.nom +
            ' - ' +
            x.categorieProduit.categorieType.picto
          );
        })
      )
    ).map((s) => {
      const obj = s.split(' - ');
      return { id: parseInt(obj[0]), nom: obj[1], picto: obj[2] };
    });
  }

  created() {
    this.showCollapse = null;
    this.load();
  }

  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.$root.$on('bv::modal::show', (bvEvent: any, modalId: number) => {
      // @ts-ignore
      this.$log.debug('Modal is about to be shown', bvEvent, modalId);
    });
  }

  load() {
    this.showSpinner = true;
    this.produitSelected = Panier.all();
    let promiseData;
    if (this.$route.query.search !== null && this.$route.query.search instanceof String) {
      promiseData = ProduitService.searchProduit(this.$route.query.search as string);
    } else {
      promiseData = ProduitService.produitList();
    }
    promiseData.then((data) => {
      this.items = data.slice(0);
      this.itemsToRender = data.slice(0);
      this.updateMenu();
      this.showSpinner = false;
    });
  }

  updateMenu() {
    if (this.$route.query.category !== null && this.$route.query.category instanceof String) {
      this.showCollapse = this.itemsToRender[0].categorieProduit.categorieType.id;
      this.itemsToRender = this.items.filter(
        (i) => i.categorieProduit.id === parseInt(this.$route.query.category as string, 10)
      );
    } else if (this.$route.query.type !== null && this.$route.query.type instanceof String) {
      this.showCollapse = parseInt(this.$route.query.type as string, 10);
      this.itemsToRender = this.items.filter(
        (i) => i.categorieProduit.categorieType.id === parseInt(this.$route.query.type as string)
      );
    } else {
      this.showCollapse = null;
      this.itemsToRender = this.items.slice(0);
    }
  }

  produitCategorieOf(categorieType: CategorieType) {
    return Array.from(
      new Set(
        this.items
          .filter((x) => {
            return x.categorieProduit.categorieType.id === categorieType.id;
          })
          .map((x) => {
            return x.categorieProduit.id + ' - ' + x.categorieProduit.nom;
          })
      )
    ).map((s) => {
      const obj = s.split(' - ');
      return { id: parseInt(obj[0]), nom: obj[1] };
    });
  }

  onProduitSelected(produit: Produit, checked: boolean) {
    const ids = this.produitSelected.map((p) => p.id);
    Object.keys(this.$refs)
      .filter((s) => s !== 'popover-' + produit.id)
      .map((s) => {
        // @ts-ignore
        if (this.$refs[s][0] !== undefined) {
          // @ts-ignore
          this.$refs[s][0].$emit('close');
        }
      });
    if (checked !== false && ids.indexOf(produit.id) === -1) {
      // @ts-ignore
      this.$refs['popover-' + produit.id][0].$emit('open');
      this.produitSelected.push(produit);
      Panier.add(produit);
    } else {
      this.produitSelected = this.produitSelected.filter((p) => p.id !== produit.id);
      Panier.remove(produit);
    }
  }

  onClose(selected: Produit) {
    this.produitSelected = this.produitSelected.filter((p) => p.id !== selected.id);
    Panier.remove(selected);
    if (Panier.isEmpty()) {
      // @ts-ignore
      this.$refs['popover-' + selected.id][0].$emit('close');
    }
  }

  test() {
    console.log('test');
    console.log(this.produitSelected.length);
  }

  @Watch('$route.query')
  onRouteChange() {
    this.showCollapse = null;
    // this.updateMenu()
    this.load();
  }

  @Watch('produitSelected')
  onProduitSelectedChange(n: Produit[]) {
    if (this.produitSelected.length === 0) {
      this.itemsToRender = this.items;
    }
    if (this.produitSelected.length === 1) {
      this.itemsToRender = this.items.filter(
        (item) => item.categorieProduit.categorieType.id === n[0].categorieProduit.categorieType.id
      );
    }
  }
}
