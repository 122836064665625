import { IdsHistoryModule } from '@/store/modules/ids_history';
import { Template } from '@/models/template';

export const TemplatesIdsHistory = {
  saveAll(templates: Template[]) {
    IdsHistoryModule.saveAll(templates.map((t) => t.id));
  },
  isEmpty() {
    return IdsHistoryModule.isEmpty;
  },
  clear() {
    IdsHistoryModule.clear();
  },
  all() {
    return IdsHistoryModule.storedIds;
  },
  add(template: Template) {
    IdsHistoryModule.add(template.id);
  },
  removeById(templateId: number) {
    IdsHistoryModule.removeById(templateId);
  },
  remove(template: Template) {
    IdsHistoryModule.remove(template.id);
  }
};
