import { Placeholder } from './placeholder';
import { DiagnosticStage, DiagnosticStageName } from './diagnostic_stage';

export const DiagnosticPipeline: DiagnosticStage[] = [
  {
    name: DiagnosticStageName.AVANTPROPOS,
    layout: [Placeholder.TITRE, Placeholder.BLOC_TEXTE],
    progress: 0
  },
  {
    name: DiagnosticStageName.ANALYSE,
    layout: [Placeholder.TITRE, Placeholder.BLOC_TEXTE, Placeholder.BLOC_TEXTE, Placeholder.AVANTAGES_RISQUES],
    progress: 25
  },
  {
    name: DiagnosticStageName.BILANS,
    layout: [],
    progress: 50,
    substages: [
      {
        name: DiagnosticStageName.CIVIL,
        layout: [Placeholder.TITRE, Placeholder.BLOC_TEXTE, Placeholder.BLOC_TEXTE, Placeholder.AVANTAGES_RISQUES],
        constats: true,
        progress: 50
      },
      {
        name: DiagnosticStageName.PATRIMONIAL,
        layout: [Placeholder.TITRE, Placeholder.BLOC_TEXTE, Placeholder.BLOC_TEXTE, Placeholder.AVANTAGES_RISQUES],
        constats: true,
        progress: 55
      },
      {
        name: DiagnosticStageName.SOCIAL,
        layout: [Placeholder.TITRE, Placeholder.BLOC_TEXTE, Placeholder.BLOC_TEXTE, Placeholder.AVANTAGES_RISQUES],
        constats: true,
        progress: 60
      },
      {
        name: DiagnosticStageName.ECONOMIQUE,
        layout: [Placeholder.TITRE, Placeholder.BLOC_TEXTE, Placeholder.BLOC_TEXTE, Placeholder.AVANTAGES_RISQUES],
        constats: true,
        progress: 65
      },
      {
        name: DiagnosticStageName.FISCAL,
        layout: [Placeholder.TITRE, Placeholder.BLOC_TEXTE, Placeholder.BLOC_TEXTE, Placeholder.AVANTAGES_RISQUES],
        constats: true,
        progress: 70
      }
    ]
  },
  {
    name: DiagnosticStageName.OBJECTIFS,
    layout: [Placeholder.SOUS_TITRE, Placeholder.BLOC_TEXTE],
    progress: 75
  },
  {
    name: DiagnosticStageName.CONSEILLERS,
    layout: [],
    progress: 100
  }
];
