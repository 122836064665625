import Vue from 'vue';
// @ts-ignore
import VueLogger from 'vuejs-logger';

const isProduction = process.env.NODE_ENV === 'production';
const options = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: isProduction,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
Vue.use(VueLogger as any, options);
