



































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import PreconisationService from '../../../services/preconisation';
import MarkdownEditor from '@/utils/MarkdownEditor.vue';
import { Preconisation } from '@/models/preconisation';

@Component({
  components: {
    MarkdownEditor
  }
})
export default class SettingsAcmPreconisationMaster extends Vue {
  @Prop({ required: true }) readonly precoId!: number;

  preconisation: Preconisation | null = null;
  preconisationOrigin: Preconisation | null = null;

  mounted() {
    this.init();
  }

  init() {
    PreconisationService.preconisationOne(this.precoId).then((response) => {
      this.preconisation = response;
      this.preconisationOrigin = Object.assign({}, response);
    });
  }

  alertIfEdited() {
    if (
      (this.preconisationOrigin &&
        this.preconisation &&
        this.preconisationOrigin.nomDefault !== this.preconisation.nomDefault) ||
      (this.preconisationOrigin &&
        this.preconisation &&
        this.preconisationOrigin.resumerDefault !== this.preconisation.resumerDefault) ||
      (this.preconisationOrigin &&
        this.preconisation &&
        this.preconisationOrigin.selectionDefault !== this.preconisation.selectionDefault)
    ) {
      // @ts-ignore
      this.$refs.modalConfirm.show();
    } else {
      this.modalValidate();
    }
  }

  modalValidate() {
    this.$router.push({ name: 'Settings.AcmPreconisation' });
  }

  modalCancel() {
    // @ts-ignore
    this.$refs.modalConfirm.hide();
  }

  saveBeforeNext() {
    if (this.preconisation) {
      PreconisationService.preconisationSaveContent(this.precoId, this.preconisation).then((response) => {
        if (response.status === 202) {
          this.$router.push({ name: 'Settings.AcmPreconisation' });
        }
      });
    }
  }
}
