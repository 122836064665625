























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import DictionaryService from '../../services/dictionary';
import SalesforceService from '../../services/salesforce';
import AccountService from '../../services/account';
import { BCollapse } from 'bootstrap-vue';
import { Trait, Definition } from '@/models/dictionary';
import { Conseiller } from '@/models/conseiller';

@Component({
  components: {
    BCollapse
  }
})
export default class SidebarDictionary extends Vue {
  @Prop({ required: false }) accountId?: number;
  @Prop({ required: false }) conseillers?: Conseiller[];

  traits: Array<Trait> = [];
  definitions: Definition[] = [];
  dictionary: { [key: number]: Definition[] } = {};
  search = '';
  newDefinition = '';
  showSpinner = false;

  expandedDefinitions: { [key: number]: boolean } = {};

  get bindingState() {
    return this.accountId !== undefined;
  }

  get sortedTraits() {
    return this.traits.sort((tr1, tr2) => {
      if (this.dictionary[tr1.id] && !this.dictionary[tr2.id]) return -1;
      else if (!this.dictionary[tr1.id] && this.dictionary[tr2.id]) return 1;
      else return tr1.name.localeCompare(tr2.name);
    });
  }

  mounted() {
    this.refreshSalesForce().then(() => {
      this.fetchDictionary();
    });
  }

  fetchDictionary() {
    this.showSpinner = true;
    DictionaryService.traits().then((response) => {
      this.traits = response.data;
      this.traits = this.traits.sort((tr1: Trait, tr2: Trait) => tr1.name.localeCompare(tr2.name));

      if (this.accountId !== undefined) {
        DictionaryService.definitions(this.accountId).then((response) => {
          this.definitions = response.data;

          this.definitions = this.definitions.filter((defToDedup: Definition) => {
            const firstSameDef = this.definitions.find(
              (d) => d.trait.id === defToDedup.trait.id && d.value === defToDedup.value
            );
            return firstSameDef && defToDedup.id === firstSameDef.id;
          });

          this.updateDictionary();
          this.showSpinner = false;
        });
      } else {
        this.updateDictionary();
        this.showSpinner = false;
      }
    });
  }

  updateDictionary() {
    this.dictionary = {};
    this.definitions.forEach((def: Definition) => {
      if (this.dictionary[def.trait.id] === undefined) this.dictionary[def.trait.id] = [];
      this.dictionary[def.trait.id].push(def);
    });
  }

  traitMatchesSearch(trait: Trait) {
    if (this.search === '') return true;
    if (trait.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) return true;

    return (
      this.dictionary[trait.id] &&
      this.dictionary[trait.id].some((def: Definition) => this.definitionMatchesSearch(def))
    );
  }

  definitionMatchesSearch(def: Definition) {
    if (this.search === '') return true;

    const standardDef = [def.trait.name, def.value].join(':').toLowerCase();
    const standardSearch = this.search.toLowerCase();
    return standardDef.indexOf(standardSearch) !== -1;
  }

  activeDefinition(trait: Trait, contactIndex?: number): Definition | undefined {
    const traitDefinitions: Definition[] = this.dictionary[trait.id];
    if (!traitDefinitions) return undefined;
    else if (contactIndex !== undefined)
      return traitDefinitions.find((def) => {
        return def.contact && def.contact.contactIndex === contactIndex;
      });
    else return traitDefinitions[0];
  }

  previewDefinitionValue(definition: Definition) {
    if (definition.trait.name === 'GestionnaireGCFClient' && this.conseillers) {
      const conseiller = this.conseillers.find((c) => c.salesForceConseillerId === definition.value);
      if (conseiller) return `${conseiller.firstname} ${conseiller.lastname}`;
    }
    if (definition.trait.name.match(/Date/)) return this.$moment(definition.value).format('LL');

    return definition.value;
  }

  refreshSalesForce(): Promise<[void, void]> {
    if (!this.accountId) return Promise.all([Promise.resolve(), Promise.resolve()]);

    const accountSync: Promise<void> = AccountService.oneAccountSync(this.accountId)
      .then((response) => {
        if (response.status === 203) console.warn('Error on account sync', { response });
      })
      .catch((error) => {
        console.error('accountSync', { error });
      });

    const contactsSync: Promise<void> = SalesforceService.contactsSync(this.accountId)
      .then((response) => {
        console.log('contactsSync', { response });
        if (response.status === 203) console.warn('Error on contacts sync', { response });
      })
      .catch((error) => {
        console.error('contactsSync', { error });
      });

    return Promise.all([accountSync, contactsSync]);
  }
}
