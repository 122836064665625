import axios from './axios';
import { Acm } from '@/models/acm';
import { ConseillerHasEditedAcm } from '@/models/conseillerhaseditedacm';
import { Produit } from '@/models';
import { AcmPreconisation } from '@/models/acmPreconisation';
import { AcmPreconisationHasProduit } from '@/models/acmpreconisationhasproduit';

class AcmService {
  async acmAll(): Promise<Acm[]> {
    return axios.get('/acm').then((response) => {
      return response.data as Acm[];
    });
  }

  async acmAllHistory(): Promise<Acm[]> {
    return axios.get('/acm/history').then((response) => {
      return response.data as Acm[];
    });
  }

  async acmById(acmId: number): Promise<Acm> {
    return axios.get('/acm/' + acmId).then((response) => {
      return response.data as Acm;
    });
  }

  async acmHistory(acmId: number) {
    return axios.get<ConseillerHasEditedAcm[]>('/acm/' + acmId + '/history').then((response) => {
      return response.data;
    });
  }

  acmDelete(acmId: number) {
    return axios.post<void>('/acm/' + acmId + '/delete');
  }

  async acmCreateDraft(clientId: number): Promise<Acm> {
    return axios
      .post<Acm>('/acm/new', {
        clientId: clientId
      })
      .then((response) => {
        return response.data;
      });
  }

  async acmClone(acmId: number, clientId: number): Promise<Acm> {
    return axios.post('/acm/' + acmId + '/clone/' + clientId).then((response) => {
      return response.data as Acm;
    });
  }

  async acmProduit(acmId: number): Promise<Produit[]> {
    return axios.get<Produit[]>('/acm/' + acmId + '/produits').then((response) => {
      return response.data;
    });
  }

  async acmUpdate(acm: { id: number }): Promise<Acm> {
    return axios.post<Acm>('/acm/' + acm.id + '/edit', acm).then((response) => {
      return response.data;
    });
  }

  acmEditState(acmId: number, newStatus: string) {
    return axios.post<void>('/acm/' + acmId + '/edit/status', { status: newStatus });
  }

  async acmSaveBordereau(acmId: number, bordereau: string): Promise<Acm> {
    return axios
      .post<Acm>('/acm/' + acmId + '/edit/bordereau', { bordereau: bordereau })
      .then((response) => {
        return response.data;
      });
  }

  friseUploadUrl(frise: string | Blob) {
    const formData = new FormData();
    formData.append('file', frise);
    return axios.post('/acm/frise/new/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  async acmMakePDF(acmId: number): Promise<Acm> {
    return axios.post<Acm>('/acm/' + acmId + '/pdf', {}).then((response) => {
      return response.data;
    });
  }

  async acmPreconisation(acmId: number): Promise<AcmPreconisation[]> {
    return axios.get<AcmPreconisation[]>('/acm/' + acmId + '/preconisation').then((response) => {
      return response.data;
    });
  }

  async acmSavePreconisation(
    acmId: number,
    preconisation: { id: number; nomDefault: string }[]
  ): Promise<AcmPreconisation[]> {
    return axios.post<AcmPreconisation[]>('/acm/' + acmId + '/preconisation', preconisation).then((response) => {
      return response.data;
    });
  }

  async acmSavePreconisationContent(
    acmId: number,
    preconisationId: number,
    content: AcmPreconisation
  ): Promise<AcmPreconisation> {
    return axios
      .post<AcmPreconisation>('/acm/' + acmId + '/preconisation/' + preconisationId, content)
      .then((response) => {
        return response.data;
      });
  }

  async acmSavePreconisationProduit(
    acmId: number,
    preconisationId: number,
    produitList: Produit[]
  ): Promise<AcmPreconisationHasProduit[]> {
    return axios
      .post('/acm/' + acmId + '/preconisation/' + preconisationId + '/produits', produitList)
      .then((response) => {
        return response.data as AcmPreconisationHasProduit[];
      });
  }

  acmHasCaracteristiqueDelete(acmId: number, acmPrecoId: number, caracteristiqueList: number[]) {
    return axios.post('/acm/' + acmId + '/caracteristiquelist/' + acmPrecoId + '/delete', caracteristiqueList);
  }

  acmHasCaracteristiqueAdd(acmId: number, acmPrecoId: number, caracteristiqueId: number[]) {
    return axios.post('/acm/' + acmId + '/preconisation/' + acmPrecoId + '/caracteristiquelist/add', caracteristiqueId);
  }

  async acmHasCaracteristiqueGet(acmId: number, acmPrecoId: number): Promise<number[]> {
    return axios.get('/acm/' + acmId + '/caracteristiquelist/' + acmPrecoId).then((response) => {
      return response.data as number[];
    });
  }

  acmPrecoDelete(acmId: number, acmPrecoId: number) {
    return axios.post('/acm/' + acmId + '/acmpreco/' + acmPrecoId + '/delete');
  }
}

export default new AcmService();
