
























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SettingsAcmPreconisationModalError extends Vue {
  @Prop({ required: true }) readonly preco!: string;
  showDismissibleAlert = false;

  openModal() {
    // @ts-ignore
    this.$refs.modal.show();
  }
}
