


































import { Vue, Component, Prop } from 'vue-property-decorator';
import SidebarDictionary from './SidebarDictionary.vue';
import SidebarComments from './SidebarComments.vue';
import { Conseiller } from '@/models/conseiller';
import { BSidebar, BIconX } from 'bootstrap-vue';
import { Section } from '@/models/section';

@Component({
  components: {
    BSidebar,
    BIconX,
    SidebarDictionary,
    SidebarComments
  }
})
export default class DiagnosticSidebar extends Vue {
  @Prop({ required: true }) mode!: 'dictionary' | 'comments' | null;
  @Prop({ required: false }) accountId?: number;
  @Prop({ required: false }) diagnosticId?: number;
  @Prop({ required: false }) conseillers?: Conseiller[];
  @Prop({ required: true }) sections!: Section[];
  @Prop({ required: false }) focusedSection?: Section | null;

  toggleMode(mode: string) {
    this.$emit('switch-sidebar', mode);
  }

  get dictionaryMode() {
    return this.mode === 'dictionary';
  }

  get commentsMode() {
    return this.mode === 'comments';
  }
}
