















































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import AcmService from '@/services/acm';
import ClientsService from '@/services/clients';
import ModalContactInfo from '@/components/contact/ModalContactInfo.vue';
import AcmState from './acmState/AcmState.vue';
import AcmStateList from './acmState/AcmStateList';
import PdfViewer from '@/components/elements/PdfViewer.vue';
import { Acm } from '../../models/acm';
import { ConseillerHasEditedAcm } from '../../models/conseillerhaseditedacm';
@Component({
  components: {
    ModalContactInfo,
    AcmState,
    PdfViewer
  }
})
export default class AcmOne extends Vue {
  @Prop({ required: true }) acmId!: number;

  acm: Acm | null = null;
  history: ConseillerHasEditedAcm[] = [];
  fullHistory: ConseillerHasEditedAcm[] = [];
  user = {};
  AcmStateList = AcmStateList;
  isMore = false;
  loading = false;
  pdfInfo = 'Aucun PDF généré';

  created() {
    AcmService.acmById(this.acmId)
      .then((response) => {
        this.acm = response;
        ClientsService.contact(this.acm.contact.id).then((response) => {
          this.user = response;
        });
        this.loadHistory().then((data) => {
          this.fullHistory = data;
          this.seeLess();
        });
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.$router.push({ name: 'AcmList' });
        }
      });
  }

  suppression() {
    AcmService.acmDelete(this.acmId).then((response) => {
      if (response.status === 204) {
        this.$router.push({ name: 'AcmList' });
      }
    });
  }

  seeMore() {
    this.isMore = true;
    this.history = this.fullHistory.slice(0);
  }

  seeLess() {
    this.isMore = false;
    this.history = this.fullHistory.slice(0, 4);
  }

  duplicate() {
    this.$router.push({
      name: 'AcmDuplicate',
      params: { acmId: `${this.acmId}` }
    });
  }

  loadHistory() {
    return AcmService.acmHistory(this.acmId).then((response) => {
      return response.map((i) => {
        if (i.content !== undefined) {
          i.content = JSON.parse(i.content);
        }
        return i;
      });
    });
  }

  makePDF() {
    if (this.acm) {
      if (this.acm.pdfFile !== undefined) {
        this.acm.pdfFile = undefined;
      }
      if (this.acm.pdfBordereauFile !== undefined) {
        this.acm.pdfBordereauFile = undefined;
      }
      this.pdfInfo = 'Veuillez patienter ...';
      AcmService.acmMakePDF(this.acmId)
        .then((response) => {
          this.acm = response;
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          this.pdfInfo = 'Une errreur est survenue !';
        });
      this.loading = true;
    }
  }
}
